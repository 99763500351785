import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBell } from 'react-icons/fa';
import { userLoggedOut } from '../features/auth/authSlice';
import { useDispatch } from 'react-redux';

const Navigation2 = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [activeButton, setActiveButton] = useState('Startseite');

    useEffect(() => {
        const currentPath = location.pathname;

        if (currentPath.startsWith('/invoice-summary/')) {
            setActiveButton('Aktivitäten');
            return;
        }

        switch (currentPath) {
            case '/home':
                setActiveButton('Startseite');
                break;
            case '/payment-methods':
                setActiveButton('Geld senden');
                break;
            case '/create-invoice':
                setActiveButton('Geld senden');
                break;
            case '/create-invoice-30-days':
                setActiveButton('Geld senden');
                break;
            case '/create-invoice-installment':
                setActiveButton('Geld senden');
                break;
            case '/wallet':
                setActiveButton('Wallet');
                break;
            case '/activities':
                setActiveButton('Aktivitäten');
                break;
            case '/help':
                setActiveButton('Hilfe');
                break;
            default:
                setActiveButton('');
                break;
        }
    }, [location]);

    const logout = () => {
        dispatch(userLoggedOut());
    };

    return (
        <div className="flex justify-around items-center bg-dark py-5 mb-10 px-10">
            <div className="flex items-center space-x-10">
                <Link to="/">
                    <img src="/images/logo_solo.svg" className="" alt="logo" class="min-w-[50px]" />
                </Link>

                {[
                    { name: 'Startseite', path: '/home' },
                    { name: 'Geld senden', path: '/payment-methods' },
                    { name: 'Wallet', path: '/wallet' },
                    { name: 'Aktivitäten', path: '/activities' },
                    { name: 'Hilfe', path: '/help' }
                ].map((button, index) => (
                    <Link to={button.path} key={index}>
                        <button
                            className={`border border-dark rounded-full p-2 transition-all hover:border-primary ${activeButton === button.name ? 'bg-primary bg-opacity-50 hover:border-dark' : ''}`}
                            onClick={() => setActiveButton(button.name)}
                        >
                            <h1 className="text-md text-white">{button.name}</h1>
                        </button>
                    </Link>
                ))}
            </div>

            <div className="flex items-center space-x-10">
                <div className="w-11 h-11 flex items-center justify-center cursor-pointer relative">
                    <Link to="/news">
                        <FaBell className="text-white text-xl" />
                    </Link>
                    <div className="absolute top-1 right-1 h-4 w-4 rounded-full bg-secondary"></div>
                </div>

                <button className="flex items-center space-x-1" onClick={logout}>
                    <h1 className="text-lg text-white">Logout</h1>
                </button>
            </div>
        </div>
    );
}

export default Navigation2;
