import { apiSlice } from "../api/apiSlice";

export const invoiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query({
      query: () => `/api/invoices`,
      providesTags: ["getInvoices"],
    }),
    getInvoice: builder.query({
      query: (id) => `/api/invoices/single-invoice/${id}`,
    }),
    createInvoice: builder.mutation({
      query: (data) => ({
        url: `/api/invoices`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getInvoices"],
    }),
    updateInvoiceStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/api/invoices/update-status/${id}`,
        method: "PATCH",
        body: { status },
      }),
      invalidatesTags: ["getInvoices", "getUser"],
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoiceQuery,
  useCreateInvoiceMutation,
  useUpdateInvoiceStatusMutation,
} = invoiceApi;

/**
Dieser Code definiert Endpunkte für die Invoice-API durch Verwendung des apiSlice-Werkzeugs aus dem Paket redux-toolkit. 
Es wird ein Objekt namens endpoints erstellt, das mehrere Methoden enthält, die auf bestimmte Endpunkte der API zugreifen. 
Hier sind die Endpunkte, die definiert sind:

getInvoices: eine Query, die alle Rechnungen abruft.
getInvoiceByCode: eine Query, die eine bestimmte Rechnung anhand ihres Codes abruft.
getInvoice: eine Query, die eine bestimmte Rechnung anhand ihrer ID abruft.
createInvoice: eine Mutation, die eine neue Rechnung erstellt.
generateInvoiceCode: eine Mutation, die einen neuen Code für eine Rechnung generiert.
updateInvoiceStatus: eine Mutation, die den Status einer bestimmten Rechnung aktualisiert.
Für jede dieser Methoden wird eine Hook erstellt, die in React-Komponenten verwendet werden kann, 
um auf die API zuzugreifen und Daten abzurufen oder zu ändern. 

Das useGetInvoicesQuery-Hook ruft beispielsweise alle Rechnungen ab, während das useCreateInvoiceMutation-Hook eine neue Rechnung erstellt.
 */