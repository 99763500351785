import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoutes";
import PublicRoute from "./components/PublicRoutes";
import CreateInvoice from "./pages/CreateInvoice";
import CreateInvoice30 from "./pages/CreateInvoice30";
import CreateInstallment from "./pages/CreateInstallment";
import Home from "./pages/Home";
import InvoiceSummary from "./pages/InvoiceSummary";
import InvoiceSummary30 from "./pages/InvoiceSummary30";
import InstallmentSummary from "./pages/InstallmentSummary";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Welcome from "./pages/Welcome";
import Account from "./pages/Account";
import Deposit from "./pages/Deposit";
import PayOut from "./pages/PayOut";
import News from "./pages/News";
import About from "./pages/About";
import Navigation2 from "./components/Navigation2";
import Footer from "./components/Footer";
import Transfer from "./pages/Transfer";
import Wallet from "./pages/Wallet";
import Activities from "./pages/Activities";
import PaymentMethods from "./pages/PaymentMethods";
import Help from "./pages/Help";
import Impressum from "./pages/Impressum";
import DataProtection from "./pages/DataProtection";

const App = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();

  useEffect(() => {
    const checkMobileAndWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', checkMobileAndWidth);

    return () => {
      window.removeEventListener('resize', checkMobileAndWidth);
    };
  }, []);

  return (
    <div>
      {windowWidth <= 850 ? (
        <Toaster position="top-center" reverseOrder={false} />
      ) : (<Toaster position="top-right" reverseOrder={false} />)}

      {windowWidth > 850 && 
      location.pathname !== '/' && 
      location.pathname !== "/login" && 
      location.pathname !== "/register" && 
      location.pathname !== "/transfer" && 
      location.pathname !== "/pay-out" && 
      location.pathname !== "/deposit" && 
      location.pathname !== "/impressum" && 
      location.pathname !== "/easycreditor-impressum" && 
      location.pathname !== "/data-protection" && 
      location.pathname !== "/easycreditor-data-protection" && 
      <Navigation2 />}

      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Welcome />
            </PublicRoute>
          }
        />

        <Route
          path="/impressum"
          element={
            <PublicRoute>
              <Impressum />
            </PublicRoute>
          }
        />

        <Route
          path="/data-protection"
          element={
            <PublicRoute>
              <DataProtection />
            </PublicRoute>
          }
        />

        <Route
          path="/easycreditor-impressum"
          element={
            <PrivateRoute>
              <Impressum />
            </PrivateRoute>
          }
        />

        <Route
          path="/easycreditor-data-protection"
          element={
            <PrivateRoute>
              <DataProtection />
            </PrivateRoute>
          }
        />

        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/news"
          element={
            <PrivateRoute>
              <News />
            </PrivateRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/account"
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        />
        <Route
          path="/deposit"
          element={
            <PrivateRoute>
              <Deposit />
            </PrivateRoute>
          }
        />
        <Route
          path="/pay-out"
          element={
            <PrivateRoute>
              <PayOut />
            </PrivateRoute>
          }
        />

        <Route
          path="/create-invoice"
          element={
            <PrivateRoute>
              <CreateInvoice />
            </PrivateRoute>
          }
        />

        <Route
          path="/create-invoice-30-days"
          element={
            <PrivateRoute>
              <CreateInvoice30 />
            </PrivateRoute>
          }
        />

        <Route
          path="/create-invoice-installment"
          element={
            <PrivateRoute>
              <CreateInstallment />
            </PrivateRoute>
          }
        />

        <Route
          path="/installment-summary/:id"
          element={
            <PrivateRoute>
              <InstallmentSummary />
            </PrivateRoute>
          }
        />

        <Route
          path="/invoice-summary/:id"
          element={
            <PrivateRoute>
              <InvoiceSummary />
            </PrivateRoute>
          }
        />

        <Route
          path="/invoice-summary-30-days/:id"
          element={
            <PrivateRoute>
              <InvoiceSummary30 />
            </PrivateRoute>
          }
        />

        <Route
          path="/about"
          element={
            <PrivateRoute>
              <About />
            </PrivateRoute>
          }
        />

        <Route
          path="/transfer"
          element={
            <PrivateRoute>
              <Transfer />
            </PrivateRoute>
          }
        />

        <Route
          path="/wallet"
          element={
            <PrivateRoute>
              <Wallet />
            </PrivateRoute>
          }
        />

        <Route
          path="/activities"
          element={
            <PrivateRoute>
              <Activities />
            </PrivateRoute>
          }
        />

        <Route
          path="/payment-methods"
          element={
            <PrivateRoute>
              <PaymentMethods />
            </PrivateRoute>
          }
        />

        <Route
          path="/help"
          element={
            <PrivateRoute>
              <Help />
            </PrivateRoute>
          }
        />

      </Routes>

      {location.pathname === '/transfer' ? (
        null
      ) : (
        <div>
          {location.pathname === '/' ? (<Footer mode={true} />) : (<Footer mode={false} />)}
        </div>
      )}
    </div>
  );
};

export default App;
