import React, { useState } from "react";
import { useGetUserQuery } from "../features/user/userApi";
import { Link } from "react-router-dom";

const Wallet = () => {
    const user = useGetUserQuery();
    const [activeButton, setActiveButton] = useState('EasyCreditor-Guthaben');
    const buttons = [
        { name: 'EasyCreditor-Guthaben', img: '/images/logo_solo.svg', detail: `${user?.data?.balance?.toFixed(2).toString().replace(".", ",")} EUR Verfügbar` },
        {
            name: 'Bankkonto', svg: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-8 text-primary">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
            </svg>, detail: user?.data?.iban
        },
    ];

    return (
        <div className="flex justify-center space-x-10">
            <div className="flex flex-col">

                <button className="flex flex-col items-center">
                    <div className="bg-primary bg-opacity-10 rounded-full p-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 text-primary">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                        </svg>
                    </div>
                    <h1 className="text-sm font-medium">Bankkonto hinzufügen</h1>
                </button>

                <br></br>

                <div className="flex flex-col gap-8">
                    {buttons.map((button, index) => (
                        <Link to={button.path} key={index}>
                            <button className={`rounded-xl py-2 px-10 hover:border-2 ${activeButton === button.name ? ' text-primary' : ''}`} onClick={() => setActiveButton(button.name)}>
                                <div className="flex space-x-2">
                                    {button.img && <img src={button.img} alt="logo" className="max-w-[35px]" />}
                                    {button.svg}
                                    <div className="flex flex-col items-start">
                                        <h1 className={`text-start text-lg ${activeButton === button.name ? 'font-bold' : ''}`} >{button.name}</h1>
                                        {button.detail && <p className="text-sm font-medium">{button.detail}</p>}
                                    </div>
                                </div>
                            </button>
                        </Link>
                    ))}
                </div>


            </div>

            <div className="flex flex-col items-center gap-2">
                <img src="/images/logo_solo.svg" className="" alt="logo" class="max-w-[60px]" />
                <h1 className="font-medium text-lg">EasyCreditor-Guthaben</h1>
                <h1 className="text-4xl font-medium">{user?.data?.balance?.toFixed(2).toString().replace(".", ",")} EUR</h1>
                <h1 className="font-medium text-lg">Verfügbar</h1>
                <Link to="/transfer" className="bg-dark rounded-full text-white font-medium py-3 px-10">Geld einzahlen oder abbuchen</Link>
                <h1 className="text-center max-w-md mt-10">Wir verwenden Ihr verfügbares Guthaben, wenn Sie Zahlungen tätigen.
                    Wenn Ihr Guthaben für eine Zahlung nicht ausreicht, schlagen wir Ihnen, weitere Zahlungsmöglichkeiten vor.</h1>
            </div>
        </div>
    );
}
export default Wallet;