import React, { useState, useEffect } from "react";
import { FaBell } from "react-icons/fa";
import { HiBars3 } from "react-icons/hi2";
import { Link } from "react-router-dom";
import Invoices from "../components/Home/Invoices";
import Navigation from "../components/Navigation";
import Banner from "../components/utilities/Banner";
import { useGetUserQuery } from "../features/user/userApi";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [activeNav, setActiveNav] = useState(false);
  const user = useGetUserQuery();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    const checkMobileAndWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', checkMobileAndWidth);

    return () => {
      window.removeEventListener('resize', checkMobileAndWidth);
    };
  }, []);

  return (
    <div>
      {windowWidth <= 850 && (
        <div className="px-[22px] py-5 relative pb-10">
          <div className="flex items-center justify-between mb-6">
            <div
              className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer"
              onClick={() => setActiveNav(true)}
            >
              <HiBars3 className="text-[352F48] text-lg" />
            </div>
            <div className="">
              <img className="cursor-pointer" src="/images/logo1.svg" alt="logo" class="w-40" />
            </div>

            <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer relative">
              <Link to="/news">
                <FaBell className="text-[352F48] text-lg" />
              </Link>
              <div className="absolute top-0 right-0 h-3 w-3 rounded-full bg-secondary"></div>
            </div>
          </div>

          {/* Navigation */}
          <Navigation activeNav={activeNav} setActiveNav={setActiveNav} />

          <Banner
            title="Kontostand:"
            heading={!user.data ? "loading..." : `${user.data.balance.toFixed(2).toString().replace(".", ",")}€`}
          />

          <div className="mt-6">
            <Link to="/payment-methods">
              <div className="text-center min-h-[232px] flex items-center flex-col justify-center gap-4 rounded-lg shadow-sm border bg-white">
                <div className="w-[70px] h-[70px] rounded-full bg-[#DDEEFF] flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 text-primary">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                </div>
                <h3 className="text-lg text-dark font-medium">Geld senden</h3>
              </div>
            </Link>
          </div>

          <div className="mt-5">
            <h1 className="mb-5 text-md">Letzte Aktivitäten</h1>
            <Invoices />
            <Link to="/activities">
              <h1 className="text-primary font-medium pt-5 text-md">Alle anzeigen</h1>
            </Link>

          </div>
        </div>
      )}

      {windowWidth > 850 && (
        <div>
          <div className="flex justify-center space-x-10">

            <div className="flex flex-col space-y-5">
              <div className="border rounded-lg transition-all shadow-sm hover:shadow-md p-5">
                <div>
                  <Link to="/wallet" className="font-bold">EasyCreditor-Guthaben</Link>
                </div>

                <br></br>
                <h1 className="font-bold text-4xl mb-2">{!user.data ? "loading..." : `${user.data.balance.toFixed(2).toString().replace(".", ",")} €`}</h1>
                <h1>Verfügbar</h1>
                <br></br>
                <Link to="/transfer" className="bg-dark rounded-full text-white font-medium py-2 px-10">Geld einzahlen oder abbuchen</Link>
              </div>

              <div className="border rounded-lg transition-all shadow-sm hover:shadow-md p-5">
                <h1 className="mb-5 text-lg">Letzte Aktivitäten</h1>
                <h1 className="mb-5">Hier sehen Sie Ihre letzten Aktivitäten, wie ein- und ausgehende Zahlungen.</h1>

                <Invoices />

                <Link to="/activities">
                  <h1 className="text-primary font-medium pt-5 text-lg">Alle anzeigen</h1>
                </Link>

              </div>
            </div>

            <div className="border transition-all shadow-sm hover:shadow-md w-auto p-5 rounded-lg">
              <button onClick={() => navigate("/payment-methods")} className="bg-primary rounded-full text-white font-bold py-3 w-full px-5">Geld senden</button>
              <div className="font-bold mb-5 mt-10">Bankkonten</div>
              <h1 className="mb-5">{user?.data?.iban === "empty" ? "Keine" : user?.data?.iban}</h1>
              <Link className="font-bold text-primary">Bankkonto hinzufügen</Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
