import { apiSlice } from "../api/apiSlice";
import { userLoggedIn } from "./authSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    // Post Request wird an Server gestellt unter der URL
    register: builder.mutation({
      query: (data) => ({
        url: "/api/auth/register",
        method: "POST",
        body: data,
      }),
    }),
    setIban: builder.mutation({
      query: (data) => ({
        url: "/api/auth/iban",
        method: "POST",
        body: data,
      }),
    }),
    checkEmail: builder.mutation({
      query: (email) => ({
        url: `/api/auth/check-email`,
        method: "POST",
        body: { email },
      }),
    }),
    checkUsername: builder.mutation({
      query: (username) => ({
        url: `/api/auth/check-username`,
        method: "POST",
        body: { username },
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: "/api/auth/login",
        method: "POST",
        body: data,
      }),
      
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          const result = await queryFulfilled;

          // set data in localstorage
          localStorage.setItem(
            "auth",
            JSON.stringify({
              accessToken: result.data.token,
              user: result.data.user,
            })
          );

          // dispatch userLoggedIn action
          dispatch(
            userLoggedIn({
              accessToken: result.data.token,
              user: result.data.user, // Hier wird das angemeldete User objekt mit den daten gefüllt
            })
          );
        } catch (err) {
          // do nothing
          console.log(err);
        }
      },
    }),
  }),
});

export const { useLoginMutation, useCheckEmailMutation, useCheckUsernameMutation, useRegisterMutation, useSetIbanMutation } =
  authApi;
