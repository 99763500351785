import React, { useState } from "react";
import CreateInvoiceForm30 from "../components/Invoice/CreateInvoiceForm30";
import InvoiceDetails30 from "../components/Invoice/InvoiceDetails30";

const tabs = {
  1: CreateInvoiceForm30,
  2: InvoiceDetails30,
};

const CreateInvoice30 = () => {
  const [activeTab, setActiveTab] = useState(1);
  const Tab = tabs[activeTab];

  return (
    <div>
      <Tab
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};

export default CreateInvoice30;
