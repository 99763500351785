import React from "react";

const Input = ({ type, id, placeholder, className, value, ...rest }) => {

  return (
    <input
      type={type}
      id={id}
      placeholder={placeholder}
      className={`w-full block border rounded-lg px-5 py-3 outline-none focus:ring-1 ring-dark text-dark placeholder:text-light text-base font-normal ${className}`}
      value={value}
      {...rest}
    />
  );
};

export default Input;
