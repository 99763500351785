import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: "",
    amount: 0,
    description: "",
    receiverUser: {},
    senderUser: {},
};


const invoiceSlice = createSlice({
    name: "invoice",
    initialState,
    reducers: {

        setInvoice: (state, action) => {
            state.id = action.payload._id;
            state.amount = action.payload.amount;
            state.description = action.payload.description;
            state.receiverUser = action.payload.receiverUser;
            state.senderUser = action.payload.senderUser;
        },
    },
});

export default invoiceSlice.reducer; // Store
export const { setInvoice } = invoiceSlice.actions;
