import { apiSlice } from "../api/apiSlice";

export const installmentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query({
      query: () => `/api/invoices`,
      providesTags: ["getInstallment"],
    }),




    getInstallment: builder.query({
      query: (id) => `/api/installment/single-installment/${id}`,
    }),

    createInstallment: builder.mutation({
      query: (data) => ({
        url: `/api/installment`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getInstallment"],
    }),




    updateInvoiceStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/api/invoices/update-status/${id}`,
        method: "PATCH",
        body: { status },
      }),
      invalidatesTags: ["getInvoices", "getUser"],
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  
  useGetInstallmentQuery,
  useCreateInstallmentMutation,

  useUpdateInvoiceStatusMutation,
} = installmentApi;