import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { store } from "./app/store";
import { userLoggedIn } from "./features/auth/authSlice";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// Element mit der ID "root" aus dem DOM holen und eine Wurzel erstellen
const container = document.getElementById("root");
const root = createRoot(container);

// Überprüfen, ob Authentifizierungsdaten im localStorage vorhanden sind
const localAuth = localStorage?.getItem("auth");

// Wenn Authentifizierungsdaten vorhanden sind, versuche sie zu parsen und setze den Redux-Store mit den Daten
if (localAuth) {
  const auth = JSON.parse(localAuth);
  if (auth?.accessToken && auth?.user) {
    store.dispatch(
      userLoggedIn({ accessToken: auth.accessToken, user: auth.user })
    );
  }
}

// Rendern der App-Komponente innerhalb des Providers und der Router-Komponente
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// Aufruf der Funktion zum Messen der Performance der Anwendung
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
