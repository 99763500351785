import React, { useEffect, useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCreateInstallmentMutation } from "../../features/invoice/installmentApi";
import { useUpdateInvoiceStatusMutation } from "../../features/invoice/invoiceApi";
import Button from "../utilities/Button";
import SuccessModal from "../utilities/SuccessModal";

const InvoiceDetails = ({
  activeTab,
  setActiveTab,
  isBack,
  summary,
}) => {
  const [created, setCreated] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { user: loggedInUser } = useSelector((state) => state.auth || {});
  const installment = useSelector((state) => state.installment || {});
  const {
    id,
    brutto,
    netto,
    profit,
    description,
    receiverUser,
    senderUser,
    rates
  } = installment || {};

  const [createInstallment, { data: newInvoice }] = useCreateInstallmentMutation();
  const [successModalStatus, setSuccessModalStatus] = useState("abgelehnt");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const checkMobileAndWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', checkMobileAndWidth);

    return () => {
      window.removeEventListener('resize', checkMobileAndWidth);
    };
  }, []);

  useEffect(() => {
    if (summary) {
      setCreated(true);
    }
  }, [summary]);

  useEffect(() => {
    if (newInvoice?._id) {
      setIsOpen(true);
    }
  }, [newInvoice]);

  return (
    <div>
      {windowWidth <= 850 && (
        <div className="mt-5">
          <div className="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center ml-5 mb-10">
            <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
              <AiOutlineLeft
                onClick={() =>
                  isBack ? navigate("/home") : setActiveTab((prev) => prev - 1)
                }
                className="text-[352F48] text-lg"
              />
            </div>
            <h1 className="text-lg font-medium m-auto">Ratenzahlung</h1>
            <div></div>
          </div>

          <div className="mt-5 px-[22px]">
            <div>
              <div className="flex flex-col gap-10">

                <div className="flex items-center justify-between">
                  <div className="flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-primary">
                      <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                    </svg>

                    <span className="text-primary text-base font-medium">Sender</span>
                  </div>
                  <h3 className="text-base font-medium text-primary">{senderUser.username}</h3>
                </div>

                <div className={`flex items-center justify-between gap-2`}>
                  <div className="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-light">
                      <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                    </svg>

                    <span className={`text-light text-base`}>Empfänger</span>
                  </div>

                  <h3 className={`text-base font-medium text-dark`}>
                    {receiverUser.username}
                  </h3>
                </div>

                <div className="flex items-center justify-between gap-2">
                  <span className="text-light text-base">Beschreibung</span>
                  <h3 className="text-base font-medium text-dark">
                    {description ? description : "Leer"}
                  </h3>
                </div>

                <div className="flex items-center justify-between gap-2">
                  <span className="text-light text-base">Betrag</span>
                  <h3 className="text-base font-medium text-dark">{(parseFloat(netto)).toFixed(2).toString().replace(".", ",")} €</h3>
                </div>

                <div className="flex items-center justify-between gap-2">
                  <span className="text-light text-base">Zinsen</span>
                  <h3 className="text-base font-medium text-dark">+ {(brutto - netto).toFixed(2).toString().replace(".", ",")} €</h3>
                </div>

                <div className="divide-y">
                  <div>
                  </div>
                  <div className="flex items-center justify-between gap-2">
                    <span className="text-light text-base font-bold">Summe</span>
                    <h3 className="text-base font-bold text-dark">
                      {(parseFloat(brutto)).toFixed(2).toString().replace(".", ",")} €
                    </h3>
                  </div>
                </div>

                {loggedInUser?._id === senderUser?._id &&

                  <div className="bg-gray-200 p-5 flex flex-col gap-2">
                    <div className="flex items-center justify-between">
                      <span className="text-light text-base">Anzahl der Raten</span>
                      <h3 className="text-base font-medium text-dark">{rates?.length} Raten</h3>
                    </div>

                    <div className="flex items-center justify-between">
                      <span className="text-light text-base">Rate</span>
                      <h3 className="text-base font-medium text-dark">{String(rates[0]?.amount).replace(".", ",")} €</h3>
                    </div>
                  </div>
                }
              </div>

              {!created && !summary && (
                <div className="mt-20 flex flex-col gap-4">
                  <Button
                    onClick={() => {
                      createInstallment({
                        receiver: receiverUser?._id,
                        sender: senderUser?._id,
                        brutto,
                        netto,
                        profit,
                        description,
                        rates
                      })
                      setSuccessModalStatus("erstellt")
                    }

                    }>Geld senden</Button>
                </div>
              )}
            </div>
          </div>

          {/* show modal when invoice created successfully */}
          <SuccessModal isOpen={isOpen} status={successModalStatus}>
            <Button
              onClick={() => { navigate("/home") }}
              notIcon={true}
            >
              Weiter
            </Button>
          </SuccessModal>
        </div>
      )}

      {windowWidth > 850 && (
        <div>
          <div className="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center ml-5 mb-10">
            <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
              <AiOutlineLeft
                onClick={() =>
                  isBack ? navigate("/home") : setActiveTab((prev) => prev - 1)
                }
                className="text-[352F48] text-lg"
              />
            </div>
            <h1 className="text-lg font-medium m-auto">Ratenzahlung</h1>
            <div></div>
          </div>

          <div className="flex flex-col gap-10 mt-5 items-center">

            <div className="flex items-center justify-between w-1/2">
              <div className="flex justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-primary">
                  <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                </svg>

                <span className="text-primary text-base font-medium">Sender</span>
              </div>
              <h3 className="text-base font-medium text-primary">{senderUser.username}</h3>
            </div>

            <div className={`flex items-center justify-between w-1/2`}>
              <div className="flex">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-light">
                  <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                </svg>

                <span className={`text-light text-base`}>Empfänger</span>
              </div>

              <h3 className={`text-base font-medium text-dark`}>
                {receiverUser.username}
              </h3>
            </div>

            <div className="flex items-center justify-between w-1/2">
              <span className="text-light text-base">Beschreibung</span>
              <h3 className="text-base font-medium text-dark">
                {description ? description : "Leer"}
              </h3>
            </div>

            <div className="flex items-center justify-between w-1/2">
              <span className="text-light text-base">Betrag</span>
              <h3 className="text-base font-medium text-dark">{(parseFloat(netto)).toFixed(2).toString().replace(".", ",")} €</h3>
            </div>

            <div className="flex items-center justify-between w-1/2">
              <span className="text-light text-base">Zinsen</span>
              <h3 className="text-base font-medium text-dark">+ {(brutto - netto).toFixed(2).toString().replace(".", ",")} €</h3>
            </div>

            <div className="divide-y w-1/2">
              <div>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-light text-base font-bold">Summe</span>
                <h3 className="text-base font-bold">
                  {(parseFloat(brutto)).toFixed(2).toString().replace(".", ",")} €
                </h3>
              </div>
            </div>

            {loggedInUser?._id === senderUser?._id &&
              <div className="bg-gray-200 p-5 flex flex-col gap-5 w-1/2">
                <div className="flex items-center justify-between">
                  <span className="text-light text-base">Anzahl der Raten</span>
                  <h3 className="text-base font-medium text-dark">{rates?.length} Raten</h3>
                </div>

                <div className="flex items-center justify-between">
                  <span className="text-light text-base">Rate</span>
                  <h3 className="text-base font-medium text-dark">{String(rates[0]?.amount).replace(".", ",")} €</h3>
                </div>
              </div>
            }

            {!created && !summary && (
              <div className="w-1/2">
                <Button
                  onClick={() => {
                    createInstallment({
                      receiver: receiverUser?._id,
                      sender: senderUser?._id,
                      brutto,
                      netto,
                      profit,
                      description,
                      rates
                    })
                    setSuccessModalStatus("erstellt")
                  }

                  }>Geld senden</Button>
              </div>
            )}
          </div>

          <SuccessModal isOpen={isOpen} status={successModalStatus}>
            <Button
              onClick={() => { navigate("/home") }}
              notIcon={true}
            >
              Weiter
            </Button>
          </SuccessModal>
        </div>
      )}

    </div>
  );
};
export default InvoiceDetails;
