import React from "react";

const NewsCard = ({ head, body, date }) => {
    return (
        <div className="flex bg-dark rounded-xl overflow-hidden">
            <div>
                <div className="font-bold text-white m-2 text-xl">
                    {head}
                </div>

                <div className="ml-2 mr-2 font-medium text-white text-justify">
                    {body}
                </div>

                <div className="flex justify-end mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 text-light">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <h1 className="text-sm font-light text-light text-right ml-1">{date}</h1>
                </div>

            </div>

        </div>

    );
};

export default NewsCard;
