import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: "",
    amount: 0,
    description: "",
    receiverUser: {},
    senderUser: {},
    processed: false,
    dueDate: ""
};


const invoice30Slice = createSlice({
    name: "invoice30",
    initialState,
    reducers: {

        setInvoice30: (state, action) => {
            state.id = action.payload._id;
            state.amount = action.payload.amount;
            state.description = action.payload.description;
            state.receiverUser = action.payload.receiverUser;
            state.senderUser = action.payload.senderUser;
            state.processed = action.payload.processed;
            state.dueDate = action.payload.dueDate;
        },
    },
});

export default invoice30Slice.reducer;
export const { setInvoice30 } = invoice30Slice.actions;