import React, { useState, useEffect } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Input from "../components/utilities/Input";
import { useCreatePayoutMutation } from "../features/jira/jiraApi";
import { useGetUserQuery } from "../features/user/userApi";
import { useSetIbanMutation } from "../features/auth/authApi";
import toast from "react-hot-toast";

const PayOut = () => {
    const navigate = useNavigate();
    const [auszahlungsBetrag, setAuszahlungsBetrag] = useState(0);
    const [iban, setIban] = useState("");
    const [createPayout] = useCreatePayoutMutation();
    const loggedInUserQuery = useGetUserQuery();
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [dispatchIban, { data: updatedUser }] = useSetIbanMutation();
    const [errors, setErrors] = useState({});
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    useEffect(() => {
        const checkMobileAndWidth = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', checkMobileAndWidth);

        return () => {
            window.removeEventListener('resize', checkMobileAndWidth);
        };
    }, []);

    useEffect(() => {
        if (loggedInUserQuery.isSuccess && loggedInUserQuery.data) {
            setLoggedInUser(loggedInUserQuery.data);
        }
    }, [loggedInUserQuery]);

    useEffect(() => {
        if (updatedUser && loggedInUser) {
            setLoggedInUser(updatedUser);
        }
    }, [updatedUser]);

    const submithandler = () => {
        setErrors({});
        const validationErrors = {};

        if (!auszahlungsBetrag || parseFloat(auszahlungsBetrag) === 0) {
            validationErrors.auszahlungsBetrag = "Bitte Auszahlungsbetrag eintragen.";
        }

        if (parseFloat(auszahlungsBetrag) < 0) {
            validationErrors.auszahlungsBetrag = "Bitte positiven Auszahlungsbetrag eintragen.";
        }

        if (parseFloat(auszahlungsBetrag) > loggedInUser.balance) {
            validationErrors.auszahlungsBetrag = "Ihr aktuelles Guthaben deckt nicht den Auszahlungsbetrag.";
        }

        if (Object.keys(validationErrors)?.length > 0) {
            return setErrors(validationErrors);
        }

        createPayout({ auszahlungsBetrag })
        toast.success("Auszahlung bestätigt!");
        navigate("/home");
    }

    return (
        <div>
            {windowWidth <= 850 && (
                <div className="flex flex-col gap-10 mt-5">

                    <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center mx-5">
                        <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
                            <AiOutlineLeft
                                onClick={() => navigate("/home")}
                                className="text-[352F48] text-lg"
                            />
                        </div>

                        <h1 className="text-md font-medium ml-auto">Abbuchungsbetrag</h1>
                        <div></div>

                    </div>

                    <div className="flex flex-col gap-5 mx-5">
                        <h1 className="font-bold text-lg text-dark">Auszahlen</h1>
                        <Input
                            id="payoutamount"
                            type="number"
                            label="Betrag"
                            placeholder="0,00€"
                            value={auszahlungsBetrag === null ? "" : auszahlungsBetrag}
                            onChange={(e) => setAuszahlungsBetrag(e.target.value)}
                            style={{ textAlign: 'right' }}
                        />
                        {errors?.auszahlungsBetrag && (
                            <p className="text-red-600">{errors?.auszahlungsBetrag}</p>
                        )}

                        {loggedInUser?.iban !== "empty" && (
                            <div>
                                <h1 className="mb-2">Auszahlung erfolgt auf folgendes Bankkonto:</h1>
                                <div className="bg-gray-300 p-2 rounded-lg font-medium">
                                    <p>{loggedInUser?.firstName} {loggedInUser?.lastName}</p>
                                    <p>{loggedInUser?.iban}</p>
                                </div>
                            </div>
                        )}

                        {loggedInUser?.iban === "empty" && (
                            <p className="bg-yellow-100 p-2 px-3 mb-5">Sie haben uns Ihr Bankkonto noch nicht mitgeteilt. Wenn Sie Geld auszahlen möchten, hinterlegen Sie unter "Wallet/Bankkonto hinzufügen" Ihr Bankkonto.</p>
                        )}


                        {/*                         {loggedInUser?.iban === "empty" && (
                            <>
                                <div className="mb-3">
                                    <p className="bg-yellow-100 p-2 px-3 mb-5">Sie haben uns Ihre IBAN noch nicht mitgeteilt. Wenn Sie Geld auszahlen möchten, geben Sie die IBAN Ihres Auszahlungskontos ein.</p>
                                    <Input
                                        id="IBAN"
                                        type="text"
                                        label="IBAN"
                                        placeholder="Bitte IBAN eingeben."
                                        value={iban}
                                        onChange={(e) => setIban(e.target.value)}
                                    />
                                </div>
                                <button
                                    className="bg-primary py-3 rounded-full text-white font-bold"
                                    onClick={() =>
                                        dispatchIban({ iban })
                                    }
                                >IBAN Bestätigen</button>
                            </>
                        )} */}
                    </div>

                    {loggedInUser?.iban !== "empty" && (
                        <button
                            className="bg-primary py-3 rounded-full text-white font-bold mx-5"
                            onClick={() => submithandler()}
                        >Jetzt Auszahlen</button>
                    )}
                </div >
            )}

            {windowWidth > 850 && (
                <div className="flex flex-col gap-5 m-5">

                    <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center">
                        <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
                            <AiOutlineLeft
                                onClick={() => navigate("/transfer")}
                                className="text-[352F48] text-lg"
                            />
                        </div>

                        <h1 className="text-lg font-medium ml-auto">Abbuchungsbetrag</h1>
                        <div></div>
                    </div>

                    <div className="flex flex-col items-center">
                        <div className="flex flex-col gap-10 mt-5">
                            <div>
                                <h1 className="font-bold text-lg text-dark">Auszahlen</h1>
                                <Input
                                    id="payoutamount"
                                    type="number"
                                    label="Betrag"
                                    placeholder="0,00€"
                                    value={auszahlungsBetrag === null ? "" : auszahlungsBetrag}
                                    onChange={(e) => setAuszahlungsBetrag(e.target.value)}
                                    style={{ textAlign: 'right' }}
                                />

                                <h1>Verfügbares Guthaben: {(loggedInUser?.balance)?.toFixed(2).replace(".", ",")} €</h1>

                                {errors?.auszahlungsBetrag && (
                                    <p className="text-red-600">{errors?.auszahlungsBetrag}</p>
                                )}
                            </div>


                            {loggedInUser?.iban !== "empty" && (
                                <div>
                                    <h1 className="mb-2">Auszahlung erfolgt auf folgendes Bankkonto:</h1>
                                    <div className="bg-gray-300 p-2 rounded-lg font-medium">
                                        <p>{loggedInUser?.firstName} {loggedInUser?.lastName}</p>
                                        <p>{loggedInUser?.iban}</p>
                                    </div>
                                </div>
                            )}

                            {loggedInUser?.iban === "empty" && (
                                <p className="bg-yellow-100 p-2 px-3 mb-5">Sie haben uns Ihr Bankkonto noch nicht mitgeteilt. Wenn Sie Geld auszahlen möchten, hinterlegen Sie unter "Wallet/Bankkonto hinzufügen" Ihr Bankkonto.</p>
                            )}

                            {/* {loggedInUser?.iban === "empty" && (
                                <>
                                    <div className="mb-3">
                                        <p className="bg-yellow-100 p-2 px-3 mb-5">Sie haben uns Ihre IBAN noch nicht mitgeteilt. Wenn Sie Geld auszahlen möchten, geben Sie die IBAN Ihres Auszahlungskontos ein.</p>
                                        <Input
                                            id="IBAN"
                                            type="text"
                                            label="IBAN"
                                            placeholder="Bitte IBAN eingeben."
                                            value={iban}
                                            onChange={(e) => setIban(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        className="bg-primary py-3 rounded-full text-white font-bold"
                                        onClick={() =>
                                            dispatchIban({ iban })
                                        }
                                    >IBAN Bestätigen</button>
                                </>
                            )} */}

                            {loggedInUser?.iban !== "empty" && (
                                <button
                                    className="bg-primary py-3 rounded-full text-white font-bold"
                                    onClick={() => submithandler()}
                                >Jetzt Auszahlen</button>
                            )}
                        </div>
                    </div >
                </div>
            )}
        </div>

    );
};

export default PayOut;
