import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useCheckEmailMutation, useCheckUsernameMutation } from "../../features/auth/authApi";
import { userRegister } from "../../features/auth/authSlice";
import Button from "../utilities/Button";
import Input from "../utilities/Input";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const RegisterInfo = ({ setActiveTab }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const [checkEmail] = useCheckEmailMutation();
  const [checkUsername] = useCheckUsernameMutation();

  useEffect(() => {
    const checkMobileAndWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', checkMobileAndWidth);

    return () => {
      window.removeEventListener('resize', checkMobileAndWidth);
    };
  }, []);

  // submit handler
  const submitHandler = async () => {
    setErrors({});
    const validationErrors = {};

    const existingEmailUser = await checkEmail(email);
    if (email && existingEmailUser?.data !== null) {
      validationErrors.email = "Diese Email ist schon mit einem anderen Account verbunden.";
    }

    const existingUsernameUser = await checkUsername(username);
    if (username && existingUsernameUser?.data !== null) {
      validationErrors.username = "Diese Username ist schon mit einem anderen Account verbunden.";
    }

    if (!username) {
      validationErrors.username = "Bitte Username eintragen.";
    }

    if (!email) {
      validationErrors.email = "Bitte Email eintragen.";
    } else if (email.indexOf("@") === -1) {
      validationErrors.email = "Email muss gültig sein.";
    }

    if (!phone) {
      validationErrors.phone = "Bitte Telefonnummer eintragen.";
    } else if (!/^\+?\d{1,14}$/.test(phone)) {
      validationErrors.phone = "Telefonnummer muss gültig sein.";
    }

    if (!password) {
      validationErrors.password = "Bitte Passwort eintragen.";
    } else if (password.length <= 7) {
      validationErrors.password = "Passwort muss mindestens 8 Zeichen haben.";
    }

    if (!confirmPassword) {
      validationErrors.confirmPassword = "Bestätigen Sie ihr Passwort.";
    } else if (confirmPassword !== password) {
      validationErrors.confirmPassword = "Passwort stimmt nicht überein.";
    }


    if (Object.keys(validationErrors)?.length > 0) {
      return setErrors(validationErrors);
    }

    if (
      Object.keys(validationErrors)?.length === 0 &&
      Object.keys(errors)?.length === 0
    ) {
      const emailLow = email.toLowerCase();
      const usernameLow = username.toLowerCase();
      dispatch(
        userRegister({
          username: usernameLow,
          email: emailLow,
          phone,
          password,
        })
      );
      setActiveTab((prev) => prev + 1);
    }
  };

  return (
    <div className={windowWidth <= 1024 ? "w-full" : "w-1/3"}>
      <div className="flex flex-col gap-5">

        <div>
          <Input
            id="email"
            type="text"
            label="Email"
            placeholder="Email eingeben."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors?.email && (
            <p className="text-red-600">{errors?.email}</p>
          )}
        </div>

        <div>
          <Input
            id="username"
            type="text"
            label="Username"
            placeholder="Username eingeben."
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          {errors?.username && (
            <p className="text-red-600">{errors?.username}</p>
          )}
        </div>

        <div>
          <div className={`w-full block border rounded-lg px-5 py-3 outline-none focus:ring-1 ring-dark text-dark placeholder:text-light text-base font-normal`}>
            <PhoneInput
              defaultCountry="DE"
              international
              placeholder="Enter phone number"
              value={phone}
              onChange={setPhone}
            />
          </div>
          {errors?.phone && (
            <p className="mt-2 text-red-600">{errors?.phone}</p>
          )}
        </div>

        <div>
          <Input
            type="password"
            id="passwort1"
            placeholder="Passwort eingeben."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors?.password && (
            <p className="mt-2 text-red-600">{errors?.password}</p>
          )}
        </div>

        <div>
          <Input
            type="password"
            id="passwort2"
            placeholder="Passwort erneut eingeben."
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {errors?.confirmPassword && (
            <p className="mt-2 text-red-600">{errors?.confirmPassword}</p>
          )}
        </div>

      </div>

      <p className="text-sm text-light mt-4">
        Indem Sie fortfahren, stimmen Sie unseren Bedingungen und der Datenschutzerklärung zu.
      </p>

      <div className="mt-10">

        <Button onClick={submitHandler}>Weiter</Button>

        <p className="mt-5 text-base text-black font-medium text-center">
          Sie haben bereits ein Konto?{" "}
          <Link className="text-primary" to="/login">
            Anmelden.
          </Link>
        </p>

      </div>
    </div>
  );
};
export default RegisterInfo;