import React from "react";

const SuccessModal = ({ isOpen, status, children }) => {
  return (
    <div
      className={`transition-all duration-200 ${isOpen ? "scale-100" : "scale-0"
        } fixed top-0 left-0
   right-0 bottom-0 w-full h-full bg-gray-200/90 z-50 flex items-center justify-center`}
    >
      <div className="w-[90%] mx-auto bg-white rounded-xl flex flex-col justify-center items-center p-8 gap-6">

        {(status === "akzeptiert" || status === "ausstehend" || status === "erstellt") && (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-28 text-secondary">
            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
          </svg>
        )}

        {status === "abgelehnt" && (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-28 text-red-500">
            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
          </svg>
        )}

        {(status === "akzeptiert") && (
          <h3 className="text-dark font-bold text-lg">
            Contract wurde angenommen!
          </h3>
        )}

        {(status === "erstellt") && (
          <h3 className="text-dark font-bold text-lg">
            Contract wurde erstellt!
          </h3>
        )}

        {status === "abgelehnt" && (
          <h3 className="text-dark font-bold text-lg">
            Contract wurde abgelehnt.
          </h3>
        )}

        {children}
      </div>
    </div>
  );
};

export default SuccessModal;
