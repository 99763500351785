import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import InvoiceDetails30 from "../components/Invoice/InvoiceDetails30";
import { useGetInvoice30Query } from "../features/invoice/invoice30Api";
import { setInvoice30 } from "../features/invoice/invoiceSlice30";

const InvoiceSummary30 = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data } = useGetInvoice30Query(id);

  useEffect(() => {
    if (data?._id) {
      dispatch(
        setInvoice30({
          ...data,
          senderUser: data?.sender,
          receiverUser: data?.receiver,
        })
      );
    }
  }, [data, dispatch]);
  
  return <InvoiceDetails30 summary={true} isBack={"/"} />;
};

export default InvoiceSummary30;
