import React, { useState } from "react";
import CreateInvoiceForm from "../components/Invoice/CreateInvoiceForm";
import InvoiceDetails from "../components/Invoice/InvoiceDetails";

const tabs = {
  1: CreateInvoiceForm,
  2: InvoiceDetails,
};

const CreateInvoice = () => {
  const [activeTab, setActiveTab] = useState(1);
  const Tab = tabs[activeTab];

  return (
    <div>
      <Tab
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};

export default CreateInvoice;
