import React, { useEffect, useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setInstallment } from "../../features/invoice/installmentSlice";
import { useGetUserByUsernameQuery } from "../../features/user/userApi";
import Input from "../utilities/Input";
import Textarea from "../utilities/Textarea";
import { useGetUserQuery } from "../../features/user/userApi";

const calculateRate = (P, r_annual, n) => {
  let r_monthly = r_annual / 12;
  let rateAmount = P * (r_monthly * Math.pow((1 + r_monthly), n)) / (Math.pow((1 + r_monthly), n) - 1);
  return rateAmount.toFixed(2);
}
const fillRates = (number, rate) => {
  const newRates = [];
  for (let i = 0; i < number; i++) {
    const dueDate = new Date();
    dueDate.setMonth(dueDate.getMonth() + i);

    newRates.push({
      amount: rate,
      dueDate: dueDate.toISOString().slice(0, 10),
      paid: false,
      paidDate: ""
    });
  }
  return newRates;
}

const CreateInvoiceInstallment = ({ activeTab, setActiveTab }) => {
  const loggedInUser = useGetUserQuery().data;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const zinssatz = 0.1;

  const [getUser, setGetUser] = useState(false);
  const [username, setUsername] = useState("");
  const { data: user } = useGetUserByUsernameQuery(username.toLowerCase(), {
    skip: !getUser,
  });
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [userMsg, setUserMsg] = useState({
    status: "",
    msg: "",
  });
  const [errors, setErrors] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [activeButton, setActiveButton] = useState(2);
  const [rates, setRates] = useState([]);
  const [rate, setRate] = useState(0);

  const [netto, setNetto] = useState(0)
  const [brutto, setBrutto] = useState(0)
  const [profit, setProfit] = useState(0)

  useEffect(() => {
    const checkMobileAndWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', checkMobileAndWidth);

    return () => {
      window.removeEventListener('resize', checkMobileAndWidth);
    };
  }, []);

  useEffect(() => {
    setNetto(amount)
    setBrutto(rate * activeButton)
    setProfit(brutto - netto)
  })

  useEffect(() => {
    if (user?._id) {
      setUserMsg({ status: "success", msg: "Vertragspartner gefunden!" });
    } else if (user?.message && username) {
      setUserMsg({ status: "error", msg: "Vertragspartner nicht gefunden!" });
    }
  }, [user, username]);

  useEffect(() => {
    const calculatedRate = calculateRate(amount, zinssatz, activeButton);
    setRate(calculatedRate);
    setRates(fillRates(activeButton, calculatedRate));
  }, [activeButton, amount, zinssatz]);


  const submitHandler = () => {
    setErrors({});
    const validationErrors = {};

    if (!username) {
      validationErrors.username = "Bitte Username eintragen.";
    }

    if (!user?._id) {
      setUserMsg({ status: "error", msg: "Vertragspartner nicht gefunden!" });
      return;
    }

    if (!amount) {
      validationErrors.amount = "Bitte Betrag eintragen.";
    }

    if (amount >= 2000) {
      validationErrors.amount = "Nur Beträge bis 2000€ sind möglich.";
    }

    if (amount < 100) {
      validationErrors.amount = "Bitte einen Betrag über 100 € eintragen.";
    }

    if ((Math.floor(parseFloat(amount) * 100) / 100) !== parseFloat(parseFloat(amount).toFixed(2))) {
      validationErrors.amount = "Bitte Betrag nur 2 Nachkommastellen eintragen.";
    }

    if (amount.includes(",")) {
      setAmount(amount.replace(",", "."));
    }

    if (Object.keys(validationErrors)?.length > 0) {
      return setErrors(validationErrors);
    }

    if (Object.keys(validationErrors)?.length === 0 && loggedInUser) {
      try {
        dispatch(
          setInstallment({
            brutto,
            netto,
            profit,
            description,
            receiverUser: user,
            senderUser: loggedInUser,
            rates,
          })
        );
        setActiveTab((prev) => prev + 1);
      } catch (err) {
        console.log("Fehler:", err)
      }
    }
  };

  return (
    <div>

      {windowWidth <= 850 && (
        <div>
          <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center p-5">
            <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
              <AiOutlineLeft
                onClick={() => navigate("/payment-methods")}
                className="text-[352F48] text-lg"
              />
            </div>
            <h1 className="text-md font-medium ml-auto">Ratenzahlung</h1>
            <button
              className="text-primary text-base font-medium ml-auto"
              onClick={() => {
                submitHandler();
              }}>Weiter</button>
          </div>


          <div className="px-[22px] flex flex-col gap-5">

            <div>
              <label className="text-sm text-light">{"Username"}</label>
              <div className="relative">
                <Input
                  id="username"
                  type="text"
                  placeholder="Username des Vertragspartners"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    setGetUser(true);
                  }}
                />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="absolute top-[50%] transform translate-y-[-50%] right-4 w-5 text-primary">
                  <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                </svg>
              </div>

              {errors?.username && (
                <p className="text-red-600">{errors?.username}</p>
              )}
              {userMsg?.msg && (
                <p
                  className={`mt-2 ${userMsg?.status === "success"
                    ? "text-green-500"
                    : "text-red-600"
                    }`}
                >
                  {userMsg?.msg}
                </p>
              )}
            </div>

            <div>
              <label className="text-sm text-light">{"Betrag"}</label>
              <div>
                <div className="relative">
                  <Input
                    id="amount"
                    type="number"
                    placeholder="0,00€"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <span className="absolute top-[50%] transform translate-y-[-50%] right-4 text-primary text-base">
                    €
                  </span>
                </div>
                {errors?.amount && (
                  <p className="mt-2 text-red-600">{errors?.amount}</p>
                )}
              </div>
            </div>

            <div>
              <label className="text-sm text-light">{"Verwendungszweck"}</label>
              <Textarea
                id="description"
                placeholder="Bitte Verwendungszweck eingeben."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="grid grid-cols-2 gap-1">
              <div className="grid gird-cols-1 gap-1">
                {[
                  { number: 2 },
                  { number: 4 }
                ].map((button, index) => (
                  <button
                    className={`w-full border rounded-md p-4 ${activeButton === button.number ? 'border-2 border-primary' : ''}`}
                    key={index}
                    onClick={() => {
                      setActiveButton(button.number);
                    }}
                  >
                    <div className="flex justify-between">
                      <p className="font-medium sm:text-md text-sm">{button.number} Raten</p>
                      <p className="text-light font-medium sm:text-md text-sm">{`${calculateRate(amount, zinssatz, button.number).replace(".", ",")} €/Monat`}</p>
                    </div>
                  </button>
                ))}
              </div>

              <div className="grid gird-cols-1 gap-1">
                {[
                  { number: 6 },
                  { number: 12 }
                ].map((button, index) => (
                  <button
                    className={`w-full border rounded-md p-4 ${activeButton === button.number ? 'border-2 border-primary' : ''}`}
                    key={index}
                    onClick={() => {
                      setActiveButton(button.number);
                    }}
                  >
                    <div className="flex justify-between">
                      <p className="font-medium sm:text-md text-sm">{button.number} Raten</p>
                      <p className="text-light font-medium sm:text-md text-sm">{`${calculateRate(amount, zinssatz, button.number).replace(".", ",")} €/Monat`}</p>
                    </div>
                  </button>
                ))}
              </div>
            </div>

            <div className="bg-gray-200 p-5 flex flex-col gap-2">
              <div className="flex justify-between text-sm">
                <p>Betrag</p>
                <p>{netto ? parseFloat(netto).toFixed(2).replace(".", ",") : "0,00"} €</p>
              </div>
              <div className="flex justify-between text-sm">
                <p>Zinsen ({(zinssatz * 100).toFixed(2).replace(".", ",")} %)</p>
                <p>{profit ? profit.toFixed(2).replace(".", ",") : "0,00"} €</p>
              </div>
              <div className="flex justify-between text-sm font-bold">
                <p>Insgesamt zu zahlen</p>
                <p>{brutto ? parseFloat(brutto).toFixed(2).replace(".", ",") : "0,00"} €</p>
              </div>
            </div>

            <div>
              <p className="text-xs text-light mb-2">
                Repräsentatives Beispiel: Basierend auf deiner Zahlung von {netto ? parseFloat(netto).toFixed(2).replace(".", ",") : "0,00"} €.
                Der Gesamtbetrag des Kredits in Höhe von {netto ? parseFloat(netto).toFixed(2).replace(".", ",") : "0,00"} €
                wird über eine Gesamtlaufzeit von {activeButton} Monaten zu einem Zinssatz von {(zinssatz * 100).toFixed(2).replace(".", ",")} % p. a. (fest) verteilt.
                Das entspricht {activeButton} monatlichen Ratenzahlungen in Höhe von {rate ? rate.replace(".", ",") : 0} €.
                Insgesamt zu zahlen {brutto ? parseFloat(brutto).toFixed(2).replace(".", ",") : "0,00"} €.
                10 % eff. Jahreszins ist ein repräsentatives Beispiel. Die Gesamtkosten des Kredits betragen {profit ? profit.toFixed(2).replace(".", ",") : "0,00"} €
              </p>
              <p className="text-xs">Wenn ich fortfahre, akzeptiere ich die Nutzungsbedingungen von EasyCreditor und bestätige, dass ich die Datenschutzerklärung und die Cookie-Erklärung gelesen habe.</p>
            </div>

          </div>
        </div>
      )}

      {windowWidth > 850 && (
        <div>

          <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center ml-5 mb-10">
            <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
              <AiOutlineLeft
                onClick={() => navigate("/payment-methods")}
                className="text-[352F48] text-lg"
              />
            </div>
            <h1 className="text-lg font-medium m-auto">Ratenzahlung</h1>
            <div></div>
          </div>


          <div className="flex flex-col items-center gap-5">

            <div className="w-1/2">
              <label className="text-sm text-light">{"Username"}</label>
              <div className="relative">
                <Input
                  id="username"
                  type="text"
                  placeholder="Username des Vertragspartners"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    setGetUser(true);
                  }}
                />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="absolute top-[50%] transform translate-y-[-50%] right-4 w-5 text-primary">
                  <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                </svg>
              </div>
              {errors?.username && (
                <p className="text-red-600">{errors?.username}</p>
              )}
              {userMsg?.msg && (
                <p
                  className={`mt-2 ${userMsg?.status === "success"
                    ? "text-green-500"
                    : "text-red-600"
                    }`}
                >
                  {userMsg?.msg}
                </p>
              )}
            </div>

            <div className="w-1/2">
              <label className="text-sm text-light">{"Betrag"}</label>
              <div>
                <div className="relative">
                  <Input
                    id="amount"
                    type="number"
                    placeholder="0,00€"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    style={{ width: "100%" }}
                  />
                  <span className="absolute top-[50%] transform translate-y-[-50%] right-4 text-primary text-base">
                    €
                  </span>
                </div>
                {errors?.amount && (
                  <p className="mt-2 text-red-600">{errors?.amount}</p>
                )}
              </div>
            </div>

            <div className="w-1/2">
              <label className="text-sm text-light">{"Verwendungszweck"}</label>
              <Textarea
                id="description"
                placeholder="Bitte Verwendungszweck eingeben."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>


            <div className="grid grid-cols-2 gap-1 w-1/2">
              <div className="grid gird-cols-1 gap-1">
                {[
                  { number: 2 },
                  { number: 4 }
                ].map((button, index) => (
                  <button
                    className={`w-full border rounded-md p-4 ${activeButton === button.number ? 'border-2 border-primary' : ''}`}
                    key={index}
                    onClick={() => {
                      setActiveButton(button.number);
                    }}
                  >
                    <div className="flex justify-between">
                      <p className="font-medium sm:text-md text-sm">{button.number} Raten</p>
                      <p className="text-light font-medium sm:text-md text-sm">{`${calculateRate(amount, zinssatz, button.number).replace(".", ",")} €/Monat`}</p>
                    </div>
                  </button>
                ))}
              </div>

              <div className="grid gird-cols-1 gap-1">
                {[
                  { number: 6 },
                  { number: 12 }
                ].map((button, index) => (
                  <button
                    className={`w-full border rounded-md p-4 ${activeButton === button.number ? 'border-2 border-primary' : ''}`}
                    key={index}
                    onClick={() => {
                      setActiveButton(button.number);
                    }}
                  >
                    <div className="flex justify-between">
                      <p className="font-medium sm:text-md text-sm">{button.number} Raten</p>
                      <p className="text-light font-medium sm:text-md text-sm">{`${calculateRate(amount, zinssatz, button.number).replace(".", ",")} €/Monat`}</p>
                    </div>
                  </button>
                ))}
              </div>
            </div>

            <div className="bg-gray-200 p-5 flex flex-col gap-2 w-1/2">
              <div className="flex justify-between text-sm">
                <p>Betrag</p>
                <p>{netto ? parseFloat(netto).toFixed(2).replace(".", ",") : "0,00"} €</p>
              </div>
              <div className="flex justify-between text-sm">
                <p>Zinsen ({(zinssatz * 100).toFixed(2).replace(".", ",")} %)</p>
                <p>{profit ? profit.toFixed(2).replace(".", ",") : "0,00"} €</p>
              </div>
              <div className="flex justify-between text-sm font-bold">
                <p>Insgesamt zu zahlen</p>
                <p>{brutto ? parseFloat(brutto).toFixed(2).replace(".", ",") : "0,00"} €</p>
              </div>
            </div>

            <div className="w-1/2">
              <p className="text-xs text-light mb-2">
                Repräsentatives Beispiel: Basierend auf deiner Zahlung von {netto ? parseFloat(netto).toFixed(2).replace(".", ",") : "0,00"} €.
                Der Gesamtbetrag des Kredits in Höhe von {netto ? parseFloat(netto).toFixed(2).replace(".", ",") : "0,00"} €
                wird über eine Gesamtlaufzeit von {activeButton} Monaten zu einem Zinssatz von {(zinssatz * 100).toFixed(2).replace(".", ",")} % p. a. (fest) verteilt.
                Das entspricht {activeButton} monatlichen Ratenzahlungen in Höhe von {rate ? rate.replace(".", ",") : 0} €.
                Insgesamt zu zahlen {brutto ? parseFloat(brutto).toFixed(2).replace(".", ",") : "0,00"} €.
                10 % eff. Jahreszins ist ein repräsentatives Beispiel. Die Gesamtkosten des Kredits betragen {profit ? profit.toFixed(2).replace(".", ",") : "0,00"} €
              </p>
              <p className="text-xs">Wenn ich fortfahre, akzeptiere ich die Nutzungsbedingungen von EasyCreditor und bestätige, dass ich die Datenschutzerklärung und die Cookie-Erklärung gelesen habe.</p>
            </div>

            <button
              className="w-1/2 text-white text-base font-medium bg-primary rounded-full py-4 px-full mt-5"
              onClick={() => {
                submitHandler();
              }}>Weiter</button>

          </div>

        </div>
      )}

    </div >
  );
};

export default CreateInvoiceInstallment;