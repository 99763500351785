import { apiSlice } from "../api/apiSlice";

export const invoice30Api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query({
      query: () => `/api/invoices`,
      providesTags: ["getInvoices"],
    }),




    getInvoice30: builder.query({
      query: (id) => `/api/invoices30/single-invoice30/${id}`,
    }),

    createInvoice30: builder.mutation({
      query: (data) => ({
        url: `/api/invoices30`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getInvoices30"],
    }),




    updateInvoiceStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/api/invoices/update-status/${id}`,
        method: "PATCH",
        body: { status },
      }),
      invalidatesTags: ["getInvoices", "getUser"],
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoice30Query,
  useCreateInvoice30Mutation,
  useUpdateInvoiceStatusMutation,
} = invoice30Api;