import React from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { userLoggedOut } from "../features/auth/authSlice";

const Navigation = ({ activeNav, setActiveNav }) => {
  const dispatch = useDispatch();

  // logout
  const logout = () => {
    dispatch(userLoggedOut());
  };

  // account page

  return (
    <div
      className={`transition-all duration-300 fixed left-[50%] -translate-x-[50%] ${activeNav ? "bottom-0" : "-bottom-[120%]"
        }  right-0 h-full w-full flex justify-center bg-white`}
    >
      <div className="w-full h-full bg-[#021634] mt-12 rounded-tl-3xl rounded-tr-3xl px-6 py-7">
        <div
          className="bg-white w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer"
          onClick={() => setActiveNav(false)}
        >
          <AiOutlineLeft
            // onClick={() => navigate("/home")}
            className="text-[352F48] text-lg"
          />
        </div>
        <div className="mt-8 text-white">
          <h3 className="text-2xl font-semibold">Mehr</h3>
          <ul className="mt-6 flex flex-col gap-5 text-lg">

            <li className="transition-all hover:opacity-70 cursor-pointer">
              <Link to="/payment-methods">Geld senden</Link>
            </li>


            <li className="transition-all hover:opacity-70 cursor-pointer">
              <Link to="/transfer">Geld einzahlen oder abbuchen</Link>
            </li>

            <li className="transition-all hover:opacity-70 cursor-pointer">
              <Link to="/activities">Aktivitäten</Link>
            </li>

            <li className="transition-all hover:opacity-70 cursor-pointer">
              <Link to="/help">Hilfe</Link>
            </li>

            <li className="transition-all hover:opacity-70 cursor-pointer" onClick={logout}>Logout</li>

          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
