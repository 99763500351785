import React, { useState } from "react";
import CreateInvoiceInstallment from "../components/Invoice/CreateInvoiceInstallment";
import InvoiceDetailsInstallment from "../components/Invoice/InvoiceDetailsInstallment";

const tabs = {
  1: CreateInvoiceInstallment,
  2: InvoiceDetailsInstallment,
};

const CreateInstallment = () => {
  const [activeTab, setActiveTab] = useState(1);
  const Tab = tabs[activeTab];

  return (
    <div>
      <Tab
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};

export default CreateInstallment;
