import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../features/auth/authApi";
import Button from "../utilities/Button";
import Input from "../utilities/Input";


const RegisterInfo = ({ setActiveTab }) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [login, { data, isError, error }] = useLoginMutation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const checkMobileAndWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', checkMobileAndWidth);

    return () => {
      window.removeEventListener('resize', checkMobileAndWidth);
    };
  }, []);

  useEffect(() => {
    // Wenn ein Fehler auftritt, werden die Fehlermeldungen in den Zustand gesetzt
    if (isError) {
      setErrors(error?.data?.error);
    }

    // Wenn der Benutzer erfolgreich angemeldet wurde, wird eine Erfolgsmeldung angezeigt
    // und die Benutzeroberfläche zur Startseite navigiert
    if (data?.user?._id) {
      toast.success("Erfolgreich angemeldet.");
      setErrors({});

      navigate("/home");
    }
  }, [data, isError, error, navigate]);

  // Funktion zur Überprüfung der Eingaben beim Absenden des Formulars
  const submitHandler = () => {
    setErrors({});
    const validationErrors = {};

    if (!email || !username) {
      validationErrors.email = "Bitte Email oder Username eintragen.";
    }

    if (!password) {
      validationErrors.password = "Bitte Passwort eintragen.";
    }

    // Wenn Fehler bei der Validierung auftreten, werden sie in den Zustand gesetzt
    if (Object.keys(validationErrors)?.length > 0) {
      return setErrors(validationErrors);
    }

    // Bei keinen eingabefehlern wird die Login-Funktion aufgerufen
    const emailLow = email.toLowerCase();
    const usernameLow = username.toLowerCase();
    login({ email: emailLow, username: usernameLow, password });
  };

  return (
    <div className={windowWidth <= 1024 ? "w-full" : "w-1/3"}>
      <div className="flex flex-col gap-5">

        <div>
          <Input
            id="email_username"
            type="text"
            label="Email oder Username"
            placeholder="Email oder Username eingeben."
            value={email}
            onChange={(e) => setEmail(e.target.value) || setUsername(e.target.value)}
          />
          {errors?.email && (
            <p className="text-red-600">{errors?.email}</p>
          )}
        </div>

        <div>
          <Input
            id="passwort0"
            type="password"
            placeholder="Passwort eingeben."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors?.password && (
            <p className="text-red-600">{errors?.password}</p>
          )}
        </div>
      </div>

      <div className="mt-10">
        <div className="flex justify-center">
          <Button onClick={submitHandler}>Jetzt Anmelden</Button>
        </div>


        <p className="mt-5 text-base text-black font-medium text-center">
          Sie haben noch kein Konto?{" "}
          <Link className="text-primary" to="/register">
            Jetzt Konto erstellen.
          </Link>
        </p>
      </div>
    </div>
  );
};

export default RegisterInfo;
