import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import timeago from "time-ago";
import { useGetInvoicesQuery } from "../../features/invoice/invoiceApi";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Invoices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: invoices } = useGetInvoicesQuery() || [];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const checkMobileAndWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', checkMobileAndWidth);

    return () => {
      window.removeEventListener('resize', checkMobileAndWidth);
    };
  }, []);


  return (
    <div>
      {windowWidth <= 850 && location.pathname === '/activities' && (
        <div className="flex flex-col px-[22px]">
          <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center my-5">
            <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
              <AiOutlineLeft
                onClick={() => navigate("/home")}
                className="text-[352F48] text-lg"
              />
            </div>
            <h1 className="font-medium ml-auto">Letzte Aktivitäten</h1>
            <div></div>
          </div>
          <div className="flex flex-col gap-2">
            {invoices && Array.isArray(invoices) && (
              <>
                {invoices.map((invoice) => {
                  let username = invoice.receiver.username;

                  return (
                    <Link to={invoice.brutto ? `/installment-summary/${invoice?._id}` : (invoice.dueDate ? `/invoice-summary-30-days/${invoice?._id}` : `/invoice-summary/${invoice?._id}`)} key={invoice?._id}>
                      <div
                        className="px-[14px] py-[26px] transition-all hover:bg-gray-100 bg-white border shadow-sm rounded-lg flex justify-between items-center"
                      >
                        <div className="flex items-center gap-3 ">
                          <div className="w-[40px] h-[40px] rounded-full bg-[#DDEEFF] flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 text-primary">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                            </svg>
                          </div>
                          <div>
                            <h3 className="text-base font-medium text-dark">
                              {username}
                            </h3>
                            <p className="text-sm text-dark">{invoice?.description}</p>
                          </div>
                        </div>
                        <h3 className="font-medium text-dark">{invoice?.brutto ? invoice?.brutto : invoice?.amount} €</h3>
                        <div className="flex gap-1 flex-col">
                          <span className="flex text-gray-400 text-xs font-normal items-center">

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3.5 h-3.5 text-gray-400 mr-0.5">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>

                            {timeago.ago(Number(invoice?.createdDate))}
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </>
            )}
          </div>
        </div>
      )}

      {windowWidth > 850 && location.pathname === '/activities' && (
        <div className="flex flex-col">
          <h1 className="font-medium text-lg text-center mb-5">Aktivitäten</h1>
          <div className="flex flex-col gap-2 items-center">
            {invoices && Array.isArray(invoices) && (
              <>
                {invoices.map((invoice) => {
                  let username = invoice.receiver.username;

                  return (
                    <div className="w-1/2">
                      <Link to={invoice.brutto ? `/installment-summary/${invoice?._id}` : (invoice.dueDate ? `/invoice-summary-30-days/${invoice?._id}` : `/invoice-summary/${invoice?._id}`)} key={invoice?._id}>
                        <div
                          className="px-[14px] py-[26px] transition-all hover:bg-gray-100 bg-white border shadow-sm rounded-lg flex justify-between items-center"
                        >
                          <div className="flex items-center gap-3 ">
                            <div className="w-[40px] h-[40px] rounded-full bg-[#DDEEFF] flex items-center justify-center">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 text-primary">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                              </svg>
                            </div>
                            <div>
                              <h3 className="text-base font-medium text-dark">
                                {username}
                              </h3>
                              <p className="text-sm text-dark">{invoice?.description}</p>
                            </div>
                          </div>
                          <h3 className="font-medium text-dark">{invoice?.brutto ? invoice?.brutto : invoice?.amount} €</h3>
                          <div className="flex gap-1 flex-col">
                            <span className="flex text-gray-400 text-xs font-normal items-center">

                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3.5 h-3.5 text-gray-400 mr-0.5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>

                              {timeago.ago(Number(invoice?.createdDate))}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      )}

      {
        location.pathname === '/home' && (
          <div className="flex flex-col">
            <div className="flex flex-col gap-2">
              {invoices && Array.isArray(invoices) && (
                JSON.parse(JSON.stringify(invoices))
                  .sort((a, b) => Number(b?.createdDate) - Number(a?.createdDate))
                  .slice(0, 3)
                  .map((invoice) => {
                    let username = invoice.receiver.username;

                    return (
                      <Link to={invoice.brutto ? `/installment-summary/${invoice?._id}` : (invoice.dueDate ? `/invoice-summary-30-days/${invoice?._id}` : `/invoice-summary/${invoice?._id}`)} key={invoice?._id}>
                        <div
                          className="px-[14px] py-[26px] transition-all hover:bg-gray-100 bg-white border shadow-sm rounded-lg flex justify-between items-center"
                        >
                          <div className="flex items-center gap-3 ">
                            <div className="w-[40px] h-[40px] rounded-full bg-[#DDEEFF] flex items-center justify-center">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 text-primary">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                              </svg>
                            </div>
                            <div>
                              <h3 className="text-base font-medium text-dark">
                                {username}
                              </h3>
                              <p className="text-sm text-dark">{invoice?.description}</p>
                            </div>
                          </div>
                          <h3 className="font-medium text-dark">{invoice?.brutto ? invoice?.brutto : invoice?.amount} €</h3>
                          <div className="flex gap-1 flex-col">
                            <span className="flex text-gray-400 text-xs font-normal items-center">

                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3.5 h-3.5 text-gray-400 mr-0.5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>

                              {timeago.ago(Number(invoice?.createdDate))}
                            </span>
                          </div>
                        </div>
                      </Link>
                    );
                  })
              )}
            </div>
          </div>
        )
      }
    </div>

  );
};

export default Invoices;
