import React from "react";
import { useGetUserQuery } from "../features/user/userApi";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi2";


const Account = () => {

    const navigate = useNavigate();
    const user = useGetUserQuery();
    console.log("User", user.data)

    return (
        <div className="flex flex-col gap-10">
            <div className="flex items-center justify-between px-[22px] py-6 bg-white shadow-sm pb-10">
                <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
                    <AiOutlineLeft
                        onClick={() => navigate("/home")}
                        className="text-[352F48] text-lg"
                    />
                </div>
                <div className="mr-24">
                    <h3 className="font-bold text-lg text-dark">Kontoinformationen</h3>
                </div>
            </div>

            <div className="flex justify-center relative">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-14 text-primary">
                    <path fill-rule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd" />
                </svg>

                <h1 className="text-dark font-bold text-xl mt-3 ml-3">{user.data?.username}</h1>
            </div>

            <div className="flex flex-col gap-5 mx-5">

                <div>
                    <label>Email:</label>
                    <div className="relative">
                        <div className={`w-full block border rounded-lg px-5 py-3 outline-none focus:ring-1 ring-dark text-dark text-base font-normal`}>
                            <h1>{user.data?.email}</h1>
                            <HiArrowRight className="absolute top-[50%] transform translate-y-[-50%] right-4 text-primary text-lg font-bold cursor-pointer" />
                        </div>
                    </div>
                </div>

                <div>
                    <label>Telefonnummer:</label>
                    <div className="relative">
                        <div className={`w-full block border rounded-lg px-5 py-3 outline-none focus:ring-1 ring-dark text-dark text-base font-normal`}>
                            <h1>{user.data?.phone}</h1>
                            <HiArrowRight className="absolute top-[50%] transform translate-y-[-50%] right-4 text-primary text-lg font-bold cursor-pointer" />
                        </div>
                    </div>
                </div>

                {user.data?.iban !== "empty" && (
                    <div>
                        <label>Bankkonto:</label>
                        <div className="relative">
                            <div className={`w-full block border rounded-lg px-5 py-3 outline-none focus:ring-1 ring-dark text-dark text-base font-normal`}>
                                <h1>{user.data?.iban}</h1>
                                <HiArrowRight className="absolute top-[50%] transform translate-y-[-50%] right-4 text-primary text-lg font-bold cursor-pointer" />
                            </div>
                        </div>
                    </div>
                )}


                <div>
                    <label>Anschrift:</label>
                    <div className="relative">
                        <div className={`w-full block border rounded-lg px-5 py-3 outline-none focus:ring-1 ring-dark text-dark placeholder:text-light text-base font-normal min-h-[120px]`}>
                            <h1>{user.data?.firstName}</h1>
                            <h1>{user.data?.lastName}</h1>
                            <h1>{user.data?.street + " " + user.data?.houseNumber}</h1>
                            <h1>{user.data?.zipCode + " " + user.data?.residence}</h1>
                            <HiArrowRight className="absolute top-[50%] transform translate-y-[-50%] right-4 text-primary text-lg font-bold cursor-pointer" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Account;
