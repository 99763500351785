import React, { useState, useEffect } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useGetUserQuery } from "../features/user/userApi";

const DataProtection = () => {
    const navigate = useNavigate();
    const user = useGetUserQuery();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const checkMobileAndWidth = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', checkMobileAndWidth);

        return () => {
            window.removeEventListener('resize', checkMobileAndWidth);
        };
    }, []);

    return (
        <div>
            {windowWidth <= 850 && (
                <div>
                    <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center my-5">
                        <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer ml-5">
                            <AiOutlineLeft
                                onClick={() => {
                                    if (user.status === 'rejected') {
                                        navigate("/")
                                    } else {
                                        navigate("/home")
                                    }
                                }}
                                className="text-[352F48] text-lg"
                            />
                        </div>
                        <h1 className="font-medium m-auto">Datenschutzerklärung</h1>
                        <div></div>
                    </div>

                    <div className="flex justify-center text-sm">
                        <div className="w-3/4">
                            <h2 id="m716">Präambel</h2>
                            <p>
                                Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären,
                                welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als
                                "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang
                                verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten
                                Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung
                                unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen
                                Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer
                                Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als
                                "Onlineangebot“).
                            </p>
                            <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
                            <p>Stand: 15. August 2023</p>
                            <h2>Inhaltsübersicht</h2>{" "}
                            <ul className="index">
                                <li>
                                    <a className="index-link" href="#m716">
                                        Präambel
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m3">
                                        Verantwortlicher
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#mOverview">
                                        Übersicht der Verarbeitungen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m2427">
                                        Maßgebliche Rechtsgrundlagen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m27">
                                        Sicherheitsmaßnahmen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m25">
                                        Übermittlung von personenbezogenen Daten
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m24">
                                        Internationale Datentransfers
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m12">
                                        Löschung von Daten
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m10">
                                        Rechte der betroffenen Personen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m134">
                                        Einsatz von Cookies
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m317">
                                        Geschäftliche Leistungen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m3575">
                                        Im Rahmen der Geschäftstätigkeit eingesetzte Anbieter und Services
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m326">
                                        Zahlungsverfahren
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m325">
                                        Bonitätsprüfung
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m225">
                                        Bereitstellung des Onlineangebotes und Webhosting
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m367">
                                        Registrierung, Anmeldung und Nutzerkonto
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m182">
                                        Kontakt- und Anfragenverwaltung
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m263">
                                        Webanalyse, Monitoring und Optimierung
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m264">
                                        Onlinemarketing
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m136">
                                        Präsenzen in sozialen Netzwerken (Social Media)
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m42">
                                        Begriffsdefinitionen
                                    </a>
                                </li>
                            </ul>
                            <h2 id="m3">Verantwortlicher</h2>
                            <p>
                                Louis, Karakas
                                <br />
                                Scheffelstraße, 2<br />
                                73252, Lenningen, Deutschland
                            </p>
                            Vertretungsberechtigte Personen: <p>Louis Karakas</p>
                            E-Mail-Adresse:{" "}
                            <p>
                                <a href="mailto:louiskarakas.bw@gmail.com">louiskarakas.bw@gmail.com</a>
                            </p>
                            Impressum: <p>www.easycreditor.com/impressum</p>
                            <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
                            <p>
                                <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im Folgenden
                                erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis
                                wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass
                                neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw.
                                unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall
                                speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der
                                Datenschutzerklärung mit.
                            </p>
                            <ul>
                                <li>
                                    <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die
                                    betroffene Person hat ihre Einwilligung in die Verarbeitung der sie
                                    betreffenden personenbezogenen Daten für einen spezifischen Zweck oder
                                    mehrere bestimmte Zwecke gegeben.
                                </li>
                                <li>
                                    <strong>
                                        Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
                                        b) DSGVO)
                                    </strong>{" "}
                                    - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
                                    Vertragspartei die betroffene Person ist, oder zur Durchführung
                                    vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen
                                    Person erfolgen.
                                </li>
                                <li>
                                    <strong>
                                        Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)
                                    </strong>{" "}
                                    - Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
                                    erforderlich, der der Verantwortliche unterliegt.
                                </li>
                                <li>
                                    <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong>{" "}
                                    - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
                                    Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
                                    Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person,
                                    die den Schutz personenbezogener Daten erfordern, überwiegen.
                                </li>
                            </ul>
                            <p>
                                <strong>Nationale Datenschutzregelungen in Deutschland: </strong>Zusätzlich
                                zu den Datenschutzregelungen der DSGVO gelten nationale Regelungen zum
                                Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz
                                vor Missbrauch personenbezogener Daten bei der Datenverarbeitung
                                (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
                                Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum
                                Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener
                                Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie
                                automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling.
                                Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur
                                Anwendung gelangen.
                            </p>
                            <p>
                                <strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese
                                Datenschutzhinweise dienen sowohl der Informationserteilung nach dem
                                schweizerischen Bundesgesetz über den Datenschutz (Schweizer DSG) als auch
                                nach der Datenschutzgrundverordnung (DSGVO). Aus diesem Grund bitten wir Sie
                                zu beachten, dass aufgrund der breiteren räumlichen Anwendung und
                                Verständlichkeit die Begriffe der DSGVO verwendet werden. Insbesondere statt
                                der im Schweizer DSG verwendeten Begriffe „Bearbeitung“ von „Personendaten“,
                                "überwiegendes Interesse" und "besonders schützenswerte Personendaten"
                                werden die in der DSGVO verwendeten Begriffe „Verarbeitung“ von
                                „personenbezogenen Daten“ sowie "berechtigtes Interesse" und "besondere
                                Kategorien von Daten" verwendet. Die gesetzliche Bedeutung der Begriffe wird
                                jedoch im Rahmen der Geltung des Schweizer DSG weiterhin nach dem Schweizer
                                DSG bestimmt.
                            </p>
                            <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
                            <p>
                                Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die
                                Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
                                Personen.
                            </p>
                            <h3>Arten der verarbeiteten Daten</h3>
                            <ul>
                                <li>Bestandsdaten.</li>
                                <li>Zahlungsdaten.</li>
                                <li>Kontaktdaten.</li>
                                <li>Inhaltsdaten.</li>
                                <li>Vertragsdaten.</li>
                                <li>Nutzungsdaten.</li>
                                <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
                            </ul>
                            <h3>Kategorien betroffener Personen</h3>
                            <ul>
                                <li>Kunden.</li>
                                <li>Interessenten.</li>
                                <li>Kommunikationspartner.</li>
                                <li>Nutzer.</li>
                                <li>Geschäfts- und Vertragspartner.</li>
                            </ul>
                            <h3>Zwecke der Verarbeitung</h3>
                            <ul>
                                <li>
                                    Erbringung vertraglicher Leistungen und erfüllung vertraglicher Pflichten.
                                </li>
                                <li>Kontaktanfragen und Kommunikation.</li>
                                <li>Sicherheitsmaßnahmen.</li>
                                <li>Reichweitenmessung.</li>
                                <li>Tracking.</li>
                                <li>Büro- und Organisationsverfahren.</li>
                                <li>Verwaltung und Beantwortung von Anfragen.</li>
                                <li>Feedback.</li>
                                <li>Marketing.</li>
                                <li>Profile mit nutzerbezogenen Informationen.</li>
                                <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                                <li>Beurteilung der Bonität und Kreditwürdigkeit.</li>
                                <li>Informationstechnische Infrastruktur.</li>
                            </ul>
                            <h3>Automatisierte Entscheidungen im Einzelfall</h3>
                            <ul>
                                <li>Bonitätsauskunft.</li>
                            </ul>
                            <h2 id="m27">Sicherheitsmaßnahmen</h2>
                            <p>
                                Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung
                                des Stands der Technik, der Implementierungskosten und der Art, des Umfangs,
                                der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen
                                Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und
                                Freiheiten natürlicher Personen geeignete technische und organisatorische
                                Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
                            </p>
                            <p>
                                Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
                                Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und
                                elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs,
                                der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer
                                Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
                                Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen
                                auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
                                Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von
                                Hardware, Software sowie Verfahren entsprechend dem Prinzip des
                                Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
                                Voreinstellungen.
                            </p>
                            <p>
                                TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot
                                übermittelten Daten zu schützen, nutzen wir eine TLS-Verschlüsselung. Sie
                                erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der
                                Adresszeile Ihres Browsers.
                            </p>
                            <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
                            <p>
                                Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor,
                                dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige
                                Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber
                                offengelegt werden. Zu den Empfängern dieser Daten können z.B. mit
                                IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und
                                Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen
                                Fällen beachten wir die gesetzlichen Vorgaben und schließen insbesondere
                                entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten
                                dienen, mit den Empfängern Ihrer Daten ab.
                            </p>
                            <h2 id="m24">Internationale Datentransfers</h2>
                            <p>
                                Datenverarbeitung in Drittländern: Sofern wir Daten in einem Drittland
                                (d.h., außerhalb der Europäischen Union (EU), des Europäischen
                                Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der
                                Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung
                                von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt
                                dies nur im Einklang mit den gesetzlichen Vorgaben.
                            </p>
                            <p>
                                Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich
                                erforderlicher Übermittlung (s. Art. 49 DSGVO) verarbeiten oder lassen wir
                                die Daten nur in Drittländern mit einem anerkannten Datenschutzniveau (Art.
                                45 DSGVO), beim Vorliegen und Einhaltung vertraglichen Verpflichtung durch
                                sogenannte Standardschutzklauseln der EU-Kommission (Art. 46 DSGVO) oder
                                beim Vorliegen von Zertifizierungen oder verbindlicher internen
                                Datenschutzvorschriften (s. Art. 44 bis 49 DSGVO, Informationsseite der
                                EU-Kommission:{" "}
                                <a
                                    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
                                    target="_blank"
                                >
                                    https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
                                </a>
                                ).
                            </p>
                            <p>
                                EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten „Data
                                Privacy Framework” (DPF) hat die EU-Kommission das Datenschutzniveau
                                ebenfalls für bestimmte Unternehmen aus den USA im Rahmen der
                                Angemessenheitsbeschlusses vom 10.07.2023 als sicher anerkannt. Die Liste
                                der zertifizierten Unternehmen als auch weitere Informationen zu dem DPF
                                können Sie der Webseite des Handelsministeriums der USA unter{" "}
                                <a href="https://www.dataprivacyframework.gov/" target="_blank">
                                    https://www.dataprivacyframework.gov/
                                </a>{" "}
                                (in Englisch) entnehmen. Wir informieren Sie im Rahmen der
                                Datenschutzhinweise welche von uns eingesetzten Diensteanbieter unter dem
                                Data Privacy Framework zertifiziert sind.
                            </p>
                            <h2 id="m12">Löschung von Daten</h2>
                            <p>
                                Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
                                Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen
                                widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck
                                der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht
                                erforderlich sind). Sofern die Daten nicht gelöscht werden, weil sie für
                                andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren
                                Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt
                                und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
                                handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
                                Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
                                Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                                juristischen Person erforderlich ist.{" "}
                            </p>
                            <p>
                                Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung
                                und Löschung von Daten beinhalten, die für die jeweiligen Verarbeitungen
                                vorrangig gelten.
                            </p>
                            <h2 id="m10">Rechte der betroffenen Personen</h2>
                            <p>
                                Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als Betroffene
                                nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis
                                21 DSGVO ergeben:
                            </p>
                            <ul>
                                <li>
                                    <strong>
                                        Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer
                                        besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie
                                        betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
                                        lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für
                                        ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie
                                        betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu
                                        betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
                                        Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
                                        derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit
                                        es mit solcher Direktwerbung in Verbindung steht.
                                    </strong>
                                </li>
                                <li>
                                    <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht,
                                    erteilte Einwilligungen jederzeit zu widerrufen.
                                </li>
                                <li>
                                    <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung
                                    darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
                                    Auskunft über diese Daten sowie auf weitere Informationen und Kopie der
                                    Daten entsprechend den gesetzlichen Vorgaben.
                                </li>
                                <li>
                                    <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
                                    gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
                                    betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen
                                    Daten zu verlangen.
                                </li>
                                <li>
                                    <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong>{" "}
                                    Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen,
                                    dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ
                                    nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung
                                    der Daten zu verlangen.
                                </li>
                                <li>
                                    <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie
                                    betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der
                                    gesetzlichen Vorgaben in einem strukturierten, gängigen und
                                    maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen
                                    anderen Verantwortlichen zu fordern.
                                </li>
                                <li>
                                    <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet
                                    eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
                                    Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde,
                                    insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts,
                                    ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie
                                    der Ansicht sind, dass die Verarbeitung der Sie betreffenden
                                    personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.
                                </li>
                            </ul>
                            <h2 id="m134">Einsatz von Cookies</h2>
                            <p>
                                Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die
                                Informationen auf Endgeräten speichern und Informationen aus den Endgeräten
                                auslesen. Z.B. um den Login-Status in einem Nutzerkonto, einen
                                Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder verwendete
                                Funktionen eines Onlineangebotes speichern. Cookies können ferner zu
                                unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der
                                Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten sowie der
                                Erstellung von Analysen der Besucherströme.{" "}
                            </p>
                            <p>
                                <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang
                                mit den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine
                                vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht gefordert
                                ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn das Speichern
                                und das Auslesen der Informationen, also auch von Cookies, unbedingt
                                erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich
                                gewünschten Telemediendienst (also unser Onlineangebot) zur Verfügung zu
                                stellen. Zu den unbedingt erforderlichen Cookies gehören in der Regel
                                Cookies mit Funktionen, die der Anzeige und Lauffähigkeit des
                                Onlineangebotes , dem Lastausgleich, der Sicherheit, der Speicherung der
                                Präferenzen und Auswahlmöglichkeiten der Nutzer oder ähnlichen mit der
                                Bereitstellung der Haupt- und Nebenfunktionen des von den Nutzern
                                angeforderten Onlineangebotes zusammenhängenden Zwecken dienen. Die
                                widerrufliche Einwilligung wird gegenüber den Nutzern deutlich kommuniziert
                                und enthält die Informationen zu der jeweiligen Cookie-Nutzung.
                            </p>
                            <p>
                                <strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf
                                welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen
                                Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir
                                Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist die
                                Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung.
                                Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf
                                Grundlage unserer berechtigten Interessen (z.B. an einem
                                betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung
                                seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung
                                unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies
                                erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen. Zu
                                welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir
                                im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
                                Einwilligungs- und Verarbeitungsprozessen auf.
                            </p>
                            <p>
                                <strong>Speicherdauer:&nbsp;</strong>Im Hinblick auf die Speicherdauer
                                werden die folgenden Arten von Cookies unterschieden:
                            </p>
                            <ul>
                                <li>
                                    <strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong>
                                    &nbsp;Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
                                    Online-Angebot verlassen und sein Endgerät (z.B. Browser oder mobile
                                    Applikation) geschlossen hat.
                                </li>
                                <li>
                                    <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach
                                    dem Schließen des Endgerätes gespeichert. So können beispielsweise der
                                    Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden,
                                    wenn der Nutzer eine Website erneut besucht. Ebenso können die mit Hilfe
                                    von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung verwendet
                                    werden. Sofern wir Nutzern&nbsp;keine expliziten Angaben zur Art und
                                    Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der
                                    Einwilligung), sollten Nutzer davon ausgehen, dass Cookies permanent sind
                                    und die Speicherdauer bis zu zwei Jahre betragen kann.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Allgemeine Hinweise zum Widerruf und Widerspruch (sog. "Opt-Out"):{" "}
                                </strong>
                                Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit widerrufen
                                und der Verarbeitung entsprechend den gesetzlichen Vorgaben widersprechen.
                                Hierzu können Nutzer unter anderem die Verwendung von Cookies in den
                                Einstellungen ihres Browsers einschränken (wobei dadurch auch die
                                Funktionalität unseres Onlineangebotes eingeschränkt sein kann). Ein
                                Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken
                                kann auch über die Websites{" "}
                                <a href="https://optout.aboutads.info/" target="_new">
                                    https://optout.aboutads.info
                                </a>{" "}
                                und{" "}
                                <a href="https://www.youronlinechoices.com/" target="_new">
                                    https://www.youronlinechoices.com/
                                </a>{" "}
                                erklärt werden.
                            </p>
                            <ul className="m-elements">
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>
                                        Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:{" "}
                                    </strong>
                                    Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in
                                    dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies,
                                    bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens
                                    genannten Verarbeitungen und Anbieter eingeholt sowie von den Nutzern
                                    verwaltet und widerrufen werden können. Hierbei wird die
                                    Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut
                                    wiederholen zu müssen und die Einwilligung entsprechend der gesetzlichen
                                    Verpflichtung nachweisen zu können. Die Speicherung kann serverseitig
                                    und/oder in einem Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe
                                    vergleichbarer Technologien) erfolgen, um die Einwilligung einem Nutzer,
                                    bzw. dessen Gerät zuordnen zu können. Vorbehaltlich individueller Angaben
                                    zu den Anbietern von Cookie-Management-Diensten, gelten die folgenden
                                    Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu zwei
                                    Jahren betragen. Hierbei wird ein pseudonymer Nutzer-Identifikator
                                    gebildet und mit dem Zeitpunkt der Einwilligung, Angaben zur Reichweite
                                    der Einwilligung (z. B. welche Kategorien von Cookies und/oder
                                    Diensteanbieter) sowie dem Browser, System und verwendeten Endgerät
                                    gespeichert;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
                                        a) DSGVO).
                                    </span>
                                </li>
                            </ul>
                            <h2 id="m317">Geschäftliche Leistungen</h2>
                            <p>
                                Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden
                                und Interessenten (zusammenfassend bezeichnet als "Vertragspartner") im
                                Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit
                                verbundenen Maßnahmen und im Rahmen der Kommunikation mit den
                                Vertragspartnern (oder vorvertraglich), z.B., um Anfragen zu beantworten.
                            </p>
                            <p>
                                Wir verarbeiten diese Daten, um unsere vertraglichen Verpflichtungen zu
                                erfüllen. Dazu gehören insbesondere die Verpflichtungen zur Erbringung der
                                vereinbarten Leistungen, etwaige Aktualisierungspflichten und Abhilfe bei
                                Gewährleistungs- und sonstigen Leistungsstörungen. Darüber hinaus
                                verarbeiten wir die Daten zur Wahrung unserer Rechte und zum Zwecke der mit
                                diesen Pflichten verbundenen Verwaltungsaufgaben sowie der
                                Unternehmensorganisation. Darüber hinaus verarbeiten wir die Daten auf
                                Grundlage unserer berechtigten Interessen an einer ordnungsgemäßen und
                                betriebswirtschaftlichen Geschäftsführung sowie an Sicherheitsmaßnahmen zum
                                Schutz unserer Vertragspartner und unseres Geschäftsbetriebes vor
                                Missbrauch, Gefährdung ihrer Daten, Geheimnisse, Informationen und Rechte
                                (z.B. zur Beteiligung von Telekommunikations-, Transport- und sonstigen
                                Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und Rechtsberatern,
                                Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des geltenden Rechts
                                geben wir die Daten von Vertragspartnern nur insoweit an Dritte weiter, als
                                dies für die vorgenannten Zwecke oder zur Erfüllung gesetzlicher Pflichten
                                erforderlich ist. Über weitere Formen der Verarbeitung, z.B. zu
                                Marketingzwecken, werden die Vertragspartner im Rahmen dieser
                                Datenschutzerklärung informiert.
                            </p>
                            <p>
                                Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den
                                Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in
                                Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw. Symbole
                                (z.B. Sternchen o.ä.), oder persönlich mit.
                            </p>
                            <p>
                                Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
                                vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4 Jahren, es
                                sei denn, dass die Daten in einem Kundenkonto gespeichert werden, z.B.,
                                solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden
                                müssen. Die gesetzliche Aufbewahrungsfrist beträgt bei steuerrechtlich
                                relevanten Unterlagen sowie bei Handelsbüchern, Inventaren,
                                Eröffnungsbilanzen, Jahresabschlüssen, die zum Verständnis dieser Unterlagen
                                erforderlichen Arbeitsanweisungen und sonstigen Organisationsunterlagen und
                                Buchungsbelegen zehn Jahre sowie bei empfangenen Handels- und
                                Geschäftsbriefen und Wiedergaben der abgesandten Handels- und
                                Geschäftsbriefe sechs Jahre. Die Frist beginnt mit Ablauf des
                                Kalenderjahres, in dem die letzte Eintragung in das Buch gemacht, das
                                Inventar, die Eröffnungsbilanz, der Jahresabschluss oder der Lagebericht
                                aufgestellt, der Handels- oder Geschäftsbrief empfangen oder abgesandt
                                worden oder der Buchungsbeleg entstanden ist, ferner die Aufzeichnung
                                vorgenommen worden ist oder die sonstigen Unterlagen entstanden sind.
                            </p>
                            <p>
                                Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen
                                einsetzen, gelten im Verhältnis zwischen den Nutzern und den Anbietern die
                                Geschäftsbedingungen und Datenschutzhinweise der jeweiligen Drittanbieter
                                oder Plattformen.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                                    Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
                                    Zahlungshistorie); Kontaktdaten (z.B. E-Mail, Telefonnummern);
                                    Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie);
                                    Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                                    Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z. B.
                                    IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Kunden; Interessenten. Geschäfts-
                                    und Vertragspartner.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
                                    Leistungen und erfüllung vertraglicher Pflichten; Sicherheitsmaßnahmen;
                                    Kontaktanfragen und Kommunikation; Büro- und Organisationsverfahren.
                                    Verwaltung und Beantwortung von Anfragen.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                    Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); Rechtliche Verpflichtung
                                    (Art. 6 Abs. 1 S. 1 lit. c) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Kundenkonto: </strong>Kunden können innerhalb unseres
                                    Onlineangebotes ein Konto anlegen (z.B. Kunden- bzw. Nutzerkonto, kurz
                                    "Kundenkonto"). Falls die Registrierung eines Kundenkontos erforderlich
                                    ist, werden Kunden hierauf ebenso hingewiesen wie auf die für die
                                    Registrierung erforderlichen Angaben. Die Kundenkonten sind nicht
                                    öffentlich und können von Suchmaschinen nicht indexiert werden. Im Rahmen
                                    der Registrierung sowie anschließender Anmeldungen und Nutzungen des
                                    Kundenkontos speichern wir die IP-Adressen der Kunden nebst den
                                    Zugriffszeitpunkten, um die Registrierung nachweisen und etwaigem
                                    Missbrauch des Kundenkontos vorbeugen zu können. Wurde das Kundenkonto
                                    gekündigt, werden die Daten des Kundenkontos nach dem Kündigungszeitpunkt
                                    gelöscht, sofern sie nicht für andere Zwecke als die Bereitstellung im
                                    Kundenkonto aufbewahrt werden oder aus rechtlichen Gründen aufbewahrt
                                    werden müssen (z.B. interne Speicherung von Kundendaten, Bestellvorgängen
                                    oder Rechnungen). Es liegt in der Verantwortung der Kunden, ihre Daten bei
                                    Kündigung des Kundenkontos zu sichern;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                                    </span>
                                </li>
                                <li>
                                    <strong>Angebot von Software- und Plattformleistungen: </strong>Wir
                                    verarbeiten die Daten unserer Nutzer, angemeldeter und etwaiger Testnutzer
                                    (nachfolgend einheitlich als "Nutzer" bezeichnet), um ihnen gegenüber
                                    unsere vertraglichen Leistungen erbringen zu können sowie auf Grundlage
                                    berechtigter Interessen, um die Sicherheit unseres Angebotes gewährleisten
                                    und es weiterentwickeln zu können. Die erforderlichen Angaben sind als
                                    solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren
                                    Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung
                                    und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige
                                    Rücksprachen halten zu können;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                                    </span>
                                </li>
                            </ul>
                            <h2 id="m3575">
                                Im Rahmen der Geschäftstätigkeit eingesetzte Anbieter und Services
                            </h2>
                            <p>
                                Im Rahmen unserer Geschäftstätigkeit nutzen wir unter Beachtung der
                                gesetzlichen Vorgaben zusätzliche Dienste, Plattformen, Schnittstellen oder
                                Plug-ins von Drittanbietern (kurz "Dienste"). Deren Nutzung beruht auf
                                unseren Interessen an einer ordnungsgemäßen, rechtmäßigen und
                                <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                    {" "}
                                    - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. -
                                    premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
                                    premiumtext premiumtext premiumtext premiumtext{" "}
                                </span>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                                    Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
                                    Zahlungshistorie
                                    <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                        {" "}
                                        - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
                                        - premiumtext premiumtext{" "}
                                    </span>
                                    ); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B. Eingaben
                                    in Onlineformularen). Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
                                    Kundenkategorie
                                    <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                        {" "}
                                        - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
                                        - premiumtext premiumtext{" "}
                                    </span>
                                    ).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Kunden; Interessenten; Nutzer (z.B.
                                    Webseitenbesucher, Nutzer von
                                    <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                        {" "}
                                        - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
                                        - premiumtext premiumtext premiumtext{" "}
                                    </span>
                                    ). Geschäfts- und Vertragspartner.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
                                    Leistungen und erfüllung vertraglicher Pflichten. Büro- und
                                    Organisationsverfahren.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <h2 id="m326">Zahlungsverfahren</h2>
                            <p>
                                Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund
                                gesetzlicher Pflichten oder sonst auf Grundlage unserer berechtigten
                                Interessen bieten wir den betroffenen Personen effiziente und sichere
                                Zahlungsmöglichkeiten an und setzen hierzu neben Banken und Kreditinstituten
                                weitere Dienstleister ein (zusammenfassend "Zahlungsdienstleister").
                            </p>
                            <p>
                                Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören
                                Bestandsdaten, wie z.B. der Name und die Adresse, Bankdaten, wie z.B.
                                Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie
                                die Vertrags-, Summen- und empfängerbezogenen Angaben. Die Angaben sind
                                erforderlich, um die Transaktionen durchzuführen. Die eingegebenen Daten
                                werden jedoch nur durch die Zahlungsdienstleister verarbeitet und bei diesen
                                gespeichert. D.h., wir erhalten keine konto- oder kreditkartenbezogenen
                                Informationen, sondern lediglich Informationen mit Bestätigung oder
                                Negativbeauskunftung der Zahlung. Unter Umständen werden die Daten seitens
                                der Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese
                                Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. Hierzu verweisen
                                wir auf die AGB und die Datenschutzhinweise der Zahlungsdienstleister.
                            </p>
                            <p>
                                Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die
                                Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche innerhalb
                                der jeweiligen Webseiten bzw. Transaktionsapplikationen abrufbar sind. Wir
                                verweisen auf diese ebenfalls zwecks weiterer Informationen und
                                Geltendmachung von Widerrufs-, Auskunfts- und anderen Betroffenenrechten.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                                    Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
                                    Zahlungshistorie); Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
                                    Kundenkategorie); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                                    Inhalten, Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z.
                                    B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Kunden. Interessenten.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
                                    Leistungen und erfüllung vertraglicher Pflichten.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                    Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Giropay: </strong>Zahlungsdienstleistungen (technische Anbindung
                                    von Online-Bezahlmethoden); <strong>Dienstanbieter:</strong> giropay GmbH,
                                    An der Welle 4, 60322 Frankfurt, Deutschland;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO);{" "}
                                    </span>
                                    <strong>Website:</strong>{" "}
                                    <a href="https://www.giropay.de" target="_blank">
                                        https://www.giropay.de
                                    </a>
                                    . <strong>Datenschutzerklärung:</strong>{" "}
                                    <a
                                        href="https://www.giropay.de/rechtliches/datenschutzerklaerung/"
                                        target="_blank"
                                    >
                                        https://www.giropay.de/rechtliches/datenschutzerklaerung/
                                    </a>
                                    .
                                </li>
                            </ul>
                            <h2 id="m325">Bonitätsprüfung</h2>
                            <p>
                                Sofern wir in Vorleistung treten oder vergleichbare wirtschaftliche Risiken
                                eingehen (z.B. beim Bestellung auf Rechnung), behalten wir uns vor, zur
                                Wahrung der berechtigten Interessen eine Identitäts- und Bonitätsauskunft
                                zwecks Beurteilung des Kreditrisikos auf Basis von
                                mathematisch-statistischen Verfahren von hierauf spezialisierten
                                Dienstleistungsunternehmen (Wirtschaftsauskunfteien) einzuholen.
                            </p>
                            <p>
                                Die von den Wirtschaftsauskunfteien erhaltenen Informationen über die
                                statistische Wahrscheinlichkeit eines Zahlungsausfalls verarbeiten wir im
                                Rahmen einer sachgerechten Ermessensentscheidung über die Begründung,
                                Durchführung und Beendigung des Vertragsverhältnisses. Wir behalten uns vor,
                                im Fall eines negativen Ergebnisses der Bonitätsprüfung, die Zahlung auf
                                Rechnung oder eine andere Vorleistung zu verweigern.
                            </p>
                            <p>
                                Die Entscheidung, ob wir in Vorleistung treten, erfolgt entsprechend den
                                gesetzlichen Vorgaben alleine auf Grundlage einer automatisierten
                                Entscheidung im Einzelfall, die unsere Software unter Zugrundelegung der
                                Auskunft der Wirtschaftsauskunftei vornimmt.
                            </p>
                            <p>
                                Sofern wir eine ausdrückliche Einwilligung von Vertragspartnern einholen,
                                ist die Rechtsgrundlage für
                                <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                    {" "}
                                    - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. -
                                    premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
                                    premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
                                    premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
                                    premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
                                    premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
                                    premiumtext premiumtext premiumtext premiumtext{" "}
                                </span>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                                    Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
                                    Zahlungshistorie
                                    <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                        {" "}
                                        - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
                                        - premiumtext premiumtext{" "}
                                    </span>
                                    ); Kontaktdaten (z.B. E-Mail, Telefonnummern). Vertragsdaten (z.B.
                                    Vertragsgegenstand, Laufzeit, Kundenkategorie
                                    <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                        {" "}
                                        - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
                                        - premiumtext premiumtext{" "}
                                    </span>
                                    ).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Kunden.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Beurteilung der Bonität und
                                    Kreditwürdigkeit.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO).
                                </li>
                                <li>
                                    <strong>Automatisierte Entscheidungen im Einzelfall:</strong>{" "}
                                    Bonitätsauskunft (Entscheidung auf Grundlage einer
                                    <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                        {" "}
                                        - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
                                        - premiumtext premiumtext premiumtext{" "}
                                    </span>
                                    ).
                                </li>
                            </ul>
                            <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
                            <p>
                                Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur
                                Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die IP-Adresse
                                des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer
                                Online-Dienste an den Browser oder das Endgerät der Nutzer zu übermitteln.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
                                    Webseiten, Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations-
                                    und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                                    Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
                                    Nutzer von Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
                                    Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
                                    Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
                                    technischen Geräten (Computer, Server etc.).). Sicherheitsmaßnahmen.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>
                                        Bereitstellung Onlineangebot auf gemietetem Speicherplatz:{" "}
                                    </strong>
                                    Für die Bereitstellung unseres Onlineangebotes nutzen wir Speicherplatz,
                                    Rechenkapazität und Software, die wir von einem entsprechenden
                                    Serveranbieter (auch "Webhoster" genannt) mieten oder anderweitig
                                    beziehen;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO).
                                    </span>
                                </li>
                                <li>
                                    <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff auf
                                    unser Onlineangebot wird in Form von so genannten "Server-Logfiles"
                                    protokolliert. Zu den Serverlogfiles können die Adresse und Name der
                                    abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs,
                                    übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp
                                    nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
                                    besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider
                                    gehören. Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
                                    eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
                                    (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
                                    DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
                                    Stabilität sicherzustellen;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO).{" "}
                                    </span>
                                    <strong>Löschung von Daten:</strong> Logfile-Informationen werden für die
                                    Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder
                                    anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken
                                    erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls
                                    von der Löschung ausgenommen.
                                </li>
                                <li>
                                    <strong>Checkdomain: </strong>Leistungen auf dem Gebiet der Bereitstellung
                                    von informationstechnischer Infrastruktur und verbundenen Dienstleistungen
                                    (z.B. Speicherplatz und/oder Rechenkapazitäten);{" "}
                                    <strong>Dienstanbieter:</strong> checkdomain GmbH, a dogado group company,
                                    Große Burgstraße 27/29, 23552 Lübeck, Deutschland;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO);{" "}
                                    </span>
                                    <strong>Website:</strong>{" "}
                                    <a href="https://www.checkdomain.de/" target="_blank">
                                        https://www.checkdomain.de/
                                    </a>
                                    ; <strong>Datenschutzerklärung:</strong>{" "}
                                    <a href="https://www.checkdomain.de/agb/datenschutz" target="_blank">
                                        https://www.checkdomain.de/agb/datenschutz
                                    </a>
                                    . <strong>Auftragsverarbeitungsvertrag:</strong> Wird vom Dienstanbieter
                                    bereitgestellt.
                                </li>
                            </ul>
                            <h2 id="m367">Registrierung, Anmeldung und Nutzerkonto</h2>
                            <p>
                                Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden
                                den Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu Zwecken der
                                Bereitstellung des Nutzerkontos auf Grundlage vertraglicher Pflichterfüllung
                                verarbeitet. Zu den verarbeiteten Daten gehören insbesondere die
                                Login-Informationen (Nutzername, Passwort sowie eine E-Mail-Adresse).
                            </p>
                            <p>
                                Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen
                                sowie der Nutzung des Nutzerkontos speichern wir die IP-Adresse und den
                                Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf
                                Grundlage unserer berechtigten Interessen als auch jener der Nutzer an einem
                                Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe
                                dieser Daten an Dritte erfolgt grundsätzlich nicht, es sei denn, sie ist zur
                                Verfolgung unserer Ansprüche erforderlich oder es besteht eine gesetzliche
                                Verpflichtung hierzu.
                            </p>
                            <p>
                                Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind,
                                wie z.B. technische Änderungen, per E-Mail informiert werden.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                                    Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B.
                                    Eingaben in Onlineformularen). Meta-, Kommunikations- und Verfahrensdaten
                                    (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern,
                                    Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
                                    Nutzer von Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
                                    Leistungen und erfüllung vertraglicher Pflichten; Sicherheitsmaßnahmen;
                                    Verwaltung und Beantwortung von Anfragen. Bereitstellung unseres
                                    Onlineangebotes und Nutzerfreundlichkeit.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                    Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO). Berechtigte Interessen (Art.
                                    6 Abs. 1 S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Registrierung mit Klarnamen: </strong>Aufgrund der Natur unserer
                                    Community bitten wir die Nutzer unser Angebot nur unter Verwendung von
                                    Klarnamen zu nutzen. D.h. die Nutzung von Pseudonymen ist nicht zulässig;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                                    </span>
                                </li>
                            </ul>
                            <h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
                            <p>
                                Bei der Kontaktaufnahme mit uns (z.B. per Post, Kontaktformular, E-Mail,
                                Telefon oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und
                                Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet
                                soweit dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter
                                Maßnahmen erforderlich ist.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
                                    Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
                                    Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                                    Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z. B.
                                    IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Kommunikationspartner.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
                                    Kommunikation; Verwaltung und Beantwortung von Anfragen; Feedback (z.B.
                                    Sammeln von Feedback via Online-Formular). Bereitstellung unseres
                                    Onlineangebotes und Nutzerfreundlichkeit.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO). Vertragserfüllung und vorvertragliche Anfragen (Art.
                                    6 Abs. 1 S. 1 lit. b) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Kontaktformular: </strong>Wenn Nutzer über unser Kontaktformular,
                                    E-Mail oder andere Kommunikationswege mit uns in Kontakt treten,
                                    verarbeiten wir die uns in diesem Zusammenhang mitgeteilten Daten zur
                                    Bearbeitung des mitgeteilten Anliegens;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte Interessen
                                        (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                                    </span>
                                </li>
                            </ul>
                            <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
                            <p>
                                Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der
                                Auswertung der Besucherströme unseres Onlineangebotes und kann Verhalten,
                                Interessen oder demographische Informationen zu den Besuchern, wie z.B. das
                                Alter oder das Geschlecht, als pseudonyme Werte umfassen. Mit Hilfe der
                                Reichweitenanalyse können wir z.B. erkennen, zu welcher Zeit unser
                                Onlineangebot oder dessen Funktionen oder Inhalte am häufigsten genutzt
                                werden oder zur Wiederverwendung einladen. Ebenso können wir nachvollziehen,
                                welche Bereiche der Optimierung bedürfen.{" "}
                            </p>
                            <p>
                                Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B.
                                unterschiedliche Versionen unseres Onlineangebotes oder seiner Bestandteile
                                zu testen und optimieren.
                            </p>
                            <p>
                                Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken Profile,
                                d.h. zu einem Nutzungsvorgang zusammengefasste Daten angelegt und
                                Informationen in einem Browser, bzw. in einem Endgerät gespeichert und aus
                                diesem ausgelesen werden. Zu den erhobenen Angaben gehören insbesondere
                                besuchte Webseiten und dort genutzte Elemente sowie technische Angaben, wie
                                der verwendete Browser, das verwendete Computersystem sowie Angaben zu
                                Nutzungszeiten. Sofern Nutzer in die Erhebung ihrer Standortdaten uns
                                gegenüber oder gegenüber den Anbietern der von uns eingesetzten Dienste
                                einverstanden erklärt haben, können auch Standortdaten verarbeitet werden.
                            </p>
                            <p>
                                Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen
                                wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der
                                IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von
                                Webanalyse, A/B-Testings und Optimierung keine Klardaten der Nutzer (wie
                                z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir
                                als auch die Anbieter der eingesetzten Software kennen nicht die
                                tatsächliche Identität der Nutzer, sondern nur den für Zwecke der jeweiligen
                                Verfahren in deren Profilen gespeicherten Angaben.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
                                    Webseiten, Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations-
                                    und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                                    Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
                                    Nutzer von Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
                                    Zugriffsstatistiken, Erkennung wiederkehrender Besucher). Profile mit
                                    nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
                                </li>
                                <li>
                                    <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
                                    IP-Adresse).
                                </li>
                            </ul>
                            <h2 id="m264">Onlinemarketing</h2>
                            <p>
                                Wir verarbeiten personenbezogene Daten zu Zwecken des Onlinemarketings,
                                worunter insbesondere die Vermarktung von Werbeflächen oder Darstellung von
                                werbenden und sonstigen Inhalten (zusammenfassend als "Inhalte" bezeichnet)
                                anhand potentieller Interessen der Nutzer sowie die Messung ihrer
                                Effektivität fallen kann.{" "}
                            </p>
                            <p>
                                Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in einer
                                Datei (sogenanntes "Cookie") gespeichert oder es werden ähnliche Verfahren
                                genutzt, mittels derer die für die Darstellung der vorgenannten Inhalte
                                relevante Angaben zum Nutzer gespeichert werden. Zu diesen Angaben können
                                z.B. betrachtete Inhalte, besuchte Webseiten, genutzte Onlinenetzwerke, aber
                                auch Kommunikationspartner und technische Angaben, wie der verwendete
                                Browser, das verwendete Computersystem sowie Angaben zu Nutzungszeiten und
                                genutzten Funktionen gehören. Sofern Nutzer in die Erhebung ihrer
                                Standortdaten eingewilligt haben, können auch diese verarbeitet werden.
                            </p>
                            <p>
                                Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen
                                wir zur Verfügung stehende IP-Masking-Verfahren (d.h., Pseudonymisierung
                                durch Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden im
                                Rahmen des Onlinemarketingverfahren keine Klardaten der Nutzer (wie z.B.
                                E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als
                                auch die Anbieter der Onlinemarketingverfahren kennen nicht die tatsächliche
                                Identität der Nutzer, sondern nur die in deren Profilen gespeicherten
                                Angaben.
                            </p>
                            <p>
                                Die Angaben in den Profilen werden im Regelfall in den Cookies oder mittels
                                ähnlicher Verfahren gespeichert. Diese Cookies können später generell auch
                                auf anderen Webseiten die dasselbe Onlinemarketingverfahren einsetzen,
                                ausgelesen und zu Zwecken der Darstellung von Inhalten analysiert als auch
                                mit weiteren Daten ergänzt und auf dem Server des
                                Onlinemarketingverfahrensanbieters gespeichert werden.
                            </p>
                            <p>
                                Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das ist der
                                Fall, wenn die Nutzer z.B. Mitglieder eines sozialen Netzwerks sind, dessen
                                Onlinemarketingverfahren wir einsetzen und das Netzwerk die Profile der
                                Nutzer mit den vorgenannten Angaben verbindet. Wir bitten darum, zu
                                beachten, dass Nutzer mit den Anbietern zusätzliche Abreden, z.B. durch
                                Einwilligung im Rahmen der Registrierung, treffen können.
                            </p>
                            <p>
                                Wir erhalten grundsätzlich nur Zugang zu zusammengefassten Informationen
                                über den Erfolg unserer Werbeanzeigen. Jedoch können wir im Rahmen
                                sogenannter Konversionsmessungen prüfen, welche unserer
                                Onlinemarketingverfahren zu einer sogenannten Konversion geführt haben, d.h.
                                z.B., zu einem Vertragsschluss mit uns. Die Konversionsmessung wird alleine
                                zur Analyse des Erfolgs unserer Marketingmaßnahmen verwendet.
                            </p>
                            <p>
                                Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass
                                verwendete Cookies für einen Zeitraum von zwei Jahren gespeichert werden.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
                                    Webseiten, Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations-
                                    und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                                    Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
                                    Nutzer von Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
                                    Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Tracking (z.B.
                                    interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies);
                                    Marketing. Profile mit nutzerbezogenen Informationen (Erstellen von
                                    Nutzerprofilen).
                                </li>
                                <li>
                                    <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
                                    IP-Adresse).
                                </li>
                                <li>
                                    <strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Wir verweisen auf die
                                    Datenschutzhinweise der jeweiligen Anbieter und die zu den Anbietern
                                    angegebenen Widerspruchsmöglichkeiten (sog. "Opt-Out"). Sofern keine
                                    explizite Opt-Out-Möglichkeit angegeben wurde, besteht zum einen die
                                    Möglichkeit, dass Sie Cookies in den Einstellungen Ihres Browsers
                                    abschalten. Hierdurch können jedoch Funktionen unseres Onlineangebotes
                                    eingeschränkt werden. Wir empfehlen daher zusätzlich die folgenden
                                    Opt-Out-Möglichkeiten, die zusammenfassend auf jeweilige Gebiete gerichtet
                                    angeboten werden: a) Europa:{" "}
                                    <a href="https://www.youronlinechoices.eu" target="_blank">
                                        https://www.youronlinechoices.eu
                                    </a>
                                    . b) Kanada:{" "}
                                    <a href="https://www.youradchoices.ca/choices" target="_blank">
                                        https://www.youradchoices.ca/choices
                                    </a>
                                    . c) USA:{" "}
                                    <a href="https://www.aboutads.info/choices" target="_blank">
                                        https://www.aboutads.info/choices
                                    </a>
                                    . d) Gebietsübergreifend:{" "}
                                    <a href="https://optout.aboutads.info" target="_blank">
                                        https://optout.aboutads.info
                                    </a>
                                    .
                                </li>
                            </ul>
                            <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
                            <p>
                                Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten
                                in diesem Rahmen Daten der Nutzer, um mit den dort aktiven Nutzern zu
                                kommunizieren oder um Informationen über uns anzubieten.
                            </p>
                            <p>
                                Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der
                                Europäischen Union verarbeitet werden können. Hierdurch können sich für die
                                Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer
                                erschwert werden könnte.
                            </p>
                            <p>
                                Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall
                                für Marktforschungs- und Werbezwecke verarbeitet. So können z.B. anhand des
                                Nutzungsverhaltens und sich daraus ergebender Interessen der Nutzer
                                Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum
                                verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der
                                Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen.
                                Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer
                                gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer
                                gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten
                                unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
                                (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind
                                und bei diesen eingeloggt sind).
                            </p>
                            <p>
                                Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der
                                Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
                                Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.
                            </p>
                            <p>
                                Auch im Fall von Auskunftsanfragen und der Geltendmachung von
                                Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei den
                                Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils
                                Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen
                                ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann
                                können Sie sich an uns wenden.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
                                    Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
                                    Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                                    Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z. B.
                                    IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
                                    Nutzer von Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
                                    Kommunikation; Feedback (z.B. Sammeln von Feedback via Online-Formular).
                                    Marketing.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Instagram: </strong>Soziales Netzwerk;{" "}
                                    <strong>Dienstanbieter:</strong> Meta Platforms Irland Limited, 4 Grand
                                    Canal Square, Grand Canal Harbour, Dublin 2, Irland;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO);{" "}
                                    </span>
                                    <strong>Website:</strong>{" "}
                                    <a href="https://www.instagram.com" target="_blank">
                                        https://www.instagram.com
                                    </a>
                                    . <strong>Datenschutzerklärung:</strong>{" "}
                                    <a href="https://instagram.com/about/legal/privacy" target="_blank">
                                        https://instagram.com/about/legal/privacy
                                    </a>
                                    .
                                </li>
                                <li>
                                    <strong>LinkedIn: </strong>Soziales Netzwerk;{" "}
                                    <strong>Dienstanbieter:</strong> LinkedIn Irland Unlimited Company, Wilton
                                    Plaza Wilton Place, Dublin 2, Irland;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO);{" "}
                                    </span>
                                    <strong>Website:</strong>{" "}
                                    <a href="https://www.linkedin.com" target="_blank">
                                        https://www.linkedin.com
                                    </a>
                                    ; <strong>Datenschutzerklärung:</strong>{" "}
                                    <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank">
                                        https://www.linkedin.com/legal/privacy-policy
                                    </a>
                                    ; <strong>Auftragsverarbeitungsvertrag:</strong>{" "}
                                    <a href="https://legal.linkedin.com/dpa" target="_blank">
                                        https://legal.linkedin.com/dpa
                                    </a>
                                    ; <strong>Grundlage Drittlandübermittlung:</strong>{" "}
                                    <span className=""> </span>Standardvertragsklauseln (
                                    <a href="https://legal.linkedin.com/dpa" target="_blank">
                                        https://legal.linkedin.com/dpa
                                    </a>
                                    ). <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>{" "}
                                    <a
                                        href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                                        target="_blank"
                                    >
                                        https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
                                    </a>
                                    .
                                </li>
                                <li>
                                    <strong>TikTok: </strong>Soziales Netzwerk / Video-Plattform;{" "}
                                    <strong>Dienstanbieter:</strong> TikTok Technology Limited, 10 Earlsfort
                                    Terrace, Dublin, D02 T380, Irland und TikTok Information Technologies UK
                                    Limited, Kaleidoscope, 4 Lindsey Street, London, United Kingdom, EC1A 9HP;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO);{" "}
                                    </span>
                                    <strong>Website:</strong>{" "}
                                    <a href="https://www.tiktok.com" target="_blank">
                                        https://www.tiktok.com
                                    </a>
                                    . <strong>Datenschutzerklärung:</strong>{" "}
                                    <a href="https://www.tiktok.com/de/privacy-policy" target="_blank">
                                        https://www.tiktok.com/de/privacy-policy
                                    </a>
                                    .
                                </li>
                                <li>
                                    <strong>YouTube: </strong>Soziales Netzwerk und Videoplattform;{" "}
                                    <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House,
                                    Barrow Street, Dublin 4, Irland;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO);{" "}
                                    </span>
                                    <strong>Datenschutzerklärung:</strong>{" "}
                                    <a href="https://policies.google.com/privacy" target="_blank">
                                        https://policies.google.com/privacy
                                    </a>
                                    ; <strong>Grundlage Drittlandübermittlung:</strong>{" "}
                                    <span className=""> EU-US Data Privacy Framework (DPF)</span>.{" "}
                                    <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>{" "}
                                    <a href="https://adssettings.google.com/authenticated" target="_blank">
                                        https://adssettings.google.com/authenticated
                                    </a>
                                    .
                                </li>
                            </ul>
                            <h2 id="m42">Begriffsdefinitionen</h2>
                            <p>
                                In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
                                Datenschutzerklärung verwendeten Begrifflichkeiten. Soweit die
                                Begrifflichkeiten gesetzlich definiert sind, gelten deren gesetzliche
                                Definitionen. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem
                                Verständnis dienen.
                            </p>
                            <ul className="glossary">
                                <li>
                                    <strong>Bonitätsauskunft:</strong> Automatisierte Entscheidungen beruhen
                                    auf einer automatischen Datenverarbeitung ohne menschliches Zutun (z.B. im
                                    Fall einer automatische Ablehnung eines Kaufs auf Rechnung, eines
                                    Online-Kreditantrags oder ein Online-Bewerbungsverfahren ohne jegliches
                                    menschliche Eingreifen). Derartige automatisierten Entscheidungen sind
                                    nach Art. 22 DSGVO nur zulässig, wenn Betroffene einwilligen, wenn sie für
                                    eine Vertragserfüllung erforderlich sind oder wenn nationale Gesetze diese
                                    Entscheidungen erlauben.{" "}
                                </li>
                                <li>
                                    <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind
                                    alle Informationen, die sich auf eine identifizierte oder identifizierbare
                                    natürliche Person (im Folgenden "betroffene Person“) beziehen; als
                                    identifizierbar wird eine natürliche Person angesehen, die direkt oder
                                    indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
                                    zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B.
                                    Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert
                                    werden kann, die Ausdruck der physischen, physiologischen, genetischen,
                                    psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser
                                    natürlichen Person sind.{" "}
                                </li>
                                <li>
                                    <strong>Profile mit nutzerbezogenen Informationen:</strong> Die
                                    Verarbeitung von "Profilen mit nutzerbezogenen Informationen", bzw. kurz
                                    "Profilen" umfasst jede Art der automatisierten Verarbeitung
                                    personenbezogener Daten, die darin besteht, dass diese personenbezogenen
                                    Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf
                                    eine natürliche Person beziehen (je nach Art der Profilbildung können dazu
                                    unterschiedliche Informationen betreffend die Demographie, Verhalten und
                                    Interessen, wie z.B. die Interaktion mit Webseiten und deren Inhalten,
                                    etc.) zu analysieren, zu bewerten oder, um sie vorherzusagen (z.B. die
                                    Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf
                                    einer Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings werden
                                    häufig Cookies und Web-Beacons eingesetzt.{" "}
                                </li>
                                <li>
                                    <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web
                                    Analytics bezeichnet) dient der Auswertung der Besucherströme eines
                                    Onlineangebotes und kann das Verhalten oder Interessen der Besucher an
                                    bestimmten Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit
                                    Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu
                                    welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie
                                    sich interessieren. Dadurch können sie z.B. die Inhalte der Webseite
                                    besser an die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der
                                    Reichweitenanalyse werden häufig pseudonyme Cookies und Web-Beacons
                                    eingesetzt, um wiederkehrende Besucher zu erkennen und so genauere
                                    Analysen zur Nutzung eines Onlineangebotes zu erhalten.{" "}
                                </li>
                                <li>
                                    <strong>Tracking:</strong> Vom "Tracking“ spricht man, wenn das Verhalten
                                    von Nutzern über mehrere Onlineangebote hinweg nachvollzogen werden kann.
                                    Im Regelfall werden im Hinblick auf die genutzten Onlineangebote
                                    Verhaltens- und Interessensinformationen in Cookies oder auf Servern der
                                    Anbieter der Trackingtechnologien gespeichert (sogenanntes Profiling).
                                    Diese Informationen können anschließend z.B. eingesetzt werden, um den
                                    Nutzern Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen
                                    entsprechen.{" "}
                                </li>
                                <li>
                                    <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
                                    natürliche oder juristische Person, Behörde, Einrichtung oder andere
                                    Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel
                                    der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.{" "}
                                </li>
                                <li>
                                    <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne
                                    Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
                                    Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff
                                    reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das
                                    Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.{" "}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}

            {windowWidth > 850 && (
                <div>
                    <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center my-5">
                        <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer ml-5">
                            <AiOutlineLeft
                                onClick={() => {
                                    if (user.status === 'rejected') {
                                        navigate("/")
                                    } else {
                                        navigate("/home")
                                    }
                                }}
                                className="text-[352F48] text-lg"
                            />
                        </div>
                        <h1 className="text-lg font-medium m-auto">Datenschutzerklärung</h1>
                        <div></div>
                    </div>

                    <div className="flex justify-center text-sm">
                        <div className="w-1/2">
                            <h2 id="m716">Präambel</h2>
                            <p>
                                Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären,
                                welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als
                                "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang
                                verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten
                                Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung
                                unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen
                                Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer
                                Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als
                                "Onlineangebot“).
                            </p>
                            <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
                            <p>Stand: 15. August 2023</p>
                            <h2>Inhaltsübersicht</h2>{" "}
                            <ul className="index">
                                <li>
                                    <a className="index-link" href="#m716">
                                        Präambel
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m3">
                                        Verantwortlicher
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#mOverview">
                                        Übersicht der Verarbeitungen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m2427">
                                        Maßgebliche Rechtsgrundlagen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m27">
                                        Sicherheitsmaßnahmen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m25">
                                        Übermittlung von personenbezogenen Daten
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m24">
                                        Internationale Datentransfers
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m12">
                                        Löschung von Daten
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m10">
                                        Rechte der betroffenen Personen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m134">
                                        Einsatz von Cookies
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m317">
                                        Geschäftliche Leistungen
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m3575">
                                        Im Rahmen der Geschäftstätigkeit eingesetzte Anbieter und Services
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m326">
                                        Zahlungsverfahren
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m325">
                                        Bonitätsprüfung
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m225">
                                        Bereitstellung des Onlineangebotes und Webhosting
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m367">
                                        Registrierung, Anmeldung und Nutzerkonto
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m182">
                                        Kontakt- und Anfragenverwaltung
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m263">
                                        Webanalyse, Monitoring und Optimierung
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m264">
                                        Onlinemarketing
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m136">
                                        Präsenzen in sozialen Netzwerken (Social Media)
                                    </a>
                                </li>
                                <li>
                                    <a className="index-link" href="#m42">
                                        Begriffsdefinitionen
                                    </a>
                                </li>
                            </ul>
                            <h2 id="m3">Verantwortlicher</h2>
                            <p>
                                Louis, Karakas
                                <br />
                                Scheffelstraße, 2<br />
                                73252, Lenningen, Deutschland
                            </p>
                            Vertretungsberechtigte Personen: <p>Louis Karakas</p>
                            E-Mail-Adresse:{" "}
                            <p>
                                <a href="mailto:louiskarakas.bw@gmail.com">louiskarakas.bw@gmail.com</a>
                            </p>
                            Impressum: <p>www.easycreditor.com/impressum</p>
                            <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
                            <p>
                                <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im Folgenden
                                erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis
                                wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass
                                neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw.
                                unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall
                                speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der
                                Datenschutzerklärung mit.
                            </p>
                            <ul>
                                <li>
                                    <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die
                                    betroffene Person hat ihre Einwilligung in die Verarbeitung der sie
                                    betreffenden personenbezogenen Daten für einen spezifischen Zweck oder
                                    mehrere bestimmte Zwecke gegeben.
                                </li>
                                <li>
                                    <strong>
                                        Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
                                        b) DSGVO)
                                    </strong>{" "}
                                    - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
                                    Vertragspartei die betroffene Person ist, oder zur Durchführung
                                    vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen
                                    Person erfolgen.
                                </li>
                                <li>
                                    <strong>
                                        Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)
                                    </strong>{" "}
                                    - Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
                                    erforderlich, der der Verantwortliche unterliegt.
                                </li>
                                <li>
                                    <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong>{" "}
                                    - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
                                    Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
                                    Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person,
                                    die den Schutz personenbezogener Daten erfordern, überwiegen.
                                </li>
                            </ul>
                            <p>
                                <strong>Nationale Datenschutzregelungen in Deutschland: </strong>Zusätzlich
                                zu den Datenschutzregelungen der DSGVO gelten nationale Regelungen zum
                                Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz
                                vor Missbrauch personenbezogener Daten bei der Datenverarbeitung
                                (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
                                Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum
                                Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener
                                Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie
                                automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling.
                                Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur
                                Anwendung gelangen.
                            </p>
                            <p>
                                <strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese
                                Datenschutzhinweise dienen sowohl der Informationserteilung nach dem
                                schweizerischen Bundesgesetz über den Datenschutz (Schweizer DSG) als auch
                                nach der Datenschutzgrundverordnung (DSGVO). Aus diesem Grund bitten wir Sie
                                zu beachten, dass aufgrund der breiteren räumlichen Anwendung und
                                Verständlichkeit die Begriffe der DSGVO verwendet werden. Insbesondere statt
                                der im Schweizer DSG verwendeten Begriffe „Bearbeitung“ von „Personendaten“,
                                "überwiegendes Interesse" und "besonders schützenswerte Personendaten"
                                werden die in der DSGVO verwendeten Begriffe „Verarbeitung“ von
                                „personenbezogenen Daten“ sowie "berechtigtes Interesse" und "besondere
                                Kategorien von Daten" verwendet. Die gesetzliche Bedeutung der Begriffe wird
                                jedoch im Rahmen der Geltung des Schweizer DSG weiterhin nach dem Schweizer
                                DSG bestimmt.
                            </p>
                            <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
                            <p>
                                Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die
                                Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
                                Personen.
                            </p>
                            <h3>Arten der verarbeiteten Daten</h3>
                            <ul>
                                <li>Bestandsdaten.</li>
                                <li>Zahlungsdaten.</li>
                                <li>Kontaktdaten.</li>
                                <li>Inhaltsdaten.</li>
                                <li>Vertragsdaten.</li>
                                <li>Nutzungsdaten.</li>
                                <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
                            </ul>
                            <h3>Kategorien betroffener Personen</h3>
                            <ul>
                                <li>Kunden.</li>
                                <li>Interessenten.</li>
                                <li>Kommunikationspartner.</li>
                                <li>Nutzer.</li>
                                <li>Geschäfts- und Vertragspartner.</li>
                            </ul>
                            <h3>Zwecke der Verarbeitung</h3>
                            <ul>
                                <li>
                                    Erbringung vertraglicher Leistungen und erfüllung vertraglicher Pflichten.
                                </li>
                                <li>Kontaktanfragen und Kommunikation.</li>
                                <li>Sicherheitsmaßnahmen.</li>
                                <li>Reichweitenmessung.</li>
                                <li>Tracking.</li>
                                <li>Büro- und Organisationsverfahren.</li>
                                <li>Verwaltung und Beantwortung von Anfragen.</li>
                                <li>Feedback.</li>
                                <li>Marketing.</li>
                                <li>Profile mit nutzerbezogenen Informationen.</li>
                                <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                                <li>Beurteilung der Bonität und Kreditwürdigkeit.</li>
                                <li>Informationstechnische Infrastruktur.</li>
                            </ul>
                            <h3>Automatisierte Entscheidungen im Einzelfall</h3>
                            <ul>
                                <li>Bonitätsauskunft.</li>
                            </ul>
                            <h2 id="m27">Sicherheitsmaßnahmen</h2>
                            <p>
                                Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung
                                des Stands der Technik, der Implementierungskosten und der Art, des Umfangs,
                                der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen
                                Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und
                                Freiheiten natürlicher Personen geeignete technische und organisatorische
                                Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
                            </p>
                            <p>
                                Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
                                Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und
                                elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs,
                                der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer
                                Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
                                Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen
                                auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
                                Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von
                                Hardware, Software sowie Verfahren entsprechend dem Prinzip des
                                Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
                                Voreinstellungen.
                            </p>
                            <p>
                                TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot
                                übermittelten Daten zu schützen, nutzen wir eine TLS-Verschlüsselung. Sie
                                erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der
                                Adresszeile Ihres Browsers.
                            </p>
                            <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
                            <p>
                                Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor,
                                dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige
                                Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber
                                offengelegt werden. Zu den Empfängern dieser Daten können z.B. mit
                                IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und
                                Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen
                                Fällen beachten wir die gesetzlichen Vorgaben und schließen insbesondere
                                entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten
                                dienen, mit den Empfängern Ihrer Daten ab.
                            </p>
                            <h2 id="m24">Internationale Datentransfers</h2>
                            <p>
                                Datenverarbeitung in Drittländern: Sofern wir Daten in einem Drittland
                                (d.h., außerhalb der Europäischen Union (EU), des Europäischen
                                Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der
                                Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung
                                von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt
                                dies nur im Einklang mit den gesetzlichen Vorgaben.
                            </p>
                            <p>
                                Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich
                                erforderlicher Übermittlung (s. Art. 49 DSGVO) verarbeiten oder lassen wir
                                die Daten nur in Drittländern mit einem anerkannten Datenschutzniveau (Art.
                                45 DSGVO), beim Vorliegen und Einhaltung vertraglichen Verpflichtung durch
                                sogenannte Standardschutzklauseln der EU-Kommission (Art. 46 DSGVO) oder
                                beim Vorliegen von Zertifizierungen oder verbindlicher internen
                                Datenschutzvorschriften (s. Art. 44 bis 49 DSGVO, Informationsseite der
                                EU-Kommission:{" "}
                                <a
                                    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
                                    target="_blank"
                                >
                                    https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
                                </a>
                                ).
                            </p>
                            <p>
                                EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten „Data
                                Privacy Framework” (DPF) hat die EU-Kommission das Datenschutzniveau
                                ebenfalls für bestimmte Unternehmen aus den USA im Rahmen der
                                Angemessenheitsbeschlusses vom 10.07.2023 als sicher anerkannt. Die Liste
                                der zertifizierten Unternehmen als auch weitere Informationen zu dem DPF
                                können Sie der Webseite des Handelsministeriums der USA unter{" "}
                                <a href="https://www.dataprivacyframework.gov/" target="_blank">
                                    https://www.dataprivacyframework.gov/
                                </a>{" "}
                                (in Englisch) entnehmen. Wir informieren Sie im Rahmen der
                                Datenschutzhinweise welche von uns eingesetzten Diensteanbieter unter dem
                                Data Privacy Framework zertifiziert sind.
                            </p>
                            <h2 id="m12">Löschung von Daten</h2>
                            <p>
                                Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
                                Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen
                                widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck
                                der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht
                                erforderlich sind). Sofern die Daten nicht gelöscht werden, weil sie für
                                andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren
                                Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt
                                und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
                                handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
                                Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
                                Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                                juristischen Person erforderlich ist.{" "}
                            </p>
                            <p>
                                Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung
                                und Löschung von Daten beinhalten, die für die jeweiligen Verarbeitungen
                                vorrangig gelten.
                            </p>
                            <h2 id="m10">Rechte der betroffenen Personen</h2>
                            <p>
                                Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als Betroffene
                                nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis
                                21 DSGVO ergeben:
                            </p>
                            <ul>
                                <li>
                                    <strong>
                                        Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer
                                        besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie
                                        betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
                                        lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für
                                        ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie
                                        betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu
                                        betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
                                        Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
                                        derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit
                                        es mit solcher Direktwerbung in Verbindung steht.
                                    </strong>
                                </li>
                                <li>
                                    <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht,
                                    erteilte Einwilligungen jederzeit zu widerrufen.
                                </li>
                                <li>
                                    <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung
                                    darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
                                    Auskunft über diese Daten sowie auf weitere Informationen und Kopie der
                                    Daten entsprechend den gesetzlichen Vorgaben.
                                </li>
                                <li>
                                    <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
                                    gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
                                    betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen
                                    Daten zu verlangen.
                                </li>
                                <li>
                                    <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong>{" "}
                                    Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen,
                                    dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ
                                    nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung
                                    der Daten zu verlangen.
                                </li>
                                <li>
                                    <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie
                                    betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der
                                    gesetzlichen Vorgaben in einem strukturierten, gängigen und
                                    maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen
                                    anderen Verantwortlichen zu fordern.
                                </li>
                                <li>
                                    <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet
                                    eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
                                    Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde,
                                    insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts,
                                    ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie
                                    der Ansicht sind, dass die Verarbeitung der Sie betreffenden
                                    personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.
                                </li>
                            </ul>
                            <h2 id="m134">Einsatz von Cookies</h2>
                            <p>
                                Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die
                                Informationen auf Endgeräten speichern und Informationen aus den Endgeräten
                                auslesen. Z.B. um den Login-Status in einem Nutzerkonto, einen
                                Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder verwendete
                                Funktionen eines Onlineangebotes speichern. Cookies können ferner zu
                                unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der
                                Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten sowie der
                                Erstellung von Analysen der Besucherströme.{" "}
                            </p>
                            <p>
                                <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang
                                mit den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine
                                vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht gefordert
                                ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn das Speichern
                                und das Auslesen der Informationen, also auch von Cookies, unbedingt
                                erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich
                                gewünschten Telemediendienst (also unser Onlineangebot) zur Verfügung zu
                                stellen. Zu den unbedingt erforderlichen Cookies gehören in der Regel
                                Cookies mit Funktionen, die der Anzeige und Lauffähigkeit des
                                Onlineangebotes , dem Lastausgleich, der Sicherheit, der Speicherung der
                                Präferenzen und Auswahlmöglichkeiten der Nutzer oder ähnlichen mit der
                                Bereitstellung der Haupt- und Nebenfunktionen des von den Nutzern
                                angeforderten Onlineangebotes zusammenhängenden Zwecken dienen. Die
                                widerrufliche Einwilligung wird gegenüber den Nutzern deutlich kommuniziert
                                und enthält die Informationen zu der jeweiligen Cookie-Nutzung.
                            </p>
                            <p>
                                <strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf
                                welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen
                                Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir
                                Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist die
                                Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung.
                                Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf
                                Grundlage unserer berechtigten Interessen (z.B. an einem
                                betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung
                                seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung
                                unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies
                                erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen. Zu
                                welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir
                                im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
                                Einwilligungs- und Verarbeitungsprozessen auf.
                            </p>
                            <p>
                                <strong>Speicherdauer:&nbsp;</strong>Im Hinblick auf die Speicherdauer
                                werden die folgenden Arten von Cookies unterschieden:
                            </p>
                            <ul>
                                <li>
                                    <strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong>
                                    &nbsp;Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
                                    Online-Angebot verlassen und sein Endgerät (z.B. Browser oder mobile
                                    Applikation) geschlossen hat.
                                </li>
                                <li>
                                    <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach
                                    dem Schließen des Endgerätes gespeichert. So können beispielsweise der
                                    Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden,
                                    wenn der Nutzer eine Website erneut besucht. Ebenso können die mit Hilfe
                                    von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung verwendet
                                    werden. Sofern wir Nutzern&nbsp;keine expliziten Angaben zur Art und
                                    Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der
                                    Einwilligung), sollten Nutzer davon ausgehen, dass Cookies permanent sind
                                    und die Speicherdauer bis zu zwei Jahre betragen kann.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Allgemeine Hinweise zum Widerruf und Widerspruch (sog. "Opt-Out"):{" "}
                                </strong>
                                Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit widerrufen
                                und der Verarbeitung entsprechend den gesetzlichen Vorgaben widersprechen.
                                Hierzu können Nutzer unter anderem die Verwendung von Cookies in den
                                Einstellungen ihres Browsers einschränken (wobei dadurch auch die
                                Funktionalität unseres Onlineangebotes eingeschränkt sein kann). Ein
                                Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken
                                kann auch über die Websites{" "}
                                <a href="https://optout.aboutads.info/" target="_new">
                                    https://optout.aboutads.info
                                </a>{" "}
                                und{" "}
                                <a href="https://www.youronlinechoices.com/" target="_new">
                                    https://www.youronlinechoices.com/
                                </a>{" "}
                                erklärt werden.
                            </p>
                            <ul className="m-elements">
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>
                                        Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:{" "}
                                    </strong>
                                    Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in
                                    dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies,
                                    bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens
                                    genannten Verarbeitungen und Anbieter eingeholt sowie von den Nutzern
                                    verwaltet und widerrufen werden können. Hierbei wird die
                                    Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut
                                    wiederholen zu müssen und die Einwilligung entsprechend der gesetzlichen
                                    Verpflichtung nachweisen zu können. Die Speicherung kann serverseitig
                                    und/oder in einem Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe
                                    vergleichbarer Technologien) erfolgen, um die Einwilligung einem Nutzer,
                                    bzw. dessen Gerät zuordnen zu können. Vorbehaltlich individueller Angaben
                                    zu den Anbietern von Cookie-Management-Diensten, gelten die folgenden
                                    Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu zwei
                                    Jahren betragen. Hierbei wird ein pseudonymer Nutzer-Identifikator
                                    gebildet und mit dem Zeitpunkt der Einwilligung, Angaben zur Reichweite
                                    der Einwilligung (z. B. welche Kategorien von Cookies und/oder
                                    Diensteanbieter) sowie dem Browser, System und verwendeten Endgerät
                                    gespeichert;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
                                        a) DSGVO).
                                    </span>
                                </li>
                            </ul>
                            <h2 id="m317">Geschäftliche Leistungen</h2>
                            <p>
                                Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden
                                und Interessenten (zusammenfassend bezeichnet als "Vertragspartner") im
                                Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit
                                verbundenen Maßnahmen und im Rahmen der Kommunikation mit den
                                Vertragspartnern (oder vorvertraglich), z.B., um Anfragen zu beantworten.
                            </p>
                            <p>
                                Wir verarbeiten diese Daten, um unsere vertraglichen Verpflichtungen zu
                                erfüllen. Dazu gehören insbesondere die Verpflichtungen zur Erbringung der
                                vereinbarten Leistungen, etwaige Aktualisierungspflichten und Abhilfe bei
                                Gewährleistungs- und sonstigen Leistungsstörungen. Darüber hinaus
                                verarbeiten wir die Daten zur Wahrung unserer Rechte und zum Zwecke der mit
                                diesen Pflichten verbundenen Verwaltungsaufgaben sowie der
                                Unternehmensorganisation. Darüber hinaus verarbeiten wir die Daten auf
                                Grundlage unserer berechtigten Interessen an einer ordnungsgemäßen und
                                betriebswirtschaftlichen Geschäftsführung sowie an Sicherheitsmaßnahmen zum
                                Schutz unserer Vertragspartner und unseres Geschäftsbetriebes vor
                                Missbrauch, Gefährdung ihrer Daten, Geheimnisse, Informationen und Rechte
                                (z.B. zur Beteiligung von Telekommunikations-, Transport- und sonstigen
                                Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und Rechtsberatern,
                                Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des geltenden Rechts
                                geben wir die Daten von Vertragspartnern nur insoweit an Dritte weiter, als
                                dies für die vorgenannten Zwecke oder zur Erfüllung gesetzlicher Pflichten
                                erforderlich ist. Über weitere Formen der Verarbeitung, z.B. zu
                                Marketingzwecken, werden die Vertragspartner im Rahmen dieser
                                Datenschutzerklärung informiert.
                            </p>
                            <p>
                                Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den
                                Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in
                                Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw. Symbole
                                (z.B. Sternchen o.ä.), oder persönlich mit.
                            </p>
                            <p>
                                Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
                                vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4 Jahren, es
                                sei denn, dass die Daten in einem Kundenkonto gespeichert werden, z.B.,
                                solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden
                                müssen. Die gesetzliche Aufbewahrungsfrist beträgt bei steuerrechtlich
                                relevanten Unterlagen sowie bei Handelsbüchern, Inventaren,
                                Eröffnungsbilanzen, Jahresabschlüssen, die zum Verständnis dieser Unterlagen
                                erforderlichen Arbeitsanweisungen und sonstigen Organisationsunterlagen und
                                Buchungsbelegen zehn Jahre sowie bei empfangenen Handels- und
                                Geschäftsbriefen und Wiedergaben der abgesandten Handels- und
                                Geschäftsbriefe sechs Jahre. Die Frist beginnt mit Ablauf des
                                Kalenderjahres, in dem die letzte Eintragung in das Buch gemacht, das
                                Inventar, die Eröffnungsbilanz, der Jahresabschluss oder der Lagebericht
                                aufgestellt, der Handels- oder Geschäftsbrief empfangen oder abgesandt
                                worden oder der Buchungsbeleg entstanden ist, ferner die Aufzeichnung
                                vorgenommen worden ist oder die sonstigen Unterlagen entstanden sind.
                            </p>
                            <p>
                                Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen
                                einsetzen, gelten im Verhältnis zwischen den Nutzern und den Anbietern die
                                Geschäftsbedingungen und Datenschutzhinweise der jeweiligen Drittanbieter
                                oder Plattformen.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                                    Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
                                    Zahlungshistorie); Kontaktdaten (z.B. E-Mail, Telefonnummern);
                                    Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie);
                                    Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                                    Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z. B.
                                    IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Kunden; Interessenten. Geschäfts-
                                    und Vertragspartner.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
                                    Leistungen und erfüllung vertraglicher Pflichten; Sicherheitsmaßnahmen;
                                    Kontaktanfragen und Kommunikation; Büro- und Organisationsverfahren.
                                    Verwaltung und Beantwortung von Anfragen.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                    Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); Rechtliche Verpflichtung
                                    (Art. 6 Abs. 1 S. 1 lit. c) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Kundenkonto: </strong>Kunden können innerhalb unseres
                                    Onlineangebotes ein Konto anlegen (z.B. Kunden- bzw. Nutzerkonto, kurz
                                    "Kundenkonto"). Falls die Registrierung eines Kundenkontos erforderlich
                                    ist, werden Kunden hierauf ebenso hingewiesen wie auf die für die
                                    Registrierung erforderlichen Angaben. Die Kundenkonten sind nicht
                                    öffentlich und können von Suchmaschinen nicht indexiert werden. Im Rahmen
                                    der Registrierung sowie anschließender Anmeldungen und Nutzungen des
                                    Kundenkontos speichern wir die IP-Adressen der Kunden nebst den
                                    Zugriffszeitpunkten, um die Registrierung nachweisen und etwaigem
                                    Missbrauch des Kundenkontos vorbeugen zu können. Wurde das Kundenkonto
                                    gekündigt, werden die Daten des Kundenkontos nach dem Kündigungszeitpunkt
                                    gelöscht, sofern sie nicht für andere Zwecke als die Bereitstellung im
                                    Kundenkonto aufbewahrt werden oder aus rechtlichen Gründen aufbewahrt
                                    werden müssen (z.B. interne Speicherung von Kundendaten, Bestellvorgängen
                                    oder Rechnungen). Es liegt in der Verantwortung der Kunden, ihre Daten bei
                                    Kündigung des Kundenkontos zu sichern;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                                    </span>
                                </li>
                                <li>
                                    <strong>Angebot von Software- und Plattformleistungen: </strong>Wir
                                    verarbeiten die Daten unserer Nutzer, angemeldeter und etwaiger Testnutzer
                                    (nachfolgend einheitlich als "Nutzer" bezeichnet), um ihnen gegenüber
                                    unsere vertraglichen Leistungen erbringen zu können sowie auf Grundlage
                                    berechtigter Interessen, um die Sicherheit unseres Angebotes gewährleisten
                                    und es weiterentwickeln zu können. Die erforderlichen Angaben sind als
                                    solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren
                                    Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung
                                    und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige
                                    Rücksprachen halten zu können;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                                    </span>
                                </li>
                            </ul>
                            <h2 id="m3575">
                                Im Rahmen der Geschäftstätigkeit eingesetzte Anbieter und Services
                            </h2>
                            <p>
                                Im Rahmen unserer Geschäftstätigkeit nutzen wir unter Beachtung der
                                gesetzlichen Vorgaben zusätzliche Dienste, Plattformen, Schnittstellen oder
                                Plug-ins von Drittanbietern (kurz "Dienste"). Deren Nutzung beruht auf
                                unseren Interessen an einer ordnungsgemäßen, rechtmäßigen und
                                <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                    {" "}
                                    - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. -
                                    premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
                                    premiumtext premiumtext premiumtext premiumtext{" "}
                                </span>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                                    Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
                                    Zahlungshistorie
                                    <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                        {" "}
                                        - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
                                        - premiumtext premiumtext{" "}
                                    </span>
                                    ); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B. Eingaben
                                    in Onlineformularen). Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
                                    Kundenkategorie
                                    <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                        {" "}
                                        - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
                                        - premiumtext premiumtext{" "}
                                    </span>
                                    ).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Kunden; Interessenten; Nutzer (z.B.
                                    Webseitenbesucher, Nutzer von
                                    <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                        {" "}
                                        - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
                                        - premiumtext premiumtext premiumtext{" "}
                                    </span>
                                    ). Geschäfts- und Vertragspartner.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
                                    Leistungen und erfüllung vertraglicher Pflichten. Büro- und
                                    Organisationsverfahren.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <h2 id="m326">Zahlungsverfahren</h2>
                            <p>
                                Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund
                                gesetzlicher Pflichten oder sonst auf Grundlage unserer berechtigten
                                Interessen bieten wir den betroffenen Personen effiziente und sichere
                                Zahlungsmöglichkeiten an und setzen hierzu neben Banken und Kreditinstituten
                                weitere Dienstleister ein (zusammenfassend "Zahlungsdienstleister").
                            </p>
                            <p>
                                Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören
                                Bestandsdaten, wie z.B. der Name und die Adresse, Bankdaten, wie z.B.
                                Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie
                                die Vertrags-, Summen- und empfängerbezogenen Angaben. Die Angaben sind
                                erforderlich, um die Transaktionen durchzuführen. Die eingegebenen Daten
                                werden jedoch nur durch die Zahlungsdienstleister verarbeitet und bei diesen
                                gespeichert. D.h., wir erhalten keine konto- oder kreditkartenbezogenen
                                Informationen, sondern lediglich Informationen mit Bestätigung oder
                                Negativbeauskunftung der Zahlung. Unter Umständen werden die Daten seitens
                                der Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese
                                Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. Hierzu verweisen
                                wir auf die AGB und die Datenschutzhinweise der Zahlungsdienstleister.
                            </p>
                            <p>
                                Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die
                                Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche innerhalb
                                der jeweiligen Webseiten bzw. Transaktionsapplikationen abrufbar sind. Wir
                                verweisen auf diese ebenfalls zwecks weiterer Informationen und
                                Geltendmachung von Widerrufs-, Auskunfts- und anderen Betroffenenrechten.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                                    Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
                                    Zahlungshistorie); Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
                                    Kundenkategorie); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                                    Inhalten, Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z.
                                    B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Kunden. Interessenten.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
                                    Leistungen und erfüllung vertraglicher Pflichten.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                    Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Giropay: </strong>Zahlungsdienstleistungen (technische Anbindung
                                    von Online-Bezahlmethoden); <strong>Dienstanbieter:</strong> giropay GmbH,
                                    An der Welle 4, 60322 Frankfurt, Deutschland;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO);{" "}
                                    </span>
                                    <strong>Website:</strong>{" "}
                                    <a href="https://www.giropay.de" target="_blank">
                                        https://www.giropay.de
                                    </a>
                                    . <strong>Datenschutzerklärung:</strong>{" "}
                                    <a
                                        href="https://www.giropay.de/rechtliches/datenschutzerklaerung/"
                                        target="_blank"
                                    >
                                        https://www.giropay.de/rechtliches/datenschutzerklaerung/
                                    </a>
                                    .
                                </li>
                            </ul>
                            <h2 id="m325">Bonitätsprüfung</h2>
                            <p>
                                Sofern wir in Vorleistung treten oder vergleichbare wirtschaftliche Risiken
                                eingehen (z.B. beim Bestellung auf Rechnung), behalten wir uns vor, zur
                                Wahrung der berechtigten Interessen eine Identitäts- und Bonitätsauskunft
                                zwecks Beurteilung des Kreditrisikos auf Basis von
                                mathematisch-statistischen Verfahren von hierauf spezialisierten
                                Dienstleistungsunternehmen (Wirtschaftsauskunfteien) einzuholen.
                            </p>
                            <p>
                                Die von den Wirtschaftsauskunfteien erhaltenen Informationen über die
                                statistische Wahrscheinlichkeit eines Zahlungsausfalls verarbeiten wir im
                                Rahmen einer sachgerechten Ermessensentscheidung über die Begründung,
                                Durchführung und Beendigung des Vertragsverhältnisses. Wir behalten uns vor,
                                im Fall eines negativen Ergebnisses der Bonitätsprüfung, die Zahlung auf
                                Rechnung oder eine andere Vorleistung zu verweigern.
                            </p>
                            <p>
                                Die Entscheidung, ob wir in Vorleistung treten, erfolgt entsprechend den
                                gesetzlichen Vorgaben alleine auf Grundlage einer automatisierten
                                Entscheidung im Einzelfall, die unsere Software unter Zugrundelegung der
                                Auskunft der Wirtschaftsauskunftei vornimmt.
                            </p>
                            <p>
                                Sofern wir eine ausdrückliche Einwilligung von Vertragspartnern einholen,
                                ist die Rechtsgrundlage für
                                <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                    {" "}
                                    - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. -
                                    premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
                                    premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
                                    premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
                                    premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
                                    premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext
                                    premiumtext premiumtext premiumtext premiumtext{" "}
                                </span>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                                    Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
                                    Zahlungshistorie
                                    <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                        {" "}
                                        - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
                                        - premiumtext premiumtext{" "}
                                    </span>
                                    ); Kontaktdaten (z.B. E-Mail, Telefonnummern). Vertragsdaten (z.B.
                                    Vertragsgegenstand, Laufzeit, Kundenkategorie
                                    <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                        {" "}
                                        - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
                                        - premiumtext premiumtext{" "}
                                    </span>
                                    ).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Kunden.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Beurteilung der Bonität und
                                    Kreditwürdigkeit.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO).
                                </li>
                                <li>
                                    <strong>Automatisierte Entscheidungen im Einzelfall:</strong>{" "}
                                    Bonitätsauskunft (Entscheidung auf Grundlage einer
                                    <span className="dsg-license-content-blurred de dsg-ttip-activate">
                                        {" "}
                                        - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden.
                                        - premiumtext premiumtext premiumtext{" "}
                                    </span>
                                    ).
                                </li>
                            </ul>
                            <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
                            <p>
                                Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur
                                Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die IP-Adresse
                                des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer
                                Online-Dienste an den Browser oder das Endgerät der Nutzer zu übermitteln.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
                                    Webseiten, Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations-
                                    und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                                    Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
                                    Nutzer von Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
                                    Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
                                    Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
                                    technischen Geräten (Computer, Server etc.).). Sicherheitsmaßnahmen.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>
                                        Bereitstellung Onlineangebot auf gemietetem Speicherplatz:{" "}
                                    </strong>
                                    Für die Bereitstellung unseres Onlineangebotes nutzen wir Speicherplatz,
                                    Rechenkapazität und Software, die wir von einem entsprechenden
                                    Serveranbieter (auch "Webhoster" genannt) mieten oder anderweitig
                                    beziehen;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO).
                                    </span>
                                </li>
                                <li>
                                    <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff auf
                                    unser Onlineangebot wird in Form von so genannten "Server-Logfiles"
                                    protokolliert. Zu den Serverlogfiles können die Adresse und Name der
                                    abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs,
                                    übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp
                                    nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
                                    besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider
                                    gehören. Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
                                    eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
                                    (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
                                    DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
                                    Stabilität sicherzustellen;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO).{" "}
                                    </span>
                                    <strong>Löschung von Daten:</strong> Logfile-Informationen werden für die
                                    Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder
                                    anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken
                                    erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls
                                    von der Löschung ausgenommen.
                                </li>
                                <li>
                                    <strong>Checkdomain: </strong>Leistungen auf dem Gebiet der Bereitstellung
                                    von informationstechnischer Infrastruktur und verbundenen Dienstleistungen
                                    (z.B. Speicherplatz und/oder Rechenkapazitäten);{" "}
                                    <strong>Dienstanbieter:</strong> checkdomain GmbH, a dogado group company,
                                    Große Burgstraße 27/29, 23552 Lübeck, Deutschland;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO);{" "}
                                    </span>
                                    <strong>Website:</strong>{" "}
                                    <a href="https://www.checkdomain.de/" target="_blank">
                                        https://www.checkdomain.de/
                                    </a>
                                    ; <strong>Datenschutzerklärung:</strong>{" "}
                                    <a href="https://www.checkdomain.de/agb/datenschutz" target="_blank">
                                        https://www.checkdomain.de/agb/datenschutz
                                    </a>
                                    . <strong>Auftragsverarbeitungsvertrag:</strong> Wird vom Dienstanbieter
                                    bereitgestellt.
                                </li>
                            </ul>
                            <h2 id="m367">Registrierung, Anmeldung und Nutzerkonto</h2>
                            <p>
                                Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden
                                den Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu Zwecken der
                                Bereitstellung des Nutzerkontos auf Grundlage vertraglicher Pflichterfüllung
                                verarbeitet. Zu den verarbeiteten Daten gehören insbesondere die
                                Login-Informationen (Nutzername, Passwort sowie eine E-Mail-Adresse).
                            </p>
                            <p>
                                Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen
                                sowie der Nutzung des Nutzerkontos speichern wir die IP-Adresse und den
                                Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf
                                Grundlage unserer berechtigten Interessen als auch jener der Nutzer an einem
                                Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe
                                dieser Daten an Dritte erfolgt grundsätzlich nicht, es sei denn, sie ist zur
                                Verfolgung unserer Ansprüche erforderlich oder es besteht eine gesetzliche
                                Verpflichtung hierzu.
                            </p>
                            <p>
                                Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind,
                                wie z.B. technische Änderungen, per E-Mail informiert werden.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                                    Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B.
                                    Eingaben in Onlineformularen). Meta-, Kommunikations- und Verfahrensdaten
                                    (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern,
                                    Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
                                    Nutzer von Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
                                    Leistungen und erfüllung vertraglicher Pflichten; Sicherheitsmaßnahmen;
                                    Verwaltung und Beantwortung von Anfragen. Bereitstellung unseres
                                    Onlineangebotes und Nutzerfreundlichkeit.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                    Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO). Berechtigte Interessen (Art.
                                    6 Abs. 1 S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Registrierung mit Klarnamen: </strong>Aufgrund der Natur unserer
                                    Community bitten wir die Nutzer unser Angebot nur unter Verwendung von
                                    Klarnamen zu nutzen. D.h. die Nutzung von Pseudonymen ist nicht zulässig;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                                    </span>
                                </li>
                            </ul>
                            <h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
                            <p>
                                Bei der Kontaktaufnahme mit uns (z.B. per Post, Kontaktformular, E-Mail,
                                Telefon oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und
                                Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet
                                soweit dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter
                                Maßnahmen erforderlich ist.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
                                    Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
                                    Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                                    Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z. B.
                                    IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Kommunikationspartner.
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
                                    Kommunikation; Verwaltung und Beantwortung von Anfragen; Feedback (z.B.
                                    Sammeln von Feedback via Online-Formular). Bereitstellung unseres
                                    Onlineangebotes und Nutzerfreundlichkeit.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO). Vertragserfüllung und vorvertragliche Anfragen (Art.
                                    6 Abs. 1 S. 1 lit. b) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Kontaktformular: </strong>Wenn Nutzer über unser Kontaktformular,
                                    E-Mail oder andere Kommunikationswege mit uns in Kontakt treten,
                                    verarbeiten wir die uns in diesem Zusammenhang mitgeteilten Daten zur
                                    Bearbeitung des mitgeteilten Anliegens;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                                        Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte Interessen
                                        (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                                    </span>
                                </li>
                            </ul>
                            <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
                            <p>
                                Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der
                                Auswertung der Besucherströme unseres Onlineangebotes und kann Verhalten,
                                Interessen oder demographische Informationen zu den Besuchern, wie z.B. das
                                Alter oder das Geschlecht, als pseudonyme Werte umfassen. Mit Hilfe der
                                Reichweitenanalyse können wir z.B. erkennen, zu welcher Zeit unser
                                Onlineangebot oder dessen Funktionen oder Inhalte am häufigsten genutzt
                                werden oder zur Wiederverwendung einladen. Ebenso können wir nachvollziehen,
                                welche Bereiche der Optimierung bedürfen.{" "}
                            </p>
                            <p>
                                Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B.
                                unterschiedliche Versionen unseres Onlineangebotes oder seiner Bestandteile
                                zu testen und optimieren.
                            </p>
                            <p>
                                Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken Profile,
                                d.h. zu einem Nutzungsvorgang zusammengefasste Daten angelegt und
                                Informationen in einem Browser, bzw. in einem Endgerät gespeichert und aus
                                diesem ausgelesen werden. Zu den erhobenen Angaben gehören insbesondere
                                besuchte Webseiten und dort genutzte Elemente sowie technische Angaben, wie
                                der verwendete Browser, das verwendete Computersystem sowie Angaben zu
                                Nutzungszeiten. Sofern Nutzer in die Erhebung ihrer Standortdaten uns
                                gegenüber oder gegenüber den Anbietern der von uns eingesetzten Dienste
                                einverstanden erklärt haben, können auch Standortdaten verarbeitet werden.
                            </p>
                            <p>
                                Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen
                                wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der
                                IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von
                                Webanalyse, A/B-Testings und Optimierung keine Klardaten der Nutzer (wie
                                z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir
                                als auch die Anbieter der eingesetzten Software kennen nicht die
                                tatsächliche Identität der Nutzer, sondern nur den für Zwecke der jeweiligen
                                Verfahren in deren Profilen gespeicherten Angaben.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
                                    Webseiten, Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations-
                                    und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                                    Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
                                    Nutzer von Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
                                    Zugriffsstatistiken, Erkennung wiederkehrender Besucher). Profile mit
                                    nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
                                </li>
                                <li>
                                    <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
                                    IP-Adresse).
                                </li>
                            </ul>
                            <h2 id="m264">Onlinemarketing</h2>
                            <p>
                                Wir verarbeiten personenbezogene Daten zu Zwecken des Onlinemarketings,
                                worunter insbesondere die Vermarktung von Werbeflächen oder Darstellung von
                                werbenden und sonstigen Inhalten (zusammenfassend als "Inhalte" bezeichnet)
                                anhand potentieller Interessen der Nutzer sowie die Messung ihrer
                                Effektivität fallen kann.{" "}
                            </p>
                            <p>
                                Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in einer
                                Datei (sogenanntes "Cookie") gespeichert oder es werden ähnliche Verfahren
                                genutzt, mittels derer die für die Darstellung der vorgenannten Inhalte
                                relevante Angaben zum Nutzer gespeichert werden. Zu diesen Angaben können
                                z.B. betrachtete Inhalte, besuchte Webseiten, genutzte Onlinenetzwerke, aber
                                auch Kommunikationspartner und technische Angaben, wie der verwendete
                                Browser, das verwendete Computersystem sowie Angaben zu Nutzungszeiten und
                                genutzten Funktionen gehören. Sofern Nutzer in die Erhebung ihrer
                                Standortdaten eingewilligt haben, können auch diese verarbeitet werden.
                            </p>
                            <p>
                                Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen
                                wir zur Verfügung stehende IP-Masking-Verfahren (d.h., Pseudonymisierung
                                durch Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden im
                                Rahmen des Onlinemarketingverfahren keine Klardaten der Nutzer (wie z.B.
                                E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als
                                auch die Anbieter der Onlinemarketingverfahren kennen nicht die tatsächliche
                                Identität der Nutzer, sondern nur die in deren Profilen gespeicherten
                                Angaben.
                            </p>
                            <p>
                                Die Angaben in den Profilen werden im Regelfall in den Cookies oder mittels
                                ähnlicher Verfahren gespeichert. Diese Cookies können später generell auch
                                auf anderen Webseiten die dasselbe Onlinemarketingverfahren einsetzen,
                                ausgelesen und zu Zwecken der Darstellung von Inhalten analysiert als auch
                                mit weiteren Daten ergänzt und auf dem Server des
                                Onlinemarketingverfahrensanbieters gespeichert werden.
                            </p>
                            <p>
                                Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das ist der
                                Fall, wenn die Nutzer z.B. Mitglieder eines sozialen Netzwerks sind, dessen
                                Onlinemarketingverfahren wir einsetzen und das Netzwerk die Profile der
                                Nutzer mit den vorgenannten Angaben verbindet. Wir bitten darum, zu
                                beachten, dass Nutzer mit den Anbietern zusätzliche Abreden, z.B. durch
                                Einwilligung im Rahmen der Registrierung, treffen können.
                            </p>
                            <p>
                                Wir erhalten grundsätzlich nur Zugang zu zusammengefassten Informationen
                                über den Erfolg unserer Werbeanzeigen. Jedoch können wir im Rahmen
                                sogenannter Konversionsmessungen prüfen, welche unserer
                                Onlinemarketingverfahren zu einer sogenannten Konversion geführt haben, d.h.
                                z.B., zu einem Vertragsschluss mit uns. Die Konversionsmessung wird alleine
                                zur Analyse des Erfolgs unserer Marketingmaßnahmen verwendet.
                            </p>
                            <p>
                                Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass
                                verwendete Cookies für einen Zeitraum von zwei Jahren gespeichert werden.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
                                    Webseiten, Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations-
                                    und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                                    Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
                                    Nutzer von Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
                                    Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Tracking (z.B.
                                    interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies);
                                    Marketing. Profile mit nutzerbezogenen Informationen (Erstellen von
                                    Nutzerprofilen).
                                </li>
                                <li>
                                    <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
                                    IP-Adresse).
                                </li>
                                <li>
                                    <strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Wir verweisen auf die
                                    Datenschutzhinweise der jeweiligen Anbieter und die zu den Anbietern
                                    angegebenen Widerspruchsmöglichkeiten (sog. "Opt-Out"). Sofern keine
                                    explizite Opt-Out-Möglichkeit angegeben wurde, besteht zum einen die
                                    Möglichkeit, dass Sie Cookies in den Einstellungen Ihres Browsers
                                    abschalten. Hierdurch können jedoch Funktionen unseres Onlineangebotes
                                    eingeschränkt werden. Wir empfehlen daher zusätzlich die folgenden
                                    Opt-Out-Möglichkeiten, die zusammenfassend auf jeweilige Gebiete gerichtet
                                    angeboten werden: a) Europa:{" "}
                                    <a href="https://www.youronlinechoices.eu" target="_blank">
                                        https://www.youronlinechoices.eu
                                    </a>
                                    . b) Kanada:{" "}
                                    <a href="https://www.youradchoices.ca/choices" target="_blank">
                                        https://www.youradchoices.ca/choices
                                    </a>
                                    . c) USA:{" "}
                                    <a href="https://www.aboutads.info/choices" target="_blank">
                                        https://www.aboutads.info/choices
                                    </a>
                                    . d) Gebietsübergreifend:{" "}
                                    <a href="https://optout.aboutads.info" target="_blank">
                                        https://optout.aboutads.info
                                    </a>
                                    .
                                </li>
                            </ul>
                            <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
                            <p>
                                Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten
                                in diesem Rahmen Daten der Nutzer, um mit den dort aktiven Nutzern zu
                                kommunizieren oder um Informationen über uns anzubieten.
                            </p>
                            <p>
                                Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der
                                Europäischen Union verarbeitet werden können. Hierdurch können sich für die
                                Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer
                                erschwert werden könnte.
                            </p>
                            <p>
                                Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall
                                für Marktforschungs- und Werbezwecke verarbeitet. So können z.B. anhand des
                                Nutzungsverhaltens und sich daraus ergebender Interessen der Nutzer
                                Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum
                                verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der
                                Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen.
                                Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer
                                gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer
                                gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten
                                unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
                                (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind
                                und bei diesen eingeloggt sind).
                            </p>
                            <p>
                                Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der
                                Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
                                Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.
                            </p>
                            <p>
                                Auch im Fall von Auskunftsanfragen und der Geltendmachung von
                                Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei den
                                Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils
                                Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen
                                ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann
                                können Sie sich an uns wenden.
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
                                    Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
                                    Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                                    Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z. B.
                                    IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li>
                                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
                                    Nutzer von Onlinediensten).
                                </li>
                                <li>
                                    <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
                                    Kommunikation; Feedback (z.B. Sammeln von Feedback via Online-Formular).
                                    Marketing.
                                </li>
                                <li className="">
                                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                    S. 1 lit. f) DSGVO).
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
                                </strong>
                            </p>
                            <ul className="m-elements">
                                <li>
                                    <strong>Instagram: </strong>Soziales Netzwerk;{" "}
                                    <strong>Dienstanbieter:</strong> Meta Platforms Irland Limited, 4 Grand
                                    Canal Square, Grand Canal Harbour, Dublin 2, Irland;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO);{" "}
                                    </span>
                                    <strong>Website:</strong>{" "}
                                    <a href="https://www.instagram.com" target="_blank">
                                        https://www.instagram.com
                                    </a>
                                    . <strong>Datenschutzerklärung:</strong>{" "}
                                    <a href="https://instagram.com/about/legal/privacy" target="_blank">
                                        https://instagram.com/about/legal/privacy
                                    </a>
                                    .
                                </li>
                                <li>
                                    <strong>LinkedIn: </strong>Soziales Netzwerk;{" "}
                                    <strong>Dienstanbieter:</strong> LinkedIn Irland Unlimited Company, Wilton
                                    Plaza Wilton Place, Dublin 2, Irland;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO);{" "}
                                    </span>
                                    <strong>Website:</strong>{" "}
                                    <a href="https://www.linkedin.com" target="_blank">
                                        https://www.linkedin.com
                                    </a>
                                    ; <strong>Datenschutzerklärung:</strong>{" "}
                                    <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank">
                                        https://www.linkedin.com/legal/privacy-policy
                                    </a>
                                    ; <strong>Auftragsverarbeitungsvertrag:</strong>{" "}
                                    <a href="https://legal.linkedin.com/dpa" target="_blank">
                                        https://legal.linkedin.com/dpa
                                    </a>
                                    ; <strong>Grundlage Drittlandübermittlung:</strong>{" "}
                                    <span className=""> </span>Standardvertragsklauseln (
                                    <a href="https://legal.linkedin.com/dpa" target="_blank">
                                        https://legal.linkedin.com/dpa
                                    </a>
                                    ). <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>{" "}
                                    <a
                                        href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                                        target="_blank"
                                    >
                                        https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
                                    </a>
                                    .
                                </li>
                                <li>
                                    <strong>TikTok: </strong>Soziales Netzwerk / Video-Plattform;{" "}
                                    <strong>Dienstanbieter:</strong> TikTok Technology Limited, 10 Earlsfort
                                    Terrace, Dublin, D02 T380, Irland und TikTok Information Technologies UK
                                    Limited, Kaleidoscope, 4 Lindsey Street, London, United Kingdom, EC1A 9HP;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO);{" "}
                                    </span>
                                    <strong>Website:</strong>{" "}
                                    <a href="https://www.tiktok.com" target="_blank">
                                        https://www.tiktok.com
                                    </a>
                                    . <strong>Datenschutzerklärung:</strong>{" "}
                                    <a href="https://www.tiktok.com/de/privacy-policy" target="_blank">
                                        https://www.tiktok.com/de/privacy-policy
                                    </a>
                                    .
                                </li>
                                <li>
                                    <strong>YouTube: </strong>Soziales Netzwerk und Videoplattform;{" "}
                                    <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House,
                                    Barrow Street, Dublin 4, Irland;{" "}
                                    <span className="">
                                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
                                        S. 1 lit. f) DSGVO);{" "}
                                    </span>
                                    <strong>Datenschutzerklärung:</strong>{" "}
                                    <a href="https://policies.google.com/privacy" target="_blank">
                                        https://policies.google.com/privacy
                                    </a>
                                    ; <strong>Grundlage Drittlandübermittlung:</strong>{" "}
                                    <span className=""> EU-US Data Privacy Framework (DPF)</span>.{" "}
                                    <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>{" "}
                                    <a href="https://adssettings.google.com/authenticated" target="_blank">
                                        https://adssettings.google.com/authenticated
                                    </a>
                                    .
                                </li>
                            </ul>
                            <h2 id="m42">Begriffsdefinitionen</h2>
                            <p>
                                In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
                                Datenschutzerklärung verwendeten Begrifflichkeiten. Soweit die
                                Begrifflichkeiten gesetzlich definiert sind, gelten deren gesetzliche
                                Definitionen. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem
                                Verständnis dienen.
                            </p>
                            <ul className="glossary">
                                <li>
                                    <strong>Bonitätsauskunft:</strong> Automatisierte Entscheidungen beruhen
                                    auf einer automatischen Datenverarbeitung ohne menschliches Zutun (z.B. im
                                    Fall einer automatische Ablehnung eines Kaufs auf Rechnung, eines
                                    Online-Kreditantrags oder ein Online-Bewerbungsverfahren ohne jegliches
                                    menschliche Eingreifen). Derartige automatisierten Entscheidungen sind
                                    nach Art. 22 DSGVO nur zulässig, wenn Betroffene einwilligen, wenn sie für
                                    eine Vertragserfüllung erforderlich sind oder wenn nationale Gesetze diese
                                    Entscheidungen erlauben.{" "}
                                </li>
                                <li>
                                    <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind
                                    alle Informationen, die sich auf eine identifizierte oder identifizierbare
                                    natürliche Person (im Folgenden "betroffene Person“) beziehen; als
                                    identifizierbar wird eine natürliche Person angesehen, die direkt oder
                                    indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
                                    zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B.
                                    Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert
                                    werden kann, die Ausdruck der physischen, physiologischen, genetischen,
                                    psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser
                                    natürlichen Person sind.{" "}
                                </li>
                                <li>
                                    <strong>Profile mit nutzerbezogenen Informationen:</strong> Die
                                    Verarbeitung von "Profilen mit nutzerbezogenen Informationen", bzw. kurz
                                    "Profilen" umfasst jede Art der automatisierten Verarbeitung
                                    personenbezogener Daten, die darin besteht, dass diese personenbezogenen
                                    Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf
                                    eine natürliche Person beziehen (je nach Art der Profilbildung können dazu
                                    unterschiedliche Informationen betreffend die Demographie, Verhalten und
                                    Interessen, wie z.B. die Interaktion mit Webseiten und deren Inhalten,
                                    etc.) zu analysieren, zu bewerten oder, um sie vorherzusagen (z.B. die
                                    Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf
                                    einer Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings werden
                                    häufig Cookies und Web-Beacons eingesetzt.{" "}
                                </li>
                                <li>
                                    <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web
                                    Analytics bezeichnet) dient der Auswertung der Besucherströme eines
                                    Onlineangebotes und kann das Verhalten oder Interessen der Besucher an
                                    bestimmten Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit
                                    Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu
                                    welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie
                                    sich interessieren. Dadurch können sie z.B. die Inhalte der Webseite
                                    besser an die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der
                                    Reichweitenanalyse werden häufig pseudonyme Cookies und Web-Beacons
                                    eingesetzt, um wiederkehrende Besucher zu erkennen und so genauere
                                    Analysen zur Nutzung eines Onlineangebotes zu erhalten.{" "}
                                </li>
                                <li>
                                    <strong>Tracking:</strong> Vom "Tracking“ spricht man, wenn das Verhalten
                                    von Nutzern über mehrere Onlineangebote hinweg nachvollzogen werden kann.
                                    Im Regelfall werden im Hinblick auf die genutzten Onlineangebote
                                    Verhaltens- und Interessensinformationen in Cookies oder auf Servern der
                                    Anbieter der Trackingtechnologien gespeichert (sogenanntes Profiling).
                                    Diese Informationen können anschließend z.B. eingesetzt werden, um den
                                    Nutzern Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen
                                    entsprechen.{" "}
                                </li>
                                <li>
                                    <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
                                    natürliche oder juristische Person, Behörde, Einrichtung oder andere
                                    Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel
                                    der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.{" "}
                                </li>
                                <li>
                                    <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne
                                    Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
                                    Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff
                                    reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das
                                    Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.{" "}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default DataProtection;