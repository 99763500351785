import React, { useState, useEffect } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import LoginInfo from "../components/LoginScreen/LoginInfo";

const tabs = {
  1: LoginInfo,
};

const Login = ({ setShowLogin, showLogin }) => {
  const [activeTab, setActiveTab] = useState(1);
  const Tab = tabs[activeTab];
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const checkMobileAndWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', checkMobileAndWidth);

    return () => {
      window.removeEventListener('resize', checkMobileAndWidth);
    };
  }, []);

  return (
    <>
      {windowWidth <= 1024 && (
        <div className="bg-[#021634] pt-12">
          <div className="px-[22px] py-6 bg-white min-h-[95vh] rounded-tl-3xl rounded-tr-3xl">
            <div>
              <div
                className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer "
                onClick={() => (showLogin ? setShowLogin(false) : navigate("/"))}
              >
                <AiOutlineLeft className="text-[352F48] text-lg" />
              </div>
            </div>

            <div>
              <h2 className="py-8 text-dark font-semibold text-2xl">
                {activeTab === 1 ? "Anmelden bei EasyCreditor" : "Weitere Informationen"}
              </h2>

              <Tab setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>
      )}

      {windowWidth > 1024 && (
        <div className="pt-10">

          <div
            className="ml-5 w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer "
            onClick={() => (showLogin ? setShowLogin(false) : navigate("/"))}
          >
            <AiOutlineLeft className="text-[352F48] text-lg" />
          </div>

          <div className="flex flex-col items-center w-full">
            <h2 className="py-10 text-dark font-semibold text-2xl">
              {activeTab === 1 ? "Anmelden bei EasyCreditor" : "Weitere Informationen"}
            </h2>

            <Tab setActiveTab={setActiveTab} />
          </div>

        </div>
      )}
    </>
  );
};

export default Login;
