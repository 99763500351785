import React from "react";
import { BsArrowRight } from "react-icons/bs";

const Button = ({ notIcon, className, type, children, ...rest }) => {
  
  return type === "warning" ? (
    <button
      className="flex items-center justify-center w-full py-4 bg-red-800 rounded-full text-white text-base font-medium gap-2 transition-all hover:gap-5"
      {...rest}
    >
      {children} {!notIcon && <BsArrowRight className="font-semibold" />}
    </button>
  ) : (
    <button
      className={`flex items-center justify-center w-full py-4 bg-primary rounded-full text-white text-base font-medium gap-2 transition-all hover:gap-5 ${className}`}
      {...rest}
    >
      {children} {!notIcon && <BsArrowRight className="font-semibold" />}
    </button>
  );
};

export default Button;
