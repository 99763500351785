import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useGetUserQuery } from "../features/user/userApi";

const Impressum = () => {
    const navigate = useNavigate();
    const user = useGetUserQuery();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const checkMobileAndWidth = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', checkMobileAndWidth);

        return () => {
            window.removeEventListener('resize', checkMobileAndWidth);
        };
    }, []);

    return (
        <div>
            {windowWidth <= 850 && (
                <div>
                    <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center my-5">
                        <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer ml-5">
                            <AiOutlineLeft
                                onClick={() => {
                                    if (user.status === 'rejected') {
                                        navigate("/")
                                    } else {
                                        navigate("/home")
                                    }
                                }}
                                className="text-[352F48] text-lg"
                            />
                        </div>
                        <h1 className="text-lg font-medium m-auto">Impressum</h1>
                        <div></div>
                    </div>
                    <div className="flex justify-center text-sm">

                        <div className="px-[22px]">
                            <p className="font-medium">EasyCreditor.com ist ein Angebot der:</p>
                            <p>EasyCreditor UG (haftungsbeschränkt)</p>
                            <p>Vertreten durch: Louis Karakas (Geschäftsführer)</p>
                            <p>Scheffelstraße 2</p>
                            <p>73252 Lenningen</p>
                            <p>E-Mail: louiskarakas.bw@gmail.com</p>
                            <br></br>
                            <p>Eingetragen beim Amtsgericht Nürtingen, HRB ...</p>
                            <p>Umsatzsteuer-ID: DE ...</p>
                            <p>Steuernummer: ...</p>
                            <p>WEEE-Reg.-Nr. DE /WEEG-Reg.-No GER: ...</p>
                            <br></br>
                            <p className="text-sm">Verantwortlicher für journalistisch-redaktionelle Inhalte: Louis Karakas</p>
                            <br></br>
                            <p className="font-medium">Rechtliche Hinweise</p>
                            <br></br>
                            <Link to='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage'>
                                <p className="text-primary">Link zur Plattform der Europäischen Kommission</p>
                            </Link>
                            <p>gemäß der Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.</p>

                            <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                            <br></br>
                            <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>

                        </div>
                    </div>
                </div>

            )}

            {windowWidth > 850 && (
                <div className="text-xs flex flex-col gap-10">
                    <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center my-5">
                        <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer ml-5">
                            <AiOutlineLeft
                                onClick={() => {
                                    if (user.status === 'rejected') {
                                        navigate("/")
                                    } else {
                                        navigate("/home")
                                    }
                                }}
                                className="text-[352F48] text-lg"
                            />
                        </div>
                        <h1 className="text-lg font-medium m-auto">Impressum</h1>
                        <div></div>
                    </div>


                    <div className="flex justify-center text-sm">

                        <div className="w-1/2">
                            <p className="font-medium">EasyCreditor.com ist ein Angebot der:</p>
                            <p>EasyCreditor UG (haftungsbeschränkt)</p>
                            <p>Vertreten durch: Louis Karakas (Geschäftsführer)</p>
                            <p>Scheffelstraße 2</p>
                            <p>73252 Lenningen</p>
                            <p>E-Mail: louiskarakas.bw@gmail.com</p>
                            <br></br>
                            <p>Eingetragen beim Amtsgericht Nürtingen, HRB ...</p>
                            <p>Umsatzsteuer-ID: DE ...</p>
                            <p>Steuernummer: ...</p>
                            <p>WEEE-Reg.-Nr. DE /WEEG-Reg.-No GER: ...</p>
                            <br></br>
                            <p className="text-sm">Verantwortlicher für journalistisch-redaktionelle Inhalte: Louis Karakas</p>
                            <br></br>
                            <p className="font-medium">Rechtliche Hinweise</p>
                            <br></br>
                            <Link to='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage'>
                                <p className="text-primary">Link zur Plattform der Europäischen Kommission</p>
                            </Link>
                            <p>gemäß der Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.</p>

                            <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                            <br></br>
                            <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>

                        </div>
                    </div>
                </div>
            )}
        </div>


    );
}

export default Impressum;