import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRegisterMutation } from "../../features/auth/authApi";
import Button from "../utilities/Button";
import Input from "../utilities/Input";

const MoreInfo = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [residence, setResidence] = useState("");
  const [errors, setErrors] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // use for navigate
  const navigate = useNavigate();

  // get previous register data from redux store
  const { regUser } = useSelector((state) => state.auth);

  // create new user
  const [register, { data: newUser }] = useRegisterMutation();

  useEffect(() => {
    if (newUser?._id) {
      toast.success("Neues Konto wurde erfolgreich erstellt.");
      setErrors({});
      navigate("/login");
    }
  }, [navigate, newUser]);

  useEffect(() => {
    const checkMobileAndWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', checkMobileAndWidth);

    return () => {
      window.removeEventListener('resize', checkMobileAndWidth);
    };
  }, []);


  // submit handler
  const submitHandler = () => {
    // check validation
    const validationErrors = {};

    if (!firstName) {
      validationErrors.firstName = "Bitte Vornamen eintragen.";
    } else if (!/^[a-zA-ZäöüÄÖÜß]+$/.test(firstName)) {
      validationErrors.firstName = "Vorname darf keine Zahlen oder Sonderzeichen enthalten.";
    }

    if (!lastName) {
      validationErrors.lastName = "Bitte Nachnamen eintragen.";
    } else if (!/^[a-zA-ZäöüÄÖÜß]+$/.test(lastName)) {
      validationErrors.lastName = "Nachname darf keine Zahlen oder Sonderzeichen enthalten.";
    }

    if (!birthdate) {
      validationErrors.birthdate = "Bitte Geburtsdatum eintragen.";
    } else {
      let today = new Date();
      let birth = new Date(birthdate);
      let age = today.getFullYear() - birth.getFullYear();
      const monthDiff = today.getMonth() - birth.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
        age--;
      }
      if (age < 18) {
        validationErrors.birthdate = "Sie müssen mindestens 18 Jahre alt sein, um sich anzumelden.";
      }
    }

    if (!street) {
      validationErrors.street = "Bitte Straße eintragen.";
    }

    if (!zipCode) {
      validationErrors.zipCode = "Bitte Postleitzahl eintragen.";
    }

    if (!residence) {
      validationErrors.residence = "Bitte Wohnort eintragen.";
    }

    if (Object.keys(validationErrors)?.length > 0) {
      return setErrors(validationErrors);
    }

    register({
      ...regUser,
      firstName,
      lastName,
      birthdate,
      street,
      zipCode,
      residence,
    });
  };

  return (
    <div className={windowWidth <= 1024 ? "w-full" : "w-1/3"}>
      <div className="flex flex-col gap-5">

        <div>
          <Input
            id="vorname"
            type="text"
            label="Vorname"
            placeholder="Vorname eingeben."
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          {errors?.firstName && (
            <p className="text-red-600">{errors?.firstName}</p>
          )}
        </div>

        <div>
          <Input
            id="nachname"
            type="text"
            label="Nachname"
            placeholder="Nachname eingeben."
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          {errors?.lastName && (
            <p className="text-red-600">{errors?.lastName}</p>
          )}
        </div>

        <div>
          <Input
            id="datum"
            type="date"
            label="Geburtsdatum"
            placeholder="Geburtsdatum eingeben."
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
          />
          {errors?.birthdate && (
            <p className="text-red-600">{errors?.birthdate}</p>
          )}
        </div>

        <div>
          <Input
            id="strasse"
            type="text"
            label="Straße"
            placeholder="Straße & Hausnummer eingeben."
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
          {errors?.street && (
            <p className="text-red-600">{errors?.street}</p>
          )}
        </div>

        <div>
          <Input
            id="plz"
            type="text"
            label="Postleitzahl"
            placeholder="Postleitzahl eingeben."
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
          {errors?.zipCode && (
            <p className="text-red-600">{errors?.zipCode}</p>
          )}
        </div>

        <div>
          <Input
            id="wohnort"
            type="text"
            label="Wohnort"
            placeholder="Wohnort eingeben."
            value={residence}
            onChange={(e) => setResidence(e.target.value)}
          />
          {errors?.residence && (
            <p className="text-red-600">{errors?.residence}</p>
          )}
        </div>

      </div>
      <div className="mt-10">
        <Button onClick={submitHandler}>Anmelden</Button>
      </div>
    </div>
  );
};

export default MoreInfo;
