import React from "react";

const Banner = ({ title, heading }) => {
  return (
    <div
      style={{
        backgroundImage: `url('/images/home-bg.png')`,
        backgroundRepeat: "no-repeat",
        width: "100%",
        backgroundPosition: "center",
      }}
      className="min-h-[232px] w-full flex flex-col justify-center items-center"
    >
      <h4 className="text-[#B5CBE1] text-base font-medium">{title}</h4>
      <h2 className="font-semibold text-[41px] text-white">{heading}</h2>
    </div>
  );
};

export default Banner;
