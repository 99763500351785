import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Transfer = () => {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col justify-center m-5">

            <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center">
                <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
                    <AiOutlineLeft
                        onClick={() => navigate("/home")}
                        className="text-[352F48] text-lg"
                    />
                </div>
                <h1 className="font-medium ml-auto">Geld einzahlen oder abbuchen</h1>
                <div></div>
            </div>

            <div className="flex flex-col items-center">
                <div className="flex flex-col gap-5 mt-5">
                    <h1 className="font-bold text-md">Geld abbuchen</h1>
                    <Link to="/pay-out">
                        <div className="flex items-center space-x-5 p-5 border transition-all shadow-md rounded-lg hover:border-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 text-primary">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                            </svg>
                            <div>
                                <h1 className="font-bold text-lg">Geld abbuchen</h1>
                                <p className="font-light text-sm" >Buchen Sie Geld von Ihrem EasyCreditor-Konto auf Ihr Bankkonto ab.</p>
                            </div>
                        </div>
                    </Link>

                    <h1 className="font-bold text-md">Geld einzahlen</h1>
                    <Link to="/deposit">
                        <div className="flex items-center space-x-5 p-5 border transition-all shadow-md rounded-lg hover:border-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 text-primary">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                            </svg>

                            <div>
                                <h1 className="font-bold text-lg">Geld einzahlen</h1>
                                <p className="font-light text-sm" >Gönnen Sie sich etwas mit dem Geld oder legen Sie es für Notfälle zur Seite.</p>
                            </div>
                        </div>
                    </Link>

                    <Link>
                        <div className="flex items-center space-x-5 p-5 border transition-all shadow-md rounded-lg hover:border-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 text-primary">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                            </svg>

                            <div>
                                <h1 className="font-bold text-lg">Geld einzahlen mit giropay</h1>
                                <p className="font-light text-sm" >Loggen Sie sich bei giropay ein und zahlen Sie Geld auf Ihr EasyCreditor-Konto ein. (Bald verfügbar)</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>

    );
}

export default Transfer;