import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import InstallmentDetails from "../components/Invoice/InvoiceDetailsInstallment";
import { useGetInstallmentQuery } from "../features/invoice/installmentApi";
import { setInstallment } from "../features/invoice/installmentSlice";

const InstallmentSummary = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data } = useGetInstallmentQuery(id);

  useEffect(() => {
    if (data?._id) {
      dispatch(
        setInstallment({
          ...data,
          senderUser: data?.sender,
          receiverUser: data?.receiver,
        })
      );
    }
  }, [data, dispatch]);
  
  return <InstallmentDetails summary={true} isBack={"/"} />;
};

export default InstallmentSummary;
