import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import InvoiceDetails from "../components/Invoice/InvoiceDetails";
import { useGetInvoiceQuery } from "../features/invoice/invoiceApi";
import { setInvoice } from "../features/invoice/invoiceSlice";

const InvoiceSummary = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data } = useGetInvoiceQuery(id);

  useEffect(() => {
    if (data?._id) {
      dispatch(
        setInvoice({
          ...data,
          senderUser: data?.sender,
          receiverUser: data?.receiver,
        })
      );
    }
  }, [data, dispatch]);
  
  return <InvoiceDetails summary={true} isBack={"/"} />;
};

export default InvoiceSummary;
