import React from "react";
import { useGetUserQuery } from "../features/user/userApi";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Deposit = () => {
    const navigate = useNavigate();
    const user = useGetUserQuery();

    return (
        <div className="flex flex-col gap-10 m-5">

            <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center">
                <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
                    <AiOutlineLeft
                        onClick={() => navigate("/transfer")}
                        className="text-[352F48] text-lg"
                    />
                </div>

                <h1 className="text-lg font-medium ml-auto">Abbuchungsbetrag</h1>
                <div></div>
            </div>

            <div className="flex flex-col gap-5 items-center">

                <div className="mb-5">
                    <h1 className="font-bold text-2xl text-dark mb-2">Tätige eine Überweisung</h1>
                    <h3 className="text-sm text-light">Überweise Geld auf dein EasyCreditor Verrechnungskonto.</h3>
                </div>

                <h2 className="font-bold text-xl text-dark mb-2">Überweise auf dieses Konto</h2>



                <div class="divide-y">
                    <div className="flex flex-col gap-3">
                        <div>
                            <p className="text-light text-sm font-medium">Name</p>
                            <p className="text-dark font-bold text-sm">{user.data?.firstName + " " + user.data?.lastName}</p>
                        </div>
                        <div>
                            <p className="text-light text-sm font-medium">IBAN</p>
                            <p className="text-dark font-bold text-sm">DE10 6005 0101 7006 3950 42</p>
                        </div>
                        <div>
                            <p className="text-light text-sm font-medium">BIC</p>
                            <p className="text-dark font-bold text-sm">SOLADEST600</p>
                        </div>
                        <div>
                            <p className="text-light text-sm font-medium">Verwendungszweck</p>
                            <p className="text-dark font-bold text-sm">{user.data?.paymentReference}</p>
                        </div>

                        <p className="text-light text-sm mt-5">Dein Geld befindet sich auf einem Konto bei Deutsche Bank AG</p>
                    </div>

                    <div className="flex flex-col gap-5 mt-10">
                        <h2 className="font-bold text-lg text-dark mt-10">Nicht vergessen</h2>
                        <div>
                            <h3 className="font-bold text-dark">Gib deinen Verwendungszweck an.</h3>
                            <p className="text-light text-sm mt-2">Einzahlungen ohne Verwendungszweck können nicht akzeptiert werden.</p>
                            <p className="text-light text-sm">Dein Geld kommt normalerweise inerhalb von 2 Bankarbeitstagen an.</p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Deposit;
