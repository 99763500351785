import React, { useState, useEffect } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import MoreInfo from "../components/RegisterScreen/MoreInfo";
import RegisterInfo from "../components/RegisterScreen/RegisterInfo";

const tabs = {
  1: RegisterInfo,
  2: MoreInfo,
};

const Register = ({ showRegister, setShowRegister }) => {
  const [activeTab, setActiveTab] = useState(1);
  const Tab = tabs[activeTab];
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const checkMobileAndWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', checkMobileAndWidth);

    return () => {
      window.removeEventListener('resize', checkMobileAndWidth);
    };
  }, []);

  return (
    <div>
      {windowWidth <= 1024 && (

        <div className="bg-[#021634] pt-12">
          <div className="bg-white px-[22px] rounded-tl-3xl rounded-tr-3xl py-6 min-h-[95vh] pb-10">
            <div>
              <div
                className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer"
                onClick={() => {
                  if (activeTab === 2) {
                    setActiveTab(1);
                  } else {
                    showRegister ? setShowRegister(false) : setActiveTab((prev) => {
                      return prev === 2 ? prev - 1 : navigate("/");
                    });
                  }
                }}
              >
                <AiOutlineLeft className="text-[352F48] text-lg" />
              </div>
            </div>

            <div className="flex flex-col items-center w-full">
              <h2 className="py-10 text-dark font-semibold text-2xl">
                {activeTab === 1
                  ? "Werde heute noch flexibler!"
                  : "Weitere Informationen"}
              </h2>

              <Tab setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>
      )}

      {windowWidth > 1024 && (
        <div className="pt-10">

          <div>
            <div
              className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer ml-5"
              onClick={() => {
                if (activeTab === 2) {
                  setActiveTab(1);
                } else {
                  showRegister ? setShowRegister(false) : setActiveTab((prev) => {
                    return prev === 2 ? prev - 1 : navigate("/");
                  });
                }
              }}
            >
              <AiOutlineLeft className="text-[352F48] text-lg" />
            </div>
          </div>


          <div className="flex flex-col items-center w-full">
            <h2 className="py-10 text-dark font-semibold text-2xl">
              {activeTab === 1
                ? "Werde heute noch flexibler!"
                : "Weitere Informationen"}
            </h2>
            <Tab setActiveTab={setActiveTab} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
