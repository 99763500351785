import React, { useState, useEffect } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Help = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        const checkMobileAndWidth = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', checkMobileAndWidth);

        return () => {
            window.removeEventListener('resize', checkMobileAndWidth);
        };
    }, []);


    return (
        <div>
            {windowWidth <= 850 && (
                <div>
                    <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center p-5">
                        <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
                            <AiOutlineLeft
                                onClick={() => navigate("/home")}
                                className="text-[352F48] text-lg"
                            />
                        </div>
                        <h1 className="font-medium text-center m-auto">Hilfe-Center – Privatkonto</h1>
                    </div>

                    <div className="px-[22px]">
                        <h1 className="font-medium text-xl">Häufige Fragen</h1>
                        <br></br>
                        <div className="flex flex-col gap-10">

                            <div className="flex flex-col">
                                <p className="text-primary font-bold mb-3">Wie kann ich Geld von mein Bankkonto auf mein EasyCreditor-Guthaben einzahlen?</p>
                                <div className="flex flex-col gap-5">
                                    <p>So zahlen Sie Geld von einem Bankkonto auf Ihr EasyCreditor-Konto ein:</p>

                                    <div>
                                        <p>1. Rufen Sie Ihr Geldbörse auf.</p>
                                        <p>2. Klicken Sie auf Geld einzahlen oder abbuchen.</p>
                                        <p>3. Klicken Sie auf Geld einzahlen mit Giropay oder Geld einzahlen.</p>
                                        <p>4. Folgen Sie der Anleitung.</p>
                                    </div>

                                    <p>Es kann bis zu drei Werktage dauern, um Geld von Ihrem Bankkonto auf Ihr EasyCreditor-Konto einzuzahlen.</p>

                                    <p>Mit giropay werden Einzahlungen auf Ihr EasyCreditor-Konto sofort abgewickelt.</p>

                                    <p>Sie sollten Geld auf Ihr EasyCreditor-Konto immer in der Währung einzahlen, in der es geführt wird.</p>

                                    <p>Wenn Sie Geld in einer Fremdwährung einzahlen möchten, wird das Geld automatisch in unserem System in Ihre lokale Währung umgerechnet. Wenn alle Informationen, die für den Abgleich zwischen Ihrer Einzahlung und Ihrem EasyCreditor-Konto erforderlich sind, in den Überweisungsdetails angegeben sind, werden wir den umgerechneten Betrag Ihrem EasyCreditor-Konto gutschreiben.</p>

                                    <p>Wenn Ihre Bank jedoch nicht alle erforderlichen Angaben bei der Überweisung gemacht hat, könnte das Geld nicht eingezahlt werden. In diesem Fall wird das Geld wieder auf Ihr Bankkonto zurückgebucht.</p>
                                </div>
                            </div>

                            <div>
                                <p className="text-primary font-bold mb-3">Ich habe mein Passwort vergessen. Wie kann ich es zurücksetzen?</p>
                                <div className="flex flex-col gap-5">
                                    <p>So setzen Sie Ihr Passwort im Webbrowser zurück:</p>

                                    <div>
                                        <p>1. Gehen Sie zu unserem Login.</p>
                                        <p>2. Klicken Sie auf Passwort vergessen?</p>
                                        <p>3. Geben Sie die E-Mail-Adresse ein, die Sie für EasyCreditor verwenden, und klicken Sie auf Weiter.</p>
                                        <p>4. Wählen Sie die Art der Sicherheitsüberprüfung aus und klicken Sie auf Weiter.</p>
                                        <p>5. Sobald Sie die Sicherheitsüberprüfung abgeschlossen haben, werden Sie aufgefordert, ein neues Passwort zu erstellen.</p>
                                    </div>


                                </div>
                            </div>

                            <div>
                                <p className="text-primary font-bold mb-3">Wie melde ich ein Problem mit einem Verkäufer? (Bald verfügbar)</p>
                                <div className="flex flex-col gap-5">
                                    <p>Mit dem Verkäufer Kontakt aufzunehmen, ist häufig der beste Weg, um ein Problem zu lösen. Die Kontaktdaten finden Sie, indem Sie die Aktivitäten aufrufen und die Zahlung auswählen. Er kann möglicherweise eine Rückzahlung veranlassen oder bei der Rücksendung behilflich sein.</p>

                                    <p>Sie können innerhalb von 180 Tagen nach dem Zahlungsdatum ein Problem melden.</p>

                                    <p>So melden Sie ein Problem in der App:</p>

                                    <div>
                                        <p>1. Klicken Sie unter Konfliktlösungen auf Problem melden.</p>
                                        <p>2. Wählen Sie die Zahlung aus und klicken Sie auf Weiter.</p>
                                        <p>3. Wählen Sie den Grund für den Konflikt aus, z.B.:</p>
                                        <div className="my-3">
                                            <p>• Probleme mit Ihrem Einkauf.</p>
                                            <p>• Probleme mit dem Verkäufer.</p>
                                            <p>• Probleme mit Abrechnungen oder Abonnements.</p>
                                            <p>• Unbefugter Zugriff auf Ihr EasyCreditor-Konto.</p>
                                        </div>
                                        <p>4. Wählen Sie die für das Problem zutreffendste Option aus und klicken Sie auf Weiter.</p>
                                    </div>

                                    <p>So melden Sie in der App ein Problem:</p>

                                    <div>
                                        <p>1. Gehen Sie zu Letzte Aktivitäten und tippen Sie auf die Transaktion, die Sie melden möchten.</p>
                                        <p>2. Scrollen Sie nach unten und tippen Sie auf Problem melden.</p>
                                        <p>3. Geben Sie die Art des Problems und alle relevanten Details an und tippen Sie auf Senden.</p>
                                    </div>

                                    <p>In einem Konflikt können Sie in den Konfliktlösungen Nachrichten mit der anderen Partei austauschen und versuchen, das Problem zu lösen.</p>

                                    <p>Wenn Sie den Konflikt nicht lösen können, können Sie einen Antrag auf Käuferschutz stellen. Um einen Konflikt zu eskalieren, verlangen wir oft, dass seit dem Zahlungsdatum mindestens sieben Tage vergangen sind.</p>

                                    <p>Mit einem Antrag auf Käuferschutz bitten Sie EasyCreditor, das Problem zu untersuchen und eine Entscheidung zu treffen. Ein Konflikt wird nach 20 Tagen automatisch geschlossen, es sei denn, es wurde ein Antrag auf Käuferschutz gestellt. Geschlossene Konflikte können nicht wieder aufgenommen werden, und es kann auch kein Antrag auf Käuferschutz mehr gestellt werden.</p>

                                </div>
                            </div>

                        </div>
                    </div>



                </div>
            )}

            {windowWidth > 850 && (
                <div className="mx-40">
                    <h1 className="font-medium text-lg text-center">Hilfe-Center – Privatkonto</h1>
                    <br></br>
                    <h1 className="font-medium text-xl">Häufige Fragen</h1>
                    <br></br>
                    <div className="flex flex-col gap-10">

                        <div className="flex flex-col">
                            <p className="text-primary font-bold mb-3">Wie kann ich Geld von mein Bankkonto auf mein EasyCreditor-Guthaben einzahlen?</p>
                            <div className="flex flex-col gap-5">
                                <p>So zahlen Sie Geld von einem Bankkonto auf Ihr EasyCreditor-Konto ein:</p>

                                <div>
                                    <p>1. Rufen Sie Ihr Geldbörse auf.</p>
                                    <p>2. Klicken Sie auf Geld einzahlen oder abbuchen.</p>
                                    <p>3. Klicken Sie auf Geld einzahlen mit Giropay oder Geld einzahlen.</p>
                                    <p>4. Folgen Sie der Anleitung.</p>
                                </div>

                                <p>Es kann bis zu drei Werktage dauern, um Geld von Ihrem Bankkonto auf Ihr EasyCreditor-Konto einzuzahlen.</p>

                                <p>Mit giropay werden Einzahlungen auf Ihr EasyCreditor-Konto sofort abgewickelt.</p>

                                <p>Sie sollten Geld auf Ihr EasyCreditor-Konto immer in der Währung einzahlen, in der es geführt wird.</p>

                                <p>Wenn Sie Geld in einer Fremdwährung einzahlen möchten, wird das Geld automatisch in unserem System in Ihre lokale Währung umgerechnet. Wenn alle Informationen, die für den Abgleich zwischen Ihrer Einzahlung und Ihrem EasyCreditor-Konto erforderlich sind, in den Überweisungsdetails angegeben sind, werden wir den umgerechneten Betrag Ihrem EasyCreditor-Konto gutschreiben.</p>

                                <p>Wenn Ihre Bank jedoch nicht alle erforderlichen Angaben bei der Überweisung gemacht hat, könnte das Geld nicht eingezahlt werden. In diesem Fall wird das Geld wieder auf Ihr Bankkonto zurückgebucht.</p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary font-bold mb-3">Ich habe mein Passwort vergessen. Wie kann ich es zurücksetzen?</p>
                            <div className="flex flex-col gap-5">
                                <p>So setzen Sie Ihr Passwort im Webbrowser zurück:</p>

                                <div>
                                    <p>1. Gehen Sie zu unserem Login.</p>
                                    <p>2. Klicken Sie auf Passwort vergessen?</p>
                                    <p>3. Geben Sie die E-Mail-Adresse ein, die Sie für EasyCreditor verwenden, und klicken Sie auf Weiter.</p>
                                    <p>4. Wählen Sie die Art der Sicherheitsüberprüfung aus und klicken Sie auf Weiter.</p>
                                    <p>5. Sobald Sie die Sicherheitsüberprüfung abgeschlossen haben, werden Sie aufgefordert, ein neues Passwort zu erstellen.</p>
                                </div>


                            </div>
                        </div>

                        <div>
                            <p className="text-primary font-bold mb-3">Wie melde ich ein Problem mit einem Verkäufer? (Bald verfügbar)</p>
                            <div className="flex flex-col gap-5">
                                <p>Mit dem Verkäufer Kontakt aufzunehmen, ist häufig der beste Weg, um ein Problem zu lösen. Die Kontaktdaten finden Sie, indem Sie die Aktivitäten aufrufen und die Zahlung auswählen. Er kann möglicherweise eine Rückzahlung veranlassen oder bei der Rücksendung behilflich sein.</p>

                                <p>Sie können innerhalb von 180 Tagen nach dem Zahlungsdatum ein Problem melden.</p>

                                <p>So melden Sie ein Problem in der App:</p>

                                <div>
                                    <p>1. Klicken Sie unter Konfliktlösungen auf Problem melden.</p>
                                    <p>2. Wählen Sie die Zahlung aus und klicken Sie auf Weiter.</p>
                                    <p>3. Wählen Sie den Grund für den Konflikt aus, z.B.:</p>
                                    <div className="my-3">
                                        <p>• Probleme mit Ihrem Einkauf.</p>
                                        <p>• Probleme mit dem Verkäufer.</p>
                                        <p>• Probleme mit Abrechnungen oder Abonnements.</p>
                                        <p>• Unbefugter Zugriff auf Ihr EasyCreditor-Konto.</p>
                                    </div>
                                    <p>4. Wählen Sie die für das Problem zutreffendste Option aus und klicken Sie auf Weiter.</p>
                                </div>

                                <p>So melden Sie in der App ein Problem:</p>

                                <div>
                                    <p>1. Gehen Sie zu Letzte Aktivitäten und tippen Sie auf die Transaktion, die Sie melden möchten.</p>
                                    <p>2. Scrollen Sie nach unten und tippen Sie auf Problem melden.</p>
                                    <p>3. Geben Sie die Art des Problems und alle relevanten Details an und tippen Sie auf Senden.</p>
                                </div>

                                <p>In einem Konflikt können Sie in den Konfliktlösungen Nachrichten mit der anderen Partei austauschen und versuchen, das Problem zu lösen.</p>

                                <p>Wenn Sie den Konflikt nicht lösen können, können Sie einen Antrag auf Käuferschutz stellen. Um einen Konflikt zu eskalieren, verlangen wir oft, dass seit dem Zahlungsdatum mindestens sieben Tage vergangen sind.</p>

                                <p>Mit einem Antrag auf Käuferschutz bitten Sie EasyCreditor, das Problem zu untersuchen und eine Entscheidung zu treffen. Ein Konflikt wird nach 20 Tagen automatisch geschlossen, es sei denn, es wurde ein Antrag auf Käuferschutz gestellt. Geschlossene Konflikte können nicht wieder aufgenommen werden, und es kann auch kein Antrag auf Käuferschutz mehr gestellt werden.</p>

                            </div>
                        </div>

                    </div>

                </div>
            )}
        </div>

    );
}
export default Help;