import React, { useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
/* import MyAccordion from "../components/utilities/MyAccordion"; */

const PayOut = () => {

    const navigate = useNavigate();
    const [auszahlungsBetrag, setAuszahlungsBetrag] = useState(0);

    return (
        <div className="flex flex-col gap-10">
            <div className="flex items-center justify-between px-[22px] py-6 bg-white shadow-sm pb-10">
                <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
                    <AiOutlineLeft
                        onClick={() => navigate("/home")}
                        className="text-[352F48] text-lg"
                    />
                </div>
                <div className="mr-28">
                    <h3 className="font-bold text-lg text-dark">Über EasyCreditor</h3>
                </div>
            </div>

            <div className="flex flex-col gap-5 mx-5">

                <p>Willkommen bei EasyCreditor, dem Vorreiter in der Welt der Kreditvergabe. Wir sind ein neuartiges und innovatives Unternehmen, das die Kreditvergabe grundlegend verändert hat, um sie einfacher und bequemer zu gestalten.</p>

                <p>EasyCreditor geht einen neuen Weg bei der Kreditvergabe. Wir haben die traditionellen Barrieren und komplizierten Prozesse abgebaut, die mit der Beantragung eines Kredits verbunden sind. Bei uns stehen die Bedürfnisse und die Bequemlichkeit der Kreditnehmer im Mittelpunkt.</p>

                <p>Mit EasyCreditor ist es einfach, einen Kredit zu erhalten. Die einzigen Voraussetzungen sind, dass Sie über 18 Jahre alt sind und keine Privatinsolvenz angemeldet haben. Mehr ist nicht nötig. Sie müssen keine zusätzlichen Dokumente einreichen oder komplizierte Antragsverfahren durchlaufen, wie es bei herkömmlichen Online-Kreditunternehmen der Fall ist.</p>

                <p>Wir glauben, dass jeder die Möglichkeit haben sollte, finanzielle Flexibilität zu erlangen, ohne durch unnötige Hürden springen zu müssen. Mit unserer benutzerfreundlichen Plattform können Sie Einzelzahlungen sowie Ratenzahlungen durchführen, ganz nach Ihren individuellen Bedürfnissen.</p>

                <p>EasyCreditor steht für eine einfache, unkomplizierte Kreditvergabe. Wir sind hier, um Ihnen zu helfen, Ihre finanziellen Ziele zu erreichen. Entdecken Sie eine neue Art der Kreditvergabe - einfach, bequem und unkompliziert. Willkommen bei EasyCreditor.</p>
                <br></br>

                {/* <MyAccordion
                    question="Was ist CPAY?"
                    answer="CPAY ist eine webbasierte Plattform, die es Privatpersonen ermöglicht, Geld zu senden und zu empfangen sowie Ratenkäufe durchzuführen. Wir arbeiten eng mit der Deutschen Bank zusammen, um eine sichere und zuverlässige Zahlungsabwicklung zu gewährleisten."
                />

                <MyAccordion
                    question="Wie funktioniert CPAY?"
                    answer="CPAY ist einfach zu nutzen. Melden Sie sich einfach auf unserer Plattform an und folgen Sie den Anweisungen, um Geld zu senden oder zu empfangen. Für Ratenkäufe bieten wir flexible Optionen an, die auf Ihre individuellen Bedürfnisse zugeschnitten sind."
                />

                <MyAccordion
                    question="Wie sicher ist CPAY?"
                    answer="Die Sicherheit unserer Plattform hat für uns höchste Priorität. Wir arbeiten eng mit der Deutschen Bank zusammen, um sicherzustellen, dass alle Transaktionen sicher und zuverlässig abgewickelt werden. Darüber hinaus setzen wir auf modernste Verschlüsselungstechnologie und andere Sicherheitsmaßnahmen, um die Sicherheit Ihrer Daten zu gewährleisten."
                />

                <MyAccordion
                    question="Welche Gebühren fallen bei CPAY an?"
                    answer="Wir sind bestrebt, transparente und faire Gebühren anzubieten. Die genauen Gebühren hängen von verschiedenen Faktoren ab, wie zum Beispiel dem Betrag der Transaktion und dem gewählten Zahlungsmittel. Weitere Informationen finden Sie auf unserer Gebührenseite."
                />

                <MyAccordion
                    question="Welche Zahlungsmethoden werden von CPAY akzeptiert?"
                    answer="Wir akzeptieren verschiedene Zahlungsmethoden, darunter Banküberweisungen, Kreditkarten und Debitkarten. Wir arbeiten ständig daran, unser Angebot an Zahlungsmethoden zu erweitern, um unseren Kunden die größtmögliche Auswahl zu bieten."
                />

                <MyAccordion
                    question="Wie lange dauern Transaktionen bei CPAY?"
                    answer="Die Dauer von Transaktionen hängt von verschiedenen Faktoren ab, wie zum Beispiel der gewählten Zahlungsmethode und dem Zielland. Wir bemühen uns jedoch, Transaktionen so schnell wie möglich abzuwickeln und halten Sie über den Fortschritt Ihrer Transaktion auf dem Laufenden."
                /> */}
            </div>
        </div >
    );
};

export default PayOut;
