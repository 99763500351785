import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/apiSlice";
import authReducer from "../features/auth/authSlice";
import invoiceReducer from "../features/invoice/invoiceSlice";
import invoice30Reducer from "../features/invoice/invoiceSlice30";
import installmentReducer from "../features/invoice/installmentSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    invoice: invoiceReducer,
    installment: installmentReducer,
    invoice30: invoice30Reducer
  },
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(apiSlice.middleware),
});