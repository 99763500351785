import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Button from "../components/utilities/Button";
import Login from "./Login";
import Register from "./Register";

const Welcome = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [text, setText] = useState('Einfachheit in jeder Rate');
  const [key, setKey] = useState(0);
  const [openPrivateCustomers, setOpenPrivateCustomers] = useState(false);

  useEffect(() => {
    const sentences = [
      'Einfachheit in jeder Rate',
      'Einfachheit in jeder Rate',
      'Einfachheit in jeder Rate',
      // Fügen Sie hier weitere Sätze hinzu...
    ];
    let index = 0;

    const intervalId = setInterval(() => {
      index = (index + 1) % sentences.length;
      setText(sentences[index]);
      setKey(prevKey => prevKey + 1); // Erhöht den Schlüssel, um das Element neu zu rendern
    }, 20000); // Ändert den Text alle 5 Sekunden

    // Bereinigt den Intervall-Timer, wenn die Komponente unmountet
    return () => clearInterval(intervalId);
  }, []);


  useEffect(() => {
    const checkMobileAndWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', checkMobileAndWidth);

    return () => {
      window.removeEventListener('resize', checkMobileAndWidth);
    };
  }, []);

  return (
    <div>
      {windowWidth <= 1024 && (
        <div>
          <div
            style={{ backgroundColor: "#021634" }}
            className="w-full min-h-[100vh] pb-10"
          >
            <div className="px-[22px] mx-auto pt-36 text-center">
              <Link to="/" className="flex justify-center">
                <img src="/images/logo2.svg" className="mx-auto" alt="logo" class="w-60" />
              </Link>

              <h2 className="typing-effect-mobile text-white font-bold text-2xl" key={key}>
                {text}
              </h2>


              <br></br>
              <br></br>
              <br></br>


              <div className="flex justify-center">
                <img src="./images/Mockup1.png" className="" alt="logo" class="min-w-[10%] max-w-[80%]" />
              </div>

              <div className="mt-[200px]">
                <div className="flex justify-center">
                  <Button onClick={() => setShowRegister(true)}>Registrieren</Button>
                </div>

                <div className="mt-8">
                  <p className="text-white font-medium text-base">
                    Sie haben bereits ein Konto?{" "}
                    <span
                      onClick={() => setShowLogin(true)}
                      className="text-primary cursor-pointer"
                    >
                      Anmelden
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {windowWidth >= 1024 && (
        <div style={{ backgroundColor: "#021634" }} className="w-full min-h-[100vh] pb-10">
          <div>
            <div className="flex justify-center items-end space-x-40 bg-white px-10 py-5 border-b-4 border-primary">
              <Link to="/">
                <img src="/images/logo1.svg" className="" alt="logo" class="min-w-[200px]" />
              </Link>

              <div className="flex space-x-10">
                <button onClick={() => setOpenPrivateCustomers(!openPrivateCustomers)} className="flex items-center space-x-1">
                  <h1 className="text-lg text-dark">Privatkunden</h1>
                  <svg fill="#021634" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path> </g></svg>
                </button>

                {/*                   <button className="flex items-center space-x-1">
                    <h1 className="text-lg text-dark">Geschäftskunden</h1>
                    <svg fill="#021634" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path> </g></svg>
                  </button> */}

                <button className="flex items-center space-x-1">
                  <h1 className="text-lg text-dark">Partner</h1>
                  <svg fill="#021634" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path> </g></svg>
                </button>
              </div>

              <div className="flex space-x-1">
                <Link to="/login" className="bg-white rounded-full border border-primary py-2 px-3 font-medium text-primary">Anmelden</Link>
                <Link to="/register" className="bg-primary rounded-full py-2 px-3 font-medium text-white">Registrieren</Link>
              </div>
            </div>

            <div
              className={`flex justify-center text-white bg-primary py-5 space-x-10 transition-all duration-300 ${openPrivateCustomers ? "" : "invisible"}`}>
              <Link>
                <p>EasyCreditor kennenlernen</p>
              </Link>

              <Link>
                <p>Geld senden</p>
              </Link>

              <Link>
                <p>Zahlungsmöglichkeiten</p>
              </Link>

            </div>
          </div>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>


          <h2 className="typing-effect text-white font-bold text-5xl" key={key}>
            {text}
          </h2>

          <div className="flex justify-center">
            <h1 className="text-white text-xl">Machen Sie Ihr Leben einfacher</h1>
          </div>
          <div className="flex justify-center mt-5">
            <Link to='/register' className="text-white font-bold bg-primary rounded-full py-4 px-20">Jetzt loslegen</Link>
          </div>

          <br></br>
          <br></br>

          <div className="flex justify-center">
            <img src="./images/Mockup1.png" className="" alt="logo" class="min-w-[10%] max-w-[50%]" />
          </div>

          <br></br>

        </div>
      )}


      {windowWidth <= 1024 && (
        <>
          <div
            className={`transition-all duration-300 fixed left-[50%] -translate-x-[50%] ${showLogin ? "bottom-0" : "-bottom-[120%]"
              } right-0 h-full overflow-y-scroll w-full`}
          >
            <Login showLogin={showLogin} setShowLogin={setShowLogin} />
          </div>

          <div
            className={`transition-all duration-300 fixed left-[50%] -translate-x-[50%] ${showRegister ? "bottom-0" : "-bottom-[120%]"
              } right-0 h-full overflow-y-scroll w-full `}
          >
            <Register
              showRegister={showRegister}
              setShowRegister={setShowRegister}
            />
          </div>
        </>
      )}

    </div>
  );
};

export default Welcome;
