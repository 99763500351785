import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: "",
    netto: 0,
    brutto: 0,
    profit: 0,
    description: "",
    receiverUser: {},
    senderUser: {},
    rates: []
};


const installmentSlice = createSlice({
    name: "installment",
    initialState,
    reducers: {

        setInstallment: (state, action) => {
            state.id = action.payload._id;
            state.netto = action.payload.netto;
            state.description = action.payload.description;
            state.receiverUser = action.payload.receiverUser;
            state.senderUser = action.payload.senderUser;
            state.brutto = action.payload.brutto;
            state.profit = action.payload.profit;
            state.rates = action.payload.rates;
        },
    },
});

export default installmentSlice.reducer;
export const { setInstallment } = installmentSlice.actions;
