import React, { useState, useEffect } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import NewsCard from "../components/utilities/NewsCard";

const News = () => {
    const navigate = useNavigate();
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString();
    const [dateString, setDateString] = useState(`${day}.${month}.${year}`);

    return (

        <div className="px-[22px]">
            <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center my-5">
                <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
                    <AiOutlineLeft
                        onClick={() => navigate("/home")}
                        className="text-[352F48] text-lg"
                    />
                </div>
                <h1 className="text-md font-medium ml-auto">Neuigkeiten</h1>
                <div></div>
            </div>
            <div className="flex flex-col items-center">
                <div>
                    <NewsCard
                        head="Neue Features bald verfügbar!"
                        body="Wir arbeiten konstant an EasyCreditor um Ihnen den besten Service zu bieten!"
                        date={dateString}
                    />
                </div>
            </div>
        </div>
    )
}

export default News;
