import React from "react";

const Textarea = ({ id, placeholder, className, ...rest }) => {
  return (
    <textarea
      id={id}
      placeholder={placeholder}
      className={`w-full block border rounded-lg px-5 py-3 outline-none focus:ring-1 ring-dark text-dark placeholder:text-light text-base font-normal min-h-[120px] ${className}`}
      {...rest}
    />
  );
};

export default Textarea;
