import { apiSlice } from "../api/apiSlice";

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => `/api/users/single-user`,
      providesTags: ["getUser"],
    }),
    getUserByUsername: builder.query({
      query: (username) => `/api/users/${username}`,
    }),
  }),
});

export const { useGetUserQuery, useGetUserByUsernameQuery } = userApi;
