import React, { useEffect, useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setInvoice } from "../../features/invoice/invoiceSlice";
import { useGetUserByUsernameQuery } from "../../features/user/userApi";
import Input from "../utilities/Input";
import Textarea from "../utilities/Textarea";
import { useGetUserQuery } from "../../features/user/userApi";

const CreateInvoiceForm = ({ activeTab, setActiveTab }) => {
  const loggedInUser = useGetUserQuery().data;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getUser, setGetUser] = useState(false);
  const [username, setUsername] = useState("");
  const { data: user } = useGetUserByUsernameQuery(username.toLowerCase(), {
    skip: !getUser,
  });
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [userMsg, setUserMsg] = useState({
    status: "",
    msg: "",
  });
  const [amountMsg, setAmountMsg] = useState({
    status: "",
    msg: "",
  });
  const [errors, setErrors] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const checkMobileAndWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', checkMobileAndWidth);

    return () => {
      window.removeEventListener('resize', checkMobileAndWidth);
    };
  }, []);

  useEffect(() => {
    if (user?._id) {
      setUserMsg({ status: "success", msg: "Vertragspartner gefunden!" });
    } else if (user?.message && username) {
      setUserMsg({ status: "error", msg: "Vertragspartner nicht gefunden!" });
    }
  }, [user, username]);

  const submitHandler = () => {
    setErrors({});
    const validationErrors = {};

    if (!username) {
      validationErrors.username = "Bitte Username eintragen.";
    }

    if (!user?._id) {
      setUserMsg({ status: "error", msg: "Vertragspartner nicht gefunden!" });
      return;
    }

    if (!amount) {
      validationErrors.amount = "Bitte Betrag eingeben.";
    }

    if (amount > loggedInUser.balance) {
      validationErrors.amount = "Ihr Guthaben reicht nicht aus.";
    }

    if (amount && parseFloat(amount) <= 0) {
      validationErrors.amount = "Bitte einen positiven Betrag eintragen.";
    }

    if (amount && (Math.floor(parseFloat(amount) * 100) / 100) != parseFloat(parseFloat(amount).toFixed(2))) {
      validationErrors.amount = "Bitte Betrag mit nur 2 Nachkommastellen eintragen.";
    }

    if (amount.includes(",")) {
      setAmount(amount.replace(",", "."));
    }

    if (Object.keys(validationErrors)?.length > 0) {
      return setErrors(validationErrors);
    }

    if (Object.keys(validationErrors)?.length === 0 && loggedInUser) {
      try {
        dispatch(
          setInvoice({
            amount: parseFloat(amount).toFixed(2),
            description,
            receiverUser: user,
            senderUser: loggedInUser,
          })
        );
        setActiveTab((prev) => prev + 1);
      } catch (err) {
        console.log("Fehler:", err)
      }
    }
  };

  return (
    <div>
      {windowWidth <= 850 && (
        <div>
          <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center p-5">
            <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
              <AiOutlineLeft
                onClick={() => navigate("/payment-methods")}
                className="text-[352F48] text-lg"
              />
            </div>
            <h1 className="text-md font-medium ml-auto">Einzelzahlung</h1>
            <button
              className="text-primary text-base font-medium ml-auto"
              onClick={() => {
                submitHandler();
              }}>Weiter</button>
          </div>

          <div className="px-[22px] flex flex-col gap-5">

            <div>
              <label className="text-sm text-light">Username</label>
              <div className="relative">
                <Input
                  id="username"
                  type="text"
                  placeholder="Username des Vertragspartners"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    setGetUser(true);
                  }}
                />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="absolute top-[50%] transform translate-y-[-50%] right-4 w-5 text-primary">
                  <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                </svg>
              </div>

              {errors?.username && (
                <p className="text-red-600">{errors?.username}</p>
              )}
              {userMsg?.msg && (
                <p
                  className={`mt-2 ${userMsg?.status === "success"
                    ? "text-green-500"
                    : "text-red-600"
                    }`}
                >
                  {userMsg?.msg}
                </p>
              )}
            </div>

            <div>
              <label className="text-sm text-light">Betrag</label>
              <div className="relative">
                <Input
                  id="amount"
                  type="number"
                  placeholder="0,00€"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <span className="absolute top-[50%] transform translate-y-[-50%] right-4 text-primary text-base">
                  €
                </span>
              </div>
              {errors?.amount && (
                <p className="mt-2 text-red-600">{errors?.amount}</p>
              )}
              {amountMsg?.msg && (
                <p
                  className={"mt-2 text-xs text-slate-600"}
                >
                  {amountMsg?.msg}
                </p>
              )}
            </div>

            <div>
              <label className="text-sm text-light">{"Verwendungszweck"}</label>
              <Textarea
                id="description"
                placeholder="Bitte Verwendungszweck eingeben."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

          </div>
        </div>

      )}

      {windowWidth > 850 && (
        <div>

          <div class="grid grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center ml-5 mb-10">
            <div className="w-11 h-11 border rounded-full flex items-center justify-center hover:bg-gray-200 cursor-pointer">
              <AiOutlineLeft
                onClick={() => navigate("/payment-methods")}
                className="text-[352F48] text-lg"
              />
            </div>
            <h1 className="text-lg font-medium m-auto">Einzelzahlung</h1>
            <div></div>
          </div>

          <div className="flex flex-col items-center gap-5">
            <div className="w-1/2">
              <label className="text-sm text-light">Username</label>
              <div className="relative">
                <Input
                  id="username"
                  type="text"
                  placeholder="Username des Vertragspartners"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    setGetUser(true);
                  }}
                />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="absolute top-[50%] transform translate-y-[-50%] right-4 w-5 text-primary">
                  <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                </svg>
              </div>
              {errors?.username && (
                <p className="text-red-600">{errors?.username}</p>
              )}
              {userMsg?.msg && (
                <p
                  className={`mt-2 ${userMsg?.status === "success"
                    ? "text-green-500"
                    : "text-red-600"
                    }`}
                >
                  {userMsg?.msg}
                </p>
              )}
            </div>

            <div className="w-1/2">
              <label className="text-sm text-light">Betrag</label>
              <div className="relative">
                <Input
                  id="amount"
                  type="number"
                  placeholder="0,00€"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <span className="absolute top-[50%] transform translate-y-[-50%] right-4 text-primary text-base">
                  €
                </span>
              </div>
              {errors?.amount && (
                <p className="mt-2 text-red-600">{errors?.amount}</p>
              )}
              {amountMsg?.msg && (
                <p
                  className={"mt-2 text-xs text-slate-600"}
                >
                  {amountMsg?.msg}
                </p>
              )}
            </div>

            <div className="w-1/2">
              <label className="text-sm text-light">Verwendungszweck</label>
              <Textarea
                id="description"
                placeholder="Bitte Verwendungszweck eingeben."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <button
              className="text-white text-base font-medium bg-primary rounded-full py-3 px-full mt-5 w-1/2"
              onClick={() => {
                submitHandler();
              }}>Weiter</button>
          </div>
        </div>
      )}

    </div >
  );
};

export default CreateInvoiceForm;